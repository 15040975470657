/* Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * chefsteps.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2015 */
@font-face {
  font-family: "Circular";
  src: url("../fonts/lineto-circular-book.eot");
  src: url("../fonts/lineto-circular-book.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-circular-book.woff") format("woff"), url("../fonts/lineto-circular-book.ttf") format("ttf"), url("../fonts/lineto-circular-book.svg#lineto-circular-book") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Circular";
  src: url("../fonts/lineto-circular-bookItalic.eot");
  src: url("../fonts/lineto-circular-bookItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-circular-bookItalic.woff") format("woff"), url("../fonts/lineto-circular-bookItalic.ttf") format("ttf"), url("../fonts/lineto-circular-bookItalic.svg#lineto-circular-bookItalic") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Circular";
  src: url("../fonts/lineto-circular-medium.eot");
  src: url("../fonts/lineto-circular-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-circular-medium.woff") format("woff"), url("../fonts/lineto-circular-medium.ttf") format("ttf"), url("../fonts/lineto-circular-medium.svg#lineto-circular-medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Circular";
  src: url("../fonts/lineto-circular-bold.eot");
  src: url("../fonts/lineto-circular-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-circular-bold.woff") format("woff"), url("../fonts/lineto-circular-bold.ttf") format("ttf"), url("../fonts/lineto-circular-bold.svg#lineto-circular-bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Akkurat";
  src: url("../fonts/lineto-akkurat-regular.eot");
  src: url("../fonts/lineto-akkurat-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-akkurat-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Akkurat";
  src: url("../fonts/lineto-akkurat-bold.eot");
  src: url("../fonts/lineto-akkurat-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-akkurat-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

* {
  font-family: Circular;
  -webkit-font-smoothing: antialiased;
  -webkit-font-variant-ligatures: common-ligatures;
  /* for iOS and Safari 6 */
  font-variant-ligatures: common-ligatures; }

h1, div.text-block-content span.h1-text-block,
span.h1-block {
  font-size: 63px;
  line-height: 63px;
  font-weight: bold;
  letter-spacing: -.02em;
  margin-top: 0px;
  margin-bottom: 28px; }
  @media (max-width: 641px) {
    h1, div.text-block-content span.h1-text-block,
    span.h1-block {
      font-size: 40px;
      line-height: 42px; } }

h2,
div.text-block-content span.h2-text-block,
span.h2-block {
  font-size: 42px;
  line-height: 42px;
  font-weight: bold;
  letter-spacing: -.02em;
  margin-top: 0px;
  margin-bottom: 28px; }
  @media (max-width: 641px) {
    h2,
    div.text-block-content span.h2-text-block,
    span.h2-block {
      font-size: 32px;
      line-height: 35px; } }

h3,
div.text-block-content span.h3-text-block,
span.h3-block {
  font-size: 32px;
  line-height: 35px;
  font-weight: bold;
  letter-spacing: -.02em;
  margin-top: 0px;
  margin-bottom: 28px; }
  @media (max-width: 641px) {
    h3,
    div.text-block-content span.h3-text-block,
    span.h3-block {
      font-size: 24px;
      line-height: 28px; } }

h4,
div.text-block-content span.h4-text-block,
.banner-text > span.h4-head,
span.h4-block {
  font-size: 21px;
  line-height: 28px;
  font-weight: bold;
  letter-spacing: -.02em;
  margin-top: 0px;
  margin-bottom: 28px; }
  @media (max-width: 961px) {
    h4,
    div.text-block-content span.h4-text-block,
    .banner-text > span.h4-head,
    span.h4-block {
      font-size: 18px;
      line-height: 24px; } }

h5,
div.text-block-content span.h5-text-block,
span.h5-block {
  font-size: 20px;
  line-height: 28px;
  font-style: italic;
  font-weight: normal;
  letter-spacing: -.02em;
  margin-top: 0px;
  margin-bottom: 28px; }
  @media (max-width: 961px) {
    h5,
    div.text-block-content span.h5-text-block,
    span.h5-block {
      font-size: 18px;
      line-height: 24px; } }

h6,
div.text-block-content span.h6-text-block,
span.h6-block {
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: -.01em;
  margin-top: 0px;
  margin-bottom: 28px; }

body, p, div.text-content {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -.015em;
  margin-top: 0px;
  margin-bottom: 28px; }
  @media (max-width: 961px) {
    body, p, div.text-content {
      font-size: 16px;
      line-height: 24px; } }

.intro-text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 28px; }

small {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 28px; }

::selection {
  background: #F6A99B; }

a:link, a:visited {
  text-decoration: none;
  color: inherit; }

a:hover, a:active {
  text-decoration: none; }

p a:link, p a:visited, p a:hover, .underline-links a:link, .underline-links a:visited, .underline-links a:hover {
  text-decoration: underline; }

.component-matrix .item-text p,
.component-feed .item-text p,
.component-text-block p,
.component-text-block div.paragraph-content,
.component-fifty-fifty p,
.component-fifty-fifty div.text-content {
  opacity: 0.85; }

.underline-hover {
  position: relative;
  display: inline;
  text-decoration: none !important;
  white-space: pre-wrap;
  background-size: 300% 2px;
  background-position: 100% 97%;
  background-image: linear-gradient(to right, #43413f 40%, rgba(67, 65, 63, 0) 66%);
  /* W3C */
  background-repeat: no-repeat no-repeat;
  transition: background-position 0.1s ease; }
  .underline-hover:hover {
    background-position: 0% 97%;
    transition: background-position 0.3s ease;
    cursor: pointer; }

.active .underline-hover {
  background-position: 0% 97%; }

div::selection .underline, .underline-hover::selection {
  text-shadow: 0.03em 0.07em #F6A99B, -0.03em 0.07em #F6A99B, 0 0.03em #F6A99B, 0 -0.03em #F6A99B, 0.06em 0.07em #F6A99B, -0.06em 0.07em #F6A99B, 0.09em 0.07em #F6A99B, -0.09em 0.07em #F6A99B, 0.12em 0.07em #F6A99B, -0.12em 0.07em #F6A99B, 0.15em 0.07em #F6A99B, -0.15em 0.07em #F6A99B; }

.theme-light .underline-hover {
  text-shadow: 0.03em 0.07em white, -0.03em 0.07em white, 0 0.03em white, 0 -0.03em white, 0.06em 0.07em white, -0.06em 0.07em white, 0.09em 0.07em white, -0.09em 0.07em white, 0.12em 0.07em white, -0.12em 0.07em white, 0.15em 0.07em white, -0.15em 0.07em white; }

.theme-gray .underline-hover {
  text-shadow: 0 0.07em #edecea, 0 0.07em #edecea, 0.03em 0.07em #edecea, -0.03em 0.07em #edecea, 0.06em 0.07em #edecea, -0.06em 0.07em #edecea, 0.09em 0.07em #edecea, -0.09em 0.07em #edecea, 0.12em 0.07em #edecea, -0.12em 0.07em #edecea, 0.15em 0.07em #edecea, -0.15em 0.07em #edecea; }

.theme-dark .underline-hover {
  text-shadow: 0.03em 0.07em #43413f, -0.03em 0.07em #43413f, 0 0.03em #43413f, 0 -0.03em #43413f, 0.06em 0.07em #43413f, -0.06em 0.07em #43413f, 0.09em 0.07em #43413f, -0.09em 0.07em #43413f, 0.12em 0.07em #43413f, -0.12em 0.07em #43413f, 0.15em 0.07em #43413f, -0.15em 0.07em #43413f; }

.theme-orange .underline-hover {
  text-shadow: 0.03em 0.07em #ff674d, -0.03em 0.07em #ff674d, 0 0.03em #ff674d, 0 -0.03em #ff674d, 0.06em 0.07em #ff674d, -0.06em 0.07em #ff674d, 0.09em 0.07em #ff674d, -0.09em 0.07em #ff674d, 0.12em 0.07em #ff674d, -0.12em 0.07em #ff674d, 0.15em 0.07em #ff674d, -0.15em 0.07em #ff674d; }

.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.align-full {
  text-align: justify; }
