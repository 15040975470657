.clone-activity-block .input-group-checkbox {
  margin-left: 20px; }

.clone-activity-block .error {
  font-size: 13px;
  color: red;
  margin-left: 60px; }

.clone-activity-block .error-msg {
  color: red; }

.clone-activity-block input.checkbox {
  height: 20px !important;
  flex: 0 1 12%; }

.clone-activity-block .column {
  display: flex;
  width: 100%; }

.clone-activity-block .flex-row {
  flex-direction: row; }
  .clone-activity-block .flex-row .column {
    flex: 1; }
    .clone-activity-block .flex-row .column input.checkbox {
      flex: 0 1 23%; }

.clone-activity-block .label, .clone-activity-block label {
  font-size: 16px; }
