.send-to-messenger-outer {
  margin-top: 28px;
  display: block;
  width: 100%;
  border: 1px solid #edecea;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 300ms ease; }
  .send-to-messenger-outer:hover {
    background-color: #f9f9f9; }
    .send-to-messenger-outer:hover .icon {
      transform: scale3d(1.08, 1.08, 1); }
    .send-to-messenger-outer:hover i, .send-to-messenger-outer:hover span.fa {
      transform: translate(5px, -50%); }

.send-to-messenger {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 28px 28px; }
  .send-to-messenger:hover {
    text-decoration: none; }
  .send-to-messenger .icon {
    margin-right: 0px;
    transform: scale3d(1, 1, 1);
    transition: transform 300ms ease-in-out; }
    .send-to-messenger .icon svg {
      height: 84px;
      width: 84px; }
  .send-to-messenger .middle {
    margin: 0px 35px 0px 21px; }
    .send-to-messenger .middle .title {
      margin-bottom: 7px; }
    .send-to-messenger .middle .subtitle {
      margin-bottom: 0px;
      font-size: 15px;
      line-height: 19px; }
  .send-to-messenger i, .send-to-messenger span.fa {
    font-size: 18px;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate(0, -50%);
    transition: transform 300ms ease-in-out; }

@media (max-width: 500px) {
  .send-to-messenger {
    padding: 28px 14px; }
    .send-to-messenger .icon svg {
      height: 64px;
      width: 64px; }
    .send-to-messenger .middle {
      margin: 0px 35px 0px 14px; }
      .send-to-messenger .middle .title {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 7px; }
      .send-to-messenger .middle .subtitle {
        font-size: 14px;
        line-height: 16px; }
    .send-to-messenger i, .send-to-messenger span.fa {
      font-size: 14px; } }

@media (max-width: 380px) {
  .send-to-messenger {
    flex-wrap: wrap;
    padding: 14px 14px 28px; }
    .send-to-messenger .icon {
      margin-right: 0px;
      flex: 0 0 100%;
      text-align: center; }
    .send-to-messenger .middle {
      text-align: center;
      margin: 0 14px; }
      .send-to-messenger .middle .title {
        margin-bottom: 14px; }
    .send-to-messenger i, .send-to-messenger span.fa {
      top: 35px;
      right: 20px; } }
