.matrix-filter h2 {
  text-align: center; }

.matrix-filter .matrix-filter-tags {
  display: flex;
  justify-content: center;
  margin: 0 0 42px; }
  .matrix-filter .matrix-filter-tags .matrix-filter-tag {
    margin: 0 14px;
    font-weight: 500;
    white-space: normal; }
    .matrix-filter .matrix-filter-tags .matrix-filter-tag .underline-hover {
      background-position: 100% 97%; }
    .matrix-filter .matrix-filter-tags .matrix-filter-tag.tag-inactive .underline-hover {
      transition: background-position 0.3s ease, color 300ms ease-in-out;
      color: #b8b6b4;
      background-position: 100% 97%; }
      .matrix-filter .matrix-filter-tags .matrix-filter-tag.tag-inactive .underline-hover:hover {
        cursor: pointer;
        color: #43413f;
        background-position: 0% 97%; }

.matrix-filter .matrix-filter-more-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 42px; }
