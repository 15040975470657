.joule-sv-hero.hero-high-z {
  z-index: 300; }

.joule-sv-hero {
  justify-content: flex-start; }
  .joule-sv-hero::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: -webkit-gradient(linear, left, right, color-stop(30%, rgba(171, 158, 155, 0.88)), color-stop(70%, transparent));
    background: -webkit-linear-gradient(left, rgba(171, 158, 155, 0.88) 30%, transparent 70%);
    background: linear-gradient(to right, rgba(171, 158, 155, 0.88) 30%, transparent 70%); }
  .joule-sv-hero .hero-text {
    max-width: 520px;
    text-align: center;
    padding: 42px 70px; }

@media (max-width: 640px) {
  .joule-sv-hero {
    flex-direction: column;
    height: auto;
    min-height: none; }
    .joule-sv-hero::after {
      display: none; }
    .joule-sv-hero .hero-background {
      position: relative;
      min-height: 400px; }
    .joule-sv-hero .hero-text {
      padding: 56px 28px;
      max-width: none;
      background-color: #d0b8b8; }
      .joule-sv-hero .hero-text p {
        opacity: 0.85; } }
