.joule-app-second-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .joule-app-second-hero .hero-background {
    filter: brightness(0.3); }
  .joule-app-second-hero .hero-text {
    flex: 0 1 auto;
    padding: 70px 28px 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .joule-app-second-hero .hero-text h2 {
      margin-bottom: 7px; }
    .joule-app-second-hero .hero-text h5 {
      margin-bottom: 0px; }
  .joule-app-second-hero .hero-image-phone {
    flex: 0 1 auto;
    box-sizing: border-box;
    max-width: 280px;
    margin-bottom: 70px; }

@media (max-width: 550px) {
  .joule-app-second-hero .hero-image-phone {
    width: 60vw;
    max-width: 240px;
    margin-bottom: 56px; } }
