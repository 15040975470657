.alerts {
  position: fixed;
  top: 76px;
  width: 100%;
  z-index: 9999; }

.alert {
  display: flex;
  padding: 14px; }

.alert-success {
  background-color: white;
  color: #ff674d; }

.alert-error {
  background-color: #ff674d;
  color: white; }

.alert-message {
  flex: 0 1 100%;
  justify-content: center;
  text-align: center; }

.alert-close {
  display: flex;
  justify-content: center;
  align-items: center; }
  .alert-close:hover {
    cursor: pointer; }
