.dropdown {
  position: relative; }

.dropdown-toggle:hover {
  cursor: pointer; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  /*left: 70px;*/
  left: 50%;
  margin-top: 23px;
  transform: translate3d(-50%, 0, 0);
  text-align: center;
  z-index: 1; }
  .dropdown-menu .dropdown-wrapper {
    position: relative;
    min-width: 200px;
    height: 100%;
    border: 1px solid #edecea;
    border-radius: 4px;
    padding: 21px 14px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 1; }
    .dropdown-menu .dropdown-wrapper.dropdown-wide {
      min-width: 280px; }
    .dropdown-menu .dropdown-wrapper:before {
      content: "";
      position: absolute;
      width: 200%;
      height: 200%;
      background-color: white;
      z-index: -1; }
  .dropdown-menu:before, .dropdown-menu:after {
    content: "";
    border-style: solid;
    border-width: 10px;
    width: 0;
    height: 0;
    position: absolute;
    top: -19px;
    left: calc(50% - 10px);
    z-index: 2; }
  .dropdown-menu:before {
    border-color: transparent transparent #edecea; }
  .dropdown-menu:after {
    margin-top: 2px;
    border-color: transparent transparent white; }

.dropdown-menu-item {
  font-size: 17px;
  line-height: 35px;
  margin: 0 14px;
  font-weight: normal;
  color: #43413f; }
  .dropdown-menu-item:last-of-type {
    margin-bottom: 0; }
  .dropdown-menu-item a {
    color: #43413f;
    display: block;
    opacity: 1;
    transition: opacity ease 200ms; }
    .dropdown-menu-item a:hover {
      opacity: 0.5;
      cursor: pointer; }

.dropdown-right .dropdown-menu {
  margin-top: 18px;
  transform: translate3d(-100%, 0, 0); }
  .dropdown-right .dropdown-menu .dropdown-wrapper {
    border-top-right-radius: 0; }
    .dropdown-right .dropdown-menu .dropdown-wrapper:before {
      transform-origin: top right;
      transform: translate3d(-50%, -45%, 0) scale3d(1, 1, 1); }
  .dropdown-right .dropdown-menu:before, .dropdown-right .dropdown-menu:after {
    opacity: 1;
    top: -10px;
    right: 1px;
    left: auto;
    transition: opacity ease 300ms 100ms, transform cubic-bezier(0.2, 0.51, 1, 1.1) 400ms; }
  .dropdown-right .dropdown-menu:before {
    right: 0px;
    border-top: none;
    border-right: none;
    border-bottom: 10px solid #edecea;
    border-left: 13px solid transparent; }
  .dropdown-right .dropdown-menu:after {
    margin-top: 2px;
    border-top: none;
    border-right: none;
    border-bottom: 10px solid white;
    border-left: 13px solid transparent; }

.dropdown-menu {
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
  transform-origin: top;
  transition: opacity ease 400ms, transform cubic-bezier(0.2, 0.51, 1, 1.1) 400ms; }
  .dropdown-menu:before, .dropdown-menu:after {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: opacity ease 400ms 0ms, transform ease 400ms 0ms; }
  .dropdown-menu .dropdown-wrapper {
    transition: border ease 300ms 200ms; }
    .dropdown-menu .dropdown-wrapper:before {
      transform: translate3d(-50%, -35%, 0) scale3d(1, 1, 1);
      transform-origin: top;
      transition: transform ease 400ms; }
    .dropdown-menu .dropdown-wrapper .dropdown-menu-item {
      opacity: 1;
      transition: opacity 400ms 200ms; }
  .dropdown-menu.ng-hide {
    opacity: 0;
    transform: translate3d(-50%, -10px, 0);
    transition: opacity ease 300ms, transform cubic-bezier(0.2, 0.51, 1, 1.1) 300ms; }
    .dropdown-menu.ng-hide:before, .dropdown-menu.ng-hide:after {
      opacity: 0;
      transform: translate3d(0, 5px, 0);
      transition: opacity ease 300ms 0ms, transform ease 300ms 0ms; }
    .dropdown-menu.ng-hide .dropdown-wrapper {
      border: 1px solid transparent;
      transition: border ease 100ms 0ms; }
      .dropdown-menu.ng-hide .dropdown-wrapper:before {
        transform: translate3d(-50%, -20%, 0) scale3d(0.2, 0.2, 0.2); }
      .dropdown-menu.ng-hide .dropdown-wrapper .dropdown-menu-item {
        opacity: 0;
        transition: opacity 400ms 0ms; }

.dropdown-right .dropdown-menu:before, .dropdown-right .dropdown-menu:after {
  transition: opacity ease 300ms 100ms, transform cubic-bezier(0.2, 0.51, 1, 1.1) 400ms; }

.dropdown-right .dropdown-menu .dropdown-wrapper:before {
  transform-origin: top right;
  transform: translate3d(-50%, -45%, 0) scale3d(1, 1, 1); }

.dropdown-right .dropdown-menu.ng-hide {
  transform: translate3d(-100%, -10px, 0); }
  .dropdown-right .dropdown-menu.ng-hide:before, .dropdown-right .dropdown-menu.ng-hide:after {
    transform: translate3d(-2px, 10px, 0);
    transition: opacity ease 300ms 0ms, transform ease 300ms 0ms; }
  .dropdown-right .dropdown-menu.ng-hide .dropdown-wrapper {
    transition: border ease 100ms 0ms; }
    .dropdown-right .dropdown-menu.ng-hide .dropdown-wrapper:before {
      transform: translate3d(-25%, -50%, 0) scale3d(0.2, 0.2, 0.2); }

@media (max-width: 900px) {
  .dropdown {
    width: 100%; }
    .dropdown .dropdown-menu {
      position: relative;
      margin-top: 0px;
      border-top: 0px;
      margin-top: 28px;
      top: auto;
      left: auto;
      right: auto;
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      opacity: 1;
      transition: opacity ease 400ms; }
      .dropdown .dropdown-menu:before, .dropdown .dropdown-menu:after {
        display: none; }
      .dropdown .dropdown-menu .dropdown-wrapper {
        padding: 28px 0px;
        background-color: #edecea;
        border: none;
        border-radius: 0; }
        .dropdown .dropdown-menu .dropdown-wrapper:before {
          display: none;
          border: none; }
        .dropdown .dropdown-menu .dropdown-wrapper .dropdown-menu-item {
          margin-bottom: 28px;
          line-height: 21px; }
        .dropdown .dropdown-menu .dropdown-wrapper .dropdown-menu-item:last-of-type {
          margin-bottom: 0px; }
      .dropdown .dropdown-menu.ng-hide {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1); } }
