span.strong {
  font-weight: bold; }

span.italic {
  font-style: italic; }

div.paragraph-content {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -.015em;
  margin-top: 0;
  margin-bottom: 28px; }
  div.paragraph-content a:link, div.paragraph-content a:visited, div.paragraph-content a:hover, .underline-links div.paragraph-content:link, .underline-links div.paragraph-content:visited, .underline-links div.paragraph-content:hover {
    text-decoration: underline; }
  div.paragraph-content a.button {
    text-decoration: none; }
  div.paragraph-content p.button-group-inline {
    margin-top: 28px; }

body, div.paragraph-content {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -.015em;
  margin-top: 0px;
  margin-bottom: 28px; }
  @media (max-width: 961px) {
    body, div.paragraph-content {
      font-size: 16px;
      line-height: 24px; } }
