.matrix-circle .matrix-item {
  display: block; }
  .matrix-circle .matrix-item .item-image {
    width: 80%;
    max-width: 252px;
    margin: 0 auto 28px;
    border-radius: 50%;
    position: relative;
    overflow: hidden; }
    .matrix-circle .matrix-item .item-image:before {
      content: '';
      display: block;
      padding-top: 100%; }
    .matrix-circle .matrix-item .item-image .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .matrix-circle .matrix-item .item-image img {
      width: 100%; }
    .matrix-circle .matrix-item .item-image .cs-image-hover-icons {
      background: transparent; }
  .matrix-circle .matrix-item .item-text {
    padding: 0 14px;
    text-align: center; }
    .matrix-circle .matrix-item .item-text h4, .matrix-circle .matrix-item .item-text span.h4 {
      margin-bottom: 7px; }
    .matrix-circle .matrix-item .item-text p {
      font-size: 16px;
      line-height: 24px;
      opacity: 0.85; }

@media (max-width: 500px) {
  .matrix-circle .item-text {
    padding: 0; } }

@media (max-width: 400px) {
  .matrix-circle .item-image {
    max-width: 196px; } }
