.flags {
  position: absolute;
  top: -56px;
  left: 0;
  width: 100%;
  height: 56px;
  color: white;
  cursor: pointer;
  z-index: 400; }
  .flags::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #ff674d;
    transform: scale3d(1, 1, 1);
    transition: transform ease 300ms; }
  .flags:hover::before {
    transform: scale3d(1, 3, 1); }
  .flags:hover .flag-close, .flags:hover .flag-message {
    transform: translate3d(0, 50%, 0); }
  .flags:hover .flag-close span {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .flags .flag-link {
    text-decoration: underline; }

.flag-icon {
  flex-shrink: 0;
  display: flex;
  align-items: center; }
  .flag-icon svg {
    padding-right: 7px; }
    .flag-icon svg .a, .flag-icon svg .b {
      stroke: white;
      stroke-width: 1.5px; }

@keyframes orange-flash {
  0% {
    background-color: #ff674d; }
  50% {
    background-color: #f1553a; }
  100% {
    background-color: #ff674d; } }

.flags.low-stock::before {
  animation: orange-flash 1.5s infinite; }

.flag-close, .flag-message {
  transform: translate3d(0, 0, 0);
  transition: transform ease 300ms; }

.flag-close {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 14px;
  padding-left: 14px;
  height: 100%;
  width: 42px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform ease 300ms, opacity ease 300ms; }
  .flag-close span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    opacity: 0;
    background: rgba(255, 255, 255, 0.1);
    transform: translate3d(50px, 0, 0);
    transition: background-color ease 300ms, transform ease 300ms, opacity ease 300ms; }
  .flag-close:hover span {
    background: rgba(255, 255, 255, 0.3); }

.flag-message {
  width: 100%;
  height: 100%;
  z-index: 0;
  padding: 0 7px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
  font-size: 14px;
  font-weight: bold;
  transform: none; }
  .flag-message span {
    max-width: 900px; }

@media (max-width: 900px) {
  .flags:hover::before {
    transform: scale3d(1, 1, 1); }
  .flags:hover .flag-close, .flags:hover .flag-message {
    transform: translate3d(0, 0, 0); }
  .flag-close span {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@media (max-width: 500px) {
  .flags {
    height: 70px;
    top: -70px; } }
