.collection-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 440px;
  height: 100%;
  z-index: 600;
  background-color: transparent;
  overflow: hidden;
  transform: translateZ(0); }
  .collection-nav.ng-hide-add, .collection-nav.ng-hide-remove {
    transition: opacity ease 0.5s; }
  .collection-nav .collection-nav-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .collection-nav .collection-nav-bg:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: auto;
      bottom: 63px;
      left: 63px;
      width: 100vh;
      height: 100vh;
      transform: translate(-50%, 50%) scale3d(3, 3, 1);
      border-radius: 50%;
      background-color: #e8e7e5;
      transition: transform cubic-bezier(0, 0, 0.5, 1) 600ms; }
  .collection-nav.ng-hide .collection-nav-bg:before {
    transform: translate(-50%, 50%) scale3d(0, 0, 0); }
  .collection-nav .collection-nav-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 42px 42px 84px;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    transition: opacity cubic-bezier(0, 0, 0.5, 1) 300ms 300ms; }
  .collection-nav.ng-hide .collection-nav-content {
    opacity: 0;
    transition: opacity cubic-bezier(0, 0, 0.5, 1) 200ms 0ms; }
  .collection-nav .component, .collection-nav .component.padded {
    background-color: transparent; }
  .collection-nav p {
    display: none; }
  .collection-nav .underline-hover {
    text-shadow: 0 0.07em #e8e7e5, 0 0.07em #e8e7e5, 0.03em 0.07em #e8e7e5, -0.03em 0.07em #e8e7e5, 0.06em 0.07em #e8e7e5, -0.06em 0.07em #e8e7e5, 0.09em 0.07em #e8e7e5, -0.09em 0.07em #e8e7e5, 0.12em 0.07em #e8e7e5, -0.12em 0.07em #e8e7e5, 0.15em 0.07em #e8e7e5, -0.15em 0.07em #e8e7e5; }

.collection-nav-header {
  text-align: center;
  padding: 28px 0 42px; }
  .collection-nav-header h3 {
    margin-bottom: 14px; }
  .collection-nav-header .collection-name {
    display: flex;
    justify-content: center;
    align-items: center; }
    .collection-nav-header .collection-name i, .collection-nav-header .collection-name span[cs-icon] {
      width: 18px;
      height: 18px;
      margin-right: 7px; }
    .collection-nav-header .collection-name h6, .collection-nav-header .collection-name span.h6-block {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0; }

.collection-nav-button {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 600; }
  .collection-nav-button .floating-action-button {
    position: absolute;
    left: 28px;
    right: auto; }
  .collection-nav-button .open-icon {
    display: block; }
  .collection-nav-button .close-icon {
    display: none; }
  .collection-nav-button .collection-nav-open .open-icon {
    display: none; }
  .collection-nav-button .collection-nav-open .close-icon {
    display: block; }

.collection-nav-prompt::before {
  content: "";
  position: absolute;
  bottom: -224px;
  left: -224px;
  width: 420px;
  height: 420px;
  border-radius: 50%;
  background-color: #edecea;
  transform: scale3d(0, 0, 1);
  transition: transform cubic-bezier(0, 0, 0.5, 1) 300ms; }

.collection-nav-prompt span {
  position: absolute;
  bottom: 112px;
  left: 63px;
  transform: translateX(-50%);
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #b8b6b4;
  opacity: 0;
  transition: opacity cubic-bezier(0, 0, 0.5, 1) 200ms 0ms; }

.collection-nav-prompt.show-nav-prompt::before {
  transform: scale3d(1, 1, 1); }

.collection-nav-prompt.show-nav-prompt span {
  opacity: 1;
  transition: opacity cubic-bezier(0, 0, 0.5, 1) 200ms 100ms; }

@media (min-width: 1250px) {
  .show-collection-nav .cs-nav-container,
  .show-collection-nav .activity-wrapper,
  .show-collection-nav .footer {
    width: calc(100% - 440px) !important;
    margin-left: 440px; }
  .show-collection-nav .hero-video-portrait .cs-embed-video-wrapper {
    width: 100%;
    height: auto; }
  .show-collection-nav .activity-intro-left, .show-collection-nav .activity-intro-right {
    max-width: 120px; }
  .show-collection-nav cs-suggested-recipes {
    display: none; }
  .collection-nav {
    width: 440px; }
  .collection-nav-prompt {
    display: none; } }

@media (max-width: 640px) {
  .collection-nav-prompt::before {
    bottom: -182px;
    left: -182px;
    width: 350px;
    height: 350px; }
  .collection-nav-prompt span {
    left: 56px;
    bottom: 98px; } }

@media (min-width: 550px) {
  .collection-nav .component, .collection-nav .component.padded {
    padding: 0 0 28px; }
  .collection-nav .component-matrix-collapsible .matrix.matrix-2-col .matrix-item,
  .collection-nav .component-matrix-collapsible .matrix.matrix-3-col .matrix-item,
  .collection-nav .component-matrix-collapsible .matrix.matrix-4-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 10px 0; }
  .collection-nav .component-matrix-collapsible .matrix-2-col.matrix-item-numbered,
  .collection-nav .component-matrix-collapsible .matrix-3-col.matrix-item-numbered,
  .collection-nav .component-matrix-collapsible .matrix-4-col.matrix-item-numbered {
    display: flex;
    column-count: normal;
    column-gap: normal; }
    .collection-nav .component-matrix-collapsible .matrix-2-col.matrix-item-numbered .matrix-item,
    .collection-nav .component-matrix-collapsible .matrix-3-col.matrix-item-numbered .matrix-item,
    .collection-nav .component-matrix-collapsible .matrix-4-col.matrix-item-numbered .matrix-item {
      display: inline;
      width: auto;
      margin: 10px 0;
      border-bottom: none; }
    .collection-nav .component-matrix-collapsible .matrix-2-col.matrix-item-numbered .item-image,
    .collection-nav .component-matrix-collapsible .matrix-3-col.matrix-item-numbered .item-image,
    .collection-nav .component-matrix-collapsible .matrix-4-col.matrix-item-numbered .item-image {
      overflow: hidden; }
      .collection-nav .component-matrix-collapsible .matrix-2-col.matrix-item-numbered .item-image img,
      .collection-nav .component-matrix-collapsible .matrix-3-col.matrix-item-numbered .item-image img,
      .collection-nav .component-matrix-collapsible .matrix-4-col.matrix-item-numbered .item-image img {
        border-radius: 0; }
  .collection-nav .component-matrix-collapsible .matrix-collapsible-header {
    margin: 0 0 14px;
    padding: 14px 21px; }
    .collection-nav .component-matrix-collapsible .matrix-collapsible-header h3 {
      font-size: 18px;
      line-height: 24px; }
  .collection-nav .component-matrix-collapsible .matrix {
    padding: 0; }
  .collection-nav .component-matrix-collapsible .matrix-list .matrix-item, .collection-nav .component-matrix-collapsible .matrix-item-numbered .matrix-item {
    padding: 0;
    border-bottom: none; }
    .collection-nav .component-matrix-collapsible .matrix-list .matrix-item h4, .collection-nav .component-matrix-collapsible .matrix-item-numbered .matrix-item h4 {
      font-size: 18px;
      line-height: 24px; }
  .collection-nav .component-matrix-collapsible .matrix-list .item-image, .collection-nav .component-matrix-collapsible .matrix-item-numbered .item-image {
    flex: 0 0 63px;
    width: 63px;
    height: 63px;
    margin-right: 21px; }
    .collection-nav .component-matrix-collapsible .matrix-list .item-image .cs-image-hover-icons, .collection-nav .component-matrix-collapsible .matrix-item-numbered .item-image .cs-image-hover-icons {
      display: flex;
      justify-content: center;
      align-items: center; }
      .collection-nav .component-matrix-collapsible .matrix-list .item-image .cs-image-hover-icons .button-circle-play, .collection-nav .component-matrix-collapsible .matrix-item-numbered .item-image .cs-image-hover-icons .button-circle-play {
        position: relative;
        bottom: auto;
        right: auto; }
  .collection-nav .component-matrix-collapsible .number-circle {
    width: 21px;
    height: 21px;
    font-size: 14px; } }

@media (max-width: 550px) {
  .collection-nav {
    width: 100%; }
    .collection-nav .collection-nav-content {
      padding: 35px 0 84px; }
    .collection-nav .collection-nav-header {
      padding: 28px 28px 0; } }
