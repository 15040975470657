.modal-terms {
  display: flex;
  flex-direction: column;
  padding: 56px 56px 42px; }
  .modal-terms .modal-body {
    overflow: auto;
    text-align: left;
    padding: 0;
    margin: 14px 0 42px;
    max-height: 340px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch; }
    .modal-terms .modal-body p {
      font-size: 16px;
      line-height: 24px; }

@media (max-width: 640px) {
  .modal-terms {
    padding: 56px 28px 42px; }
    .modal-terms .modal-bottom {
      padding-bottom: 56px; } }
