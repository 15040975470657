.cs-image {
  perspective: 1000px;
  transform: translate3d(0, 0, 0); }

.cs-image[cs-background-image] {
  height: 100%; }

img.cs-image-load {
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 200ms ease-in-out; }
  img.cs-image-load.loaded {
    opacity: 1; }

label.feature-text-tag {
  position: absolute;
  top: 14px;
  right: 14px;
  background: #ffffff;
  padding: 0 7px;
  font-size: 15px;
  border-radius: 21px;
  font-weight: bold; }
