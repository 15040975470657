.component-menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 14px 28px;
  box-sizing: border-box; }
  .component-menu .button {
    min-width: 40px;
    text-align: center;
    margin: 7px 0 0 0;
    border: none;
    opacity: 0.5; }
    .component-menu .button:hover {
      opacity: 1; }

.component-menu.editing {
  position: relative; }

.component-menu.ng-enter {
  transition: 200ms linear all;
  transform: translateY(-10%);
  opacity: 0; }

.component-menu.ng-enter.ng-enter-active {
  transform: translateY(0%);
  opacity: 1; }

.component-menu.ng-leave {
  transition: 200ms linear all;
  transform: translateY(0%);
  opacity: 1; }

.component-menu.ng-leave.ng-leave-active {
  transform: translateY(-10%);
  opacity: 0; }

.component-form.ng-enter {
  transition: 300ms all ease-in;
  max-height: 0;
  opacity: 0; }

.component-form.ng-enter.ng-enter-active, .component-form.ng-leave {
  max-height: 999px;
  opacity: 1; }

.component-form.ng-leave.ng-leave-active {
  max-height: 0;
  opacity: 0; }

.component-form.ng-leave {
  transition: 300ms all ease-out; }
