.component-full {
  width: 100%; }

.component-standard {
  width: 100%;
  padding-left: calc((100% - 1110px)/2);
  padding-right: calc((100% - 1110px)/2);
  box-sizing: border-box; }

.component-small {
  width: 100%;
  padding-left: calc((100% - 700px)/2);
  padding-right: calc((100% - 700px)/2);
  box-sizing: border-box; }

@media (max-width: 1166px) {
  .component-standard {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px; } }

@media (max-width: 700px) {
  .component-small {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px; } }

.component {
  box-sizing: border-box;
  overflow: hidden;
  display: block;
  position: relative; }
  .component .component-content {
    width: 100%; }

.component.theme-light {
  background-color: white; }

.component.theme-dark {
  background-color: #43413f;
  color: white; }

.component.theme-gray {
  background-color: #edecea; }

.component.theme-orange {
  background-color: #ff674d;
  color: white; }

.component.theme-light-with-bottom-line {
  background-color: white; }

.padded {
  padding-top: 42px;
  padding-bottom: 42px; }

.padded-large {
  padding-top: 84px; }

@media (max-width: 960px) {
  .component.padded {
    padding-top: 28px;
    padding-bottom: 28px; }
  .component.padded.padded-large {
    padding-top: 56px; } }

.component.theme-light.padded + .component.theme-light.padded {
  padding-top: 0px; }

.component.theme-dark.padded + .component.theme-dark.padded {
  padding-top: 0px; }

.component.theme-orange.padded + .component.theme-orange.padded {
  padding-top: 0px; }

.component.theme-gray.padded + .component.theme-gray.padded {
  padding-top: 0px; }

.component.theme-light.padded + .component.theme-light.component-divider .divider,
.component.theme-light.component-divider + .component.theme-light.padded {
  padding-top: 0px; }

.component.theme-dark.padded + .component.theme-dark.component-divider .divider,
.component.theme-dark.component-divider + .component.theme-dark.padded {
  padding-top: 0px; }

.component.theme-orange.padded + .component.theme-orange.component-divider .divider,
.component.theme-orange.component-divider + .component.theme-orange.padded {
  padding-top: 0px; }

.component.theme-gray.padded + .component.theme-gray.component-divider .divider,
.component.theme-gray.component-divider + .component.theme-gray.padded {
  padding-top: 0px; }

.component.component-hidden {
  display: none; }

.component.component-editing {
  display: block;
  min-height: 200px; }
