.sso-form-container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .sso-form-container .sso-form {
    max-width: 500px;
    border: 1px solid #edecea;
    padding: 84px;
    box-sizing: border-box;
    width: 100%; }

.sso-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }

.sso-form-top .updates.info {
  color: #046B99; }
  .sso-form-top .updates.info .info-icon {
    display: inline-block;
    vertical-align: middle;
    padding-top: 3px; }

.sso-form-top h3 span, .sso-form-top span.h3-block span {
  color: #b8b6b4; }
  .sso-form-top h3 span a, .sso-form-top span.h3-block span a {
    text-decoration: underline;
    cursor: pointer; }

.sso-form-top h6, .sso-form-top span.h6-block {
  margin-bottom: 7px; }

.sso-form-body {
  width: 100%; }
  .sso-form-body input {
    width: 100%;
    margin-bottom: 28px; }
  .sso-form-body .button {
    margin-bottom: 28px; }
  .sso-form-body .button.button-facebook, .sso-form-body .button.button-apple {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto 28px;
    align-items: center;
    color: white; }
    .sso-form-body .button.button-facebook i, .sso-form-body .button.button-facebook span, .sso-form-body .button.button-apple i, .sso-form-body .button.button-apple span {
      padding-right: 14px; }
      .sso-form-body .button.button-facebook i .cs-icon-facebook-dims, .sso-form-body .button.button-facebook span .cs-icon-facebook-dims, .sso-form-body .button.button-apple i .cs-icon-facebook-dims, .sso-form-body .button.button-apple span .cs-icon-facebook-dims {
        height: 18px; }
  .sso-form-body .button.button-facebook {
    border-color: #3B5998;
    background-color: #3B5998;
    font-size: 21px; }
  .sso-form-body .button.button-apple {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 21px;
    border-color: #050708;
    background-color: #050708; }
  .sso-form-body h6, .sso-form-body span.h6-block {
    color: #b8b6b4;
    text-transform: uppercase;
    margin-bottom: 0; }
  .sso-form-body h6.button-separator, .sso-form-body span.h6-block.button-separator {
    margin-bottom: 20px; }
  .sso-form-body a {
    color: #b8b6b4;
    text-decoration: underline;
    transition: color 200ms ease; }
    .sso-form-body a:hover {
      cursor: pointer;
      color: #43413f; }

.signup-form .signup-form-bottom {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .signup-form .signup-form-bottom .agreement-text {
    text-align: left;
    padding-bottom: 10px; }
    .signup-form .signup-form-bottom .agreement-text div, .signup-form .signup-form-bottom .agreement-text .legal-link {
      font-size: 12px;
      line-height: 17px;
      font-style: italic;
      color: rgba(67, 65, 63, 0.7);
      font-weight: normal; }
    .signup-form .signup-form-bottom .agreement-text div.terms-condition {
      margin-top: 5px; }
    .signup-form .signup-form-bottom .agreement-text .legal-link {
      text-decoration: underline;
      color: rgba(67, 65, 63, 0.7); }
    .signup-form .signup-form-bottom .agreement-text input {
      height: 12px;
      font-size: 12px;
      line-height: 12px;
      margin: 0 3px; }
    .signup-form .signup-form-bottom .agreement-text .checkbox {
      width: auto; }

.login-form-bottom {
  display: flex;
  justify-content: space-around; }

@media (max-width: 500px) {
  .sso-form-container .sso-form {
    padding: 42px;
    border: 0px; } }
