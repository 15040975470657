@media screen and (max-width: 672px) {
  .subscription-container .content .offer-text .offer-topic .offer-line {
    font-size: 64px !important; }
  .subscription-container .content .offer-text hr {
    margin: 15px 0; } }

.subscription-container {
  padding: 28px 14px;
  margin: 0 auto;
  max-width: 500px; }
  .subscription-container .button {
    display: block;
    width: fit-content;
    margin: 14px 0; }
  .subscription-container .content .loading-spinner {
    justify-content: flex-start; }
  .subscription-container .content div {
    margin-bottom: 14px; }
  .subscription-container .content .offer-text hr {
    background: #EDECEA;
    height: 3px; }
  .subscription-container .content .offer-text .offer-topic {
    color: #FF5F42;
    font-size: 24px;
    font-weight: bold;
    margin: 31px 0 0 0; }
    .subscription-container .content .offer-text .offer-topic .greet-line {
      font-size: 24px;
      margin-bottom: 23px; }
    .subscription-container .content .offer-text .offer-topic .offer-line {
      font-size: 74.2px; }
    .subscription-container .content .offer-text .offer-topic .offer-line-sub {
      font-size: 28px; }
    .subscription-container .content .offer-text .offer-topic .last-save {
      font-size: 74.2px;
      line-height: 1;
      margin-bottom: 12px; }
    .subscription-container .content .offer-text .offer-topic .last-save-sub {
      font-size: 35px; }
  .subscription-container .content .offer-text .offer-body {
    font-size: 18px;
    letter-spacing: -0.42px; }
    .subscription-container .content .offer-text .offer-body a {
      display: inline; }
  .subscription-container .loading-spinner {
    display: inline-block; }
  .subscription-container span.bold {
    font-weight: bold; }
  .subscription-container a {
    text-decoration: underline;
    cursor: pointer;
    display: block; }
  .subscription-container a.action {
    display: inline-block;
    text-decoration: none; }
    .subscription-container a.action:nth-of-type(2) {
      margin-left: 15px; }
    .subscription-container a.action.disabled {
      color: #C7C7C7;
      cursor: not-allowed;
      border-color: #C7C7C7; }
  .subscription-container div.radio-options {
    margin-bottom: 0; }
    .subscription-container div.radio-options input.radio {
      height: 14px; }
    .subscription-container div.radio-options:nth-of-type(1) {
      margin-top: 15px; }
    .subscription-container div.radio-options:nth-of-type(2) {
      margin-bottom: 15px; }
  .subscription-container .notice {
    margin-top: 15px;
    border: 1px solid #F6A99B;
    padding: 10px 10px 10px 20px;
    background-color: #F6A99B; }
  .subscription-container .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center; }
    .subscription-container .loading-wrapper .wrapper-text, .subscription-container .loading-wrapper .subscribe-error {
      min-width: 20%;
      text-align: center;
      background-color: white;
      border: 2px solid #b8b6b4;
      border-radius: 14px;
      color: #43413f;
      padding: 28px; }
      .subscription-container .loading-wrapper .wrapper-text button, .subscription-container .loading-wrapper .subscribe-error button {
        margin: 14px auto; }
  .subscription-container .coupon-link {
    color: #1565c0;
    font-size: 15px;
    text-decoration: none;
    margin-left: 5px; }
