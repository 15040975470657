@keyframes fade-in-text {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  20% {
    opacity: 1;
    transform: translateX(0px); }
  80% {
    opacity: 1;
    transform: translateX(0px); }
  100% {
    opacity: 0;
    transform: translateX(20px); } }

#cooked-text {
  display: inline-block;
  position: relative;
  animation: fade-in-text 2s 0s 1; }
  #cooked-text:after {
    content: "";
    width: 100%;
    height: 0;
    border-bottom: 4px solid white;
    position: absolute;
    left: 0;
    bottom: -5px; }

.joule-main-hero {
  position: relative;
  padding-bottom: 250px; }

.joule-main-hero .hero {
  flex-direction: row;
  justify-content: flex-start;
  height: 80vh;
  max-height: 800px;
  min-height: 650px; }
  .joule-main-hero .hero:after {
    display: none; }
  .joule-main-hero .hero .hero-background:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.6) 10%, transparent 50%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.6) 10%, transparent 50%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 10%, transparent 50%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
  .joule-main-hero .hero .hero-background .cs-background-image {
    background-position: center right !important; }
  .joule-main-hero .hero .hero-text {
    text-align: left;
    max-width: 700px;
    margin: 0 84px 56px;
    padding: 42px 0;
    z-index: 4; }
  .joule-main-hero .hero h1 {
    font-size: 91px;
    line-height: 91px;
    margin-bottom: 21px; }

@media (max-width: 1220px) {
  .joule-main-hero .hero h1 {
    font-size: 63px;
    line-height: 63px; } }

@media (max-width: 1150px) {
  .joule-main-hero {
    padding-bottom: 450px; }
    .joule-main-hero .hero {
      height: 650px;
      max-height: auto;
      min-height: auto; }
      .joule-main-hero .hero .hero-text {
        max-width: 500px; } }

@media (max-width: 800px) {
  .joule-main-hero {
    padding-bottom: 500px; }
    .joule-main-hero .hero {
      align-items: center;
      justify-content: flex-end;
      flex-direction: column; }
      .joule-main-hero .hero .hero-background:before {
        background: -moz-linear-gradient(top, transparent 0%, transparent 50%, #2a2928 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, transparent 0%, transparent 50%, #2a2928 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, transparent 0%, transparent 50%, #2a2928 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
        /* IE6-9 */ }
      .joule-main-hero .hero .hero-text {
        margin-left: 0;
        width: 100%;
        padding: 42px 28px 98px;
        margin: 0;
        text-align: center; }
      .joule-main-hero .hero h1 {
        order: 1;
        font-size: 49px;
        line-height: 49px; } }

@media (max-width: 700px) {
  .joule-main-hero .hero {
    height: 80vh;
    min-height: 500px; }
    .joule-main-hero .hero .cs-background-image-container .cs-background-image {
      background-position: top center !important; } }

@media (max-width: 670px) {
  .joule-main-hero {
    padding-bottom: 200px; } }

@media (max-width: 600px) {
  .joule-main-hero .hero .hero-text {
    padding: 42px 28px 98px; }
  .joule-main-hero .hero h1 {
    font-size: 42px;
    line-height: 42px; } }

@media (max-width: 400px) {
  .joule-main-hero .hero .hero-text {
    padding: 42px 28px 112px; } }

@media (max-width: 365px) {
  .joule-main-hero .hero h1 {
    font-size: 35px;
    line-height: 35px; } }

.wired-recommends {
  position: absolute;
  width: 180px;
  height: 180px;
  top: 80px;
  right: 80px; }
  .wired-recommends img {
    width: 100%; }

@media (max-width: 1200px) {
  .wired-recommends {
    width: 160px;
    height: 160px;
    top: 60px;
    right: 40px; } }

@media (max-width: 600px) {
  .wired-recommends {
    width: 110px;
    height: 110px;
    top: 50px;
    right: 20px; } }
