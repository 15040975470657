.footer {
  background-color: #383736;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 98px 56px;
  animation: footer-fadein 2s; }
  .footer .footer-items {
    width: 700px;
    display: flex; }

@keyframes footer-fadein {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.footer-item-logo {
  flex: 0 1 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding-right: 70px;
  margin-right: 56px; }
  .footer-item-logo .footer-logo {
    width: 100%;
    max-width: 150px;
    padding: 0;
    margin-bottom: 28px;
    display: block; }
    .footer-item-logo .footer-logo img {
      width: 100%; }
  .footer-item-logo a {
    color: white;
    padding-right: 14px;
    display: inline-block;
    width: 28px;
    transition: opacity 300ms; }
    .footer-item-logo a .cs-icon-twitter-dims, .footer-item-logo a .cs-icon-facebook-dims, .footer-item-logo a .cs-icon-instagram-dims {
      height: 23px;
      width: auto;
      padding-top: 0; }
    .footer-item-logo a:hover {
      opacity: 0.6; }

.footer-item-links {
  flex: 0 1 100%;
  padding-left: 14px;
  color: white;
  font-size: 16px;
  line-height: 28px;
  font-weight: normal; }
  .footer-item-links ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .footer-item-links ul li a {
      color: white;
      font-weight: 500; }
      .footer-item-links ul li a:hover {
        text-decoration: underline; }

.updated {
  font-size: 11px;
  opacity: 0.7;
  white-space: nowrap; }
  @media (min-width: 700px) {
    .updated {
      padding-left: 14px; } }

@media (max-width: 700px) {
  .footer {
    padding: 56px 14px; }
    .footer .footer-items {
      flex-direction: column;
      max-width: 180px; }
      .footer .footer-items .footer-item-logo {
        flex: none;
        padding: 0;
        margin: 0 0 28px;
        border: 0px;
        text-align: center; }
        .footer .footer-items .footer-item-logo .footer-logo {
          margin: 0 auto 14px;
          max-width: 150px;
          padding: 0; }
        .footer .footer-items .footer-item-logo a {
          padding: 14px; }
      .footer .footer-items .footer-item-links {
        flex: none;
        padding: 28px 0;
        text-align: center;
        border-top: 1px solid rgba(255, 255, 255, 0.2); }
        .footer .footer-items .footer-item-links ul {
          padding: 0px; } }
