.redeem-block .button:focus {
  outline: 0; }

.redeem-block .input-block {
  margin: 24px 0 24px 0 !important; }

.redeem-block .input-error {
  color: #ff674d;
  margin-bottom: 0 !important; }

.redeem-block .loading-spinner {
  margin-bottom: 0 !important;
  margin-top: 4px; }
