.joule-highlights {
  padding: 28px 0; }

.joule-split {
  width: 90%;
  max-width: 1400px;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 42px 0; }

.split-media {
  flex: 1 1 60%;
  overflow: hidden; }
  .split-media img {
    border-radius: 4px; }
  .split-media .media-video-container {
    margin: 0;
    border-radius: 4px;
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .split-media .media-button-container {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2); }
    .split-media .media-button-container:after {
      content: "See Joule in action";
      font-size: 20px;
      line-height: 1.1;
      font-weight: bold;
      letter-spacing: normal;
      margin-left: 14px;
      text-transform: uppercase;
      color: white;
      z-index: 5;
      max-width: 130px;
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.8); }
  .split-media .button-circle-play-large {
    width: 70px;
    height: 70px;
    box-shadow: 0 0 60px 50px rgba(0, 0, 0, 0.5); }

.split-text {
  flex: 1 1 40%;
  min-width: 420px;
  padding: 0 42px 0 84px;
  box-sizing: border-box; }
  .split-text h2, .split-text span.h2-block {
    font-size: 52px;
    line-height: 1;
    color: #ff674d; }
  .split-text p {
    opacity: 0.7;
    margin-bottom: 0; }
  .split-text joule-selector-button {
    text-align: left; }
  .split-text .button {
    min-width: 220px;
    margin-top: 14px; }
  .split-text .button:first-of-type {
    margin-top: 28px;
    margin-right: 14px; }

.joule-split.right .split-media {
  order: 2; }

.joule-split.right .split-text {
  order: 1;
  padding: 0 84px 0 42px; }

.joule-steps {
  width: 90%;
  max-width: 1400px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 56px 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 4px; }
  .joule-steps svg {
    flex: 0 0 75px; }
    .joule-steps svg .a {
      stroke: #ff674d;
      stroke-width: 2px; }

.joule-step {
  flex: 0 0 190px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap; }
  .joule-step h4, .joule-step span.h4-block {
    line-height: 1;
    color: #ff674d;
    margin: 0 0 0 7px; }

.joule-arrow {
  padding: 0 21px 0 14px;
  opacity: 0.3; }

@media (max-width: 1300px) {
  .split-text {
    padding: 0 42px 0 56px; }
    .split-text h2, .split-text span.h2-block {
      font-size: 45px; }
  .joule-split.right .split-text {
    padding: 0 56px 0 42px; } }

@media (max-width: 1100px) {
  .joule-split {
    align-items: flex-start; } }

@media (max-width: 1000px) {
  .split-text {
    padding: 0 0 0 56px;
    min-width: 380px; }
    .split-text h2, .split-text span.h2-block {
      font-size: 40px; }
  .joule-split.right .split-text {
    padding: 0 56px 0 0; } }

@media (max-width: 960px) {
  .joule-step {
    flex: 0 0 170px; } }

@media (max-width: 900px) {
  .joule-highlights {
    padding: 42px 0 0; }
  .joule-split {
    flex-direction: column;
    align-items: center;
    padding: 0 0 28px; }
    .joule-split:first-child {
      padding: 0; }
  .split-text, .split-media {
    flex: 1 1 auto; }
  .split-media {
    width: 100%; }
  .split-text,
  .joule-split.right .split-text {
    padding: 42px;
    min-width: 0;
    text-align: center;
    max-width: 600px; }
  .joule-split.right .split-media {
    order: 1; }
  .joule-split.right .split-text {
    order: 2; }
  .split-text joule-selector-button {
    text-align: center; }
  .joule-steps {
    width: 100%;
    background-color: transparent;
    margin-bottom: 56px;
    padding: 0 28px;
    box-sizing: border-box; }
  .joule-step {
    flex: 0 1 100px;
    flex-direction: column;
    text-align: center; }
    .joule-step h4, .joule-step span.h4-block {
      margin: 14px 0 0; }
  .joule-arrow {
    padding: 0 14px; } }

@media (max-width: 600px) {
  .split-text,
  .joule-split.right .split-text {
    padding: 42px 14px 28px;
    text-align: center; }
    .split-text h2, .split-text span.h2-block,
    .joule-split.right .split-text h2,
    .joule-split.right .split-text span.h2-block {
      font-size: 34px; }
    .split-text .button,
    .joule-split.right .split-text .button {
      width: 100%;
      max-width: 220px; } }

@media (max-width: 550px) {
  .split-text .button:first-of-type {
    margin-right: 0; } }

@media (max-width: 500px) {
  .joule-steps {
    background-color: transparent;
    margin-bottom: 56px;
    padding: 0 14px; }
    .joule-steps svg {
      flex: 0 0 65px; }
  .joule-step {
    flex: 0 1 80px;
    flex-direction: column;
    text-align: center; }
    .joule-step h4, .joule-step span.h4-block {
      margin: 14px 0 0;
      font-size: 16px; }
  .joule-arrow {
    padding: 0; } }

@media (max-width: 400px) {
  .split-media .media-button-container:after {
    font-size: 16px;
    max-width: 100px; }
  .split-media .button-circle-play-large {
    width: 63px;
    height: 63px;
    box-shadow: 0 0 30px 20px rgba(0, 0, 0, 0.5); } }

@media (max-width: 360px) {
  .joule-step {
    margin: 0 7px; }
  .joule-arrow {
    display: none; } }
