@import "app.variables";
@import "mixins";

.cs-icons-sprite-sheet {
  position: absolute;
  left: -10000px;
  opacity: 0;
}

.space-large {
  margin-bottom: $space-large;
}

.small-text {
  font-size: 12/14 * $base;
  line-height: 16/14 * $base;
}

#mainView.show-flag {
  position: relative;
  margin-top: 4 * $base;
}

@media (max-width: 500px) {
  #mainView.show-flag {
    margin-top: 5 * $base;
  }
}

div.text-block-content {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -.015em;
  margin-top: 0;
  margin-bottom: 28px;

  & span.h1-text-block,
  & span.h2-text-block,
  & span.h3-text-block,
  & span.h4-text-block,
  & span.h5-text-block,
  & span.h6-text-block {
    display: block;
  }
}

span.h1-block,
span.h2-block,
span.h3-block,
span.h4-block,
span.h5-block,
span.h6-block {
  display: block;
}

span.h6-block {
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 28px;
  display: block;
}

span.h4-block {
  font-size: 18px;
  margin-bottom: 7px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: -.02em;
  margin-top: 0;
  display: block;
}