.modal-container-content .modals {
  background-color: rgba(0, 0, 0, 0.6); }

.modal-container-content i.close-x, .modal-container-content span.close-x {
  display: none; }

.modal-container-content .modal-content i.close-x, .modal-container-content .modal-content span.close-x {
  display: block;
  cursor: pointer;
  position: absolute;
  color: white;
  padding: 14px;
  top: 14px;
  right: 14px;
  z-index: 101;
  border-radius: 50%;
  line-height: 28px;
  padding: 7px 14px;
  background-color: rgba(0, 0, 0, 0.2); }

.modal.modal-content {
  padding: 0px;
  max-height: 90vh;
  overflow-y: scroll;
  height: auto;
  border: 0px;
  box-sizing: border-box;
  max-width: 500px;
  border-radius: 4px;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  display: flex;
  flex-direction: column; }
  .modal.modal-content .modal-content-image {
    position: relative;
    display: block;
    flex-shrink: 0; }
  .modal.modal-content .modal-content-body {
    position: relative;
    width: 100%;
    padding: 0 42px;
    margin: 42px 0 0;
    box-sizing: border-box; }
    .modal.modal-content .modal-content-body .modal-content-wrapper {
      padding-bottom: 28px;
      word-wrap: break-word;
      overflow-y: hidden; }

@media (max-width: 528px) {
  .modal-content .close-x {
    top: 0;
    right: 0; }
  .modal.modal-content {
    width: 90%;
    max-height: 90%;
    overflow-y: scroll; }
    .modal.modal-content .modal-content-body {
      padding: 0 28px;
      margin: 28px 0; }
    .modal.modal-content:after {
      bottom: 28px; } }
