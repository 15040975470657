.joule-hardware-desc .text-block {
  max-width: 700px;
  margin: 0 auto 56px; }
  .joule-hardware-desc .text-block p {
    opacity: 0.85; }

.joule-hardware-desc .cta-buttons {
  display: flex;
  justify-content: center;
  align-items: center; }
  .joule-hardware-desc .cta-buttons custom-button {
    margin: 14px; }

.joule-hardware-desc .media-video-container {
  margin: 0 0 28px; }

@media (max-width: 1194px) {
  .joule-hardware-desc .media-video-container {
    margin: 0 42px 28px; } }

@media (max-width: 640px) {
  .joule-hardware-desc .media-video-container {
    margin: 0 28px 0;
    min-height: 200px; } }

@media (max-width: 600px) {
  .joule-hardware-desc .cta-buttons {
    flex-direction: column; }
    .joule-hardware-desc .cta-buttons custom-button {
      margin: 7px 0; } }
