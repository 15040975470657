.matrix-icon {
  justify-content: center; }
  .matrix-icon .matrix-item {
    display: block; }
    .matrix-icon .matrix-item .item-image {
      border-radius: 4px; }
      .matrix-icon .matrix-item .item-image .cs-image {
        height: 77px;
        display: flex;
        justify-content: center;
        margin-bottom: 21px; }
      .matrix-icon .matrix-item .item-image img {
        width: auto;
        height: 100%; }
    .matrix-icon .matrix-item .item-text {
      color: #43413f;
      padding: 0 14px;
      text-align: center; }
      .matrix-icon .matrix-item .item-text h4, .matrix-icon .matrix-item .item-text span.h4-block {
        margin-bottom: 7px; }
      .matrix-icon .matrix-item .item-text .item-card-label {
        display: none; }
      .matrix-icon .matrix-item .item-text p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        opacity: 0.85; }
      .matrix-icon .matrix-item .item-text .button {
        margin-top: 28px; }

.theme-dark .matrix-icon .matrix-item .item-text, .theme-orange .matrix-icon .matrix-item .item-text {
  color: white; }
