.component-banner.component-banner-madlib {
  background: -moz-linear-gradient(top, #fff 0%, #f2f2f2 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #f2f2f2));
  background: -webkit-linear-gradient(top, #fff 0%, #f2f2f2 100%);
  background: -o-linear-gradient(top, #fff 0%, #f2f2f2 100%);
  background: -ms-linear-gradient(top, #fff 0%, #f2f2f2 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%); }

.banner-madlib {
  padding: 0; }
  .banner-madlib h3, .banner-madlib span.h3-block {
    margin-bottom: 14px; }
  .banner-madlib .banner-content {
    padding: 28px 56px 56px;
    max-width: 800px;
    margin: auto; }
    .banner-madlib .banner-content .banner-agreement-text {
      margin: 14px 0; }
      .banner-madlib .banner-content .banner-agreement-text div {
        font-size: 16px;
        line-height: 24px;
        font-style: italic;
        color: rgba(67, 65, 63, 0.7);
        font-weight: normal;
        letter-spacing: -.02em; }
        .banner-madlib .banner-content .banner-agreement-text div .legal-link {
          text-decoration: underline;
          color: rgba(67, 65, 63, 0.7);
          line-height: 24px; }
        .banner-madlib .banner-content .banner-agreement-text div input {
          font-size: 12px;
          height: 12px; }
      .banner-madlib .banner-content .banner-agreement-text div.terms-condition {
        margin-top: 7px; }
    .banner-madlib .banner-content .banner-form {
      font-size: 32px;
      line-height: 42px;
      letter-spacing: -1.2px;
      margin-bottom: 14px; }
      .banner-madlib .banner-content .banner-form .banner-form-text {
        display: inline-block;
        font-weight: 500;
        margin-bottom: 14px; }
      .banner-madlib .banner-content .banner-form .banner-form-input {
        display: inline-block;
        position: relative; }
        .banner-madlib .banner-content .banner-form .banner-form-input .banner-form-input-error {
          position: absolute;
          top: -14px;
          font-size: 14px;
          line-height: 14px;
          width: 100%;
          text-align: center; }
        .banner-madlib .banner-content .banner-form .banner-form-input .banner-form-input-error-bottom {
          position: absolute;
          top: 35px;
          font-size: 14px;
          line-height: 14px;
          width: 100%;
          text-align: center; }
        .banner-madlib .banner-content .banner-form .banner-form-input input {
          font-size: 21px;
          line-height: 28px;
          height: inherit;
          text-align: center;
          margin-right: 0px;
          width: 224px;
          display: block; }
    .banner-madlib .banner-content p {
      margin-bottom: 21px;
      opacity: 0.5; }
    .banner-madlib .banner-content .button {
      display: inline-flex;
      justify-content: center;
      align-items: center; }

.banner-form-error {
  font-size: 18px;
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 28px;
  color: #ff674d; }

.component.theme-light .banner-madlib h3, .component.theme-light .banner-madlib span.h3-block {
  color: #ff674d;
  display: inline-block; }

.component.theme-light .banner-madlib .button.solid {
  background-color: #ff674d;
  border: 2px solid #ff674d;
  color: white; }

.component.theme-light .banner-madlib > .madlib-success {
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column; }
  .component.theme-light .banner-madlib > .madlib-success h3, .component.theme-light .banner-madlib > .madlib-success span.h3-block {
    color: #43413f;
    text-align: center; }
  .component.theme-light .banner-madlib > .madlib-success .madlib-success-close {
    position: absolute;
    top: 14px;
    right: 28px; }
    .component.theme-light .banner-madlib > .madlib-success .madlib-success-close:hover {
      cursor: pointer;
      color: #ff674d; }

@media (max-width: 960px) {
  .component-banner .banner-madlib h3, .component-banner .banner-madlib span.h3-block {
    font-size: 24px;
    margin-bottom: 14px; }
  .component-banner .banner-madlib .banner-content {
    padding: 28px 42px; }
    .component-banner .banner-madlib .banner-content .banner-form {
      font-size: 24px;
      letter-spacing: -.9px;
      line-height: 35px; }
      .component-banner .banner-madlib .banner-content .banner-form .banner-form-input .banner-form-input-error {
        top: -12px;
        font-size: 12px;
        line-height: 12px;
        color: #b8b6b4; }
      .component-banner .banner-madlib .banner-content .banner-form .banner-form-input .banner-form-input-error-bottom {
        top: 28px;
        font-size: 12px;
        line-height: 12px;
        color: #b8b6b4; }
      .component-banner .banner-madlib .banner-content .banner-form .banner-form-input input {
        font-size: 16px;
        line-height: 21px;
        width: 182px; } }

@media (max-width: 640px) {
  .component-banner-madlib.theme-light .banner-madlib .banner-content {
    padding: 28px; }
    .component-banner-madlib.theme-light .banner-madlib .banner-content .banner-form {
      font-size: 20px;
      letter-spacing: -.7px; }
  .component-banner-madlib.theme-light .banner-madlib > .madlib-success {
    padding-top: 28px; }
    .component-banner-madlib.theme-light .banner-madlib > .madlib-success .madlib-success-close {
      right: 0;
      left: 0; } }

@media (max-width: 356px) {
  .component-banner .banner-madlib .banner-content .banner-form .show-btn {
    margin-top: 28px; }
  .component-banner-madlib.theme-light .banner-madlib .banner-content .banner-form .show-btn {
    margin-top: auto; } }

@media (max-width: 316px) {
  .component-banner-madlib.theme-light .banner-madlib .banner-content .banner-form .show-btn {
    margin-top: 28px; } }
