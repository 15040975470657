.joule-app-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 75vh;
  min-height: 700px; }
  .joule-app-hero::after {
    background: -webkit-gradient(linear, left, right, color-stop(10%, rgba(0, 0, 0, 0.8)), color-stop(50%, transparent));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8) 10%, transparent 50%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 10%, transparent 50%); }
  .joule-app-hero .text-icon svg {
    height: 85px;
    width: auto;
    margin-bottom: 7px; }
    .joule-app-hero .text-icon svg .a, .joule-app-hero .text-icon svg .b {
      stroke-width: 2px;
      stroke: white; }
  .joule-app-hero .hero-text {
    flex: 0 1 auto;
    text-align: center;
    padding: 42px 70px;
    max-width: 450px; }
    .joule-app-hero .hero-text h5 {
      margin-bottom: 0px; }
    .joule-app-hero .hero-text p {
      margin-bottom: 14px; }
  .joule-app-hero .cta-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    .joule-app-hero .cta-buttons custom-button {
      margin: 7px 0; }
    .joule-app-hero .cta-buttons a {
      width: 250px; }

@media (max-width: 640px) {
  .joule-app-hero {
    flex-direction: column;
    height: auto;
    min-height: none; }
    .joule-app-hero::after {
      display: none; }
    .joule-app-hero .hero-background {
      position: relative;
      min-height: 400px; }
    .joule-app-hero .hero-text {
      padding: 56px 28px;
      max-width: none;
      text-align: center;
      background-color: #8fb7a6; }
      .joule-app-hero .hero-text p {
        opacity: 0.85; }
    .joule-app-hero .cta-buttons {
      justify-content: center; }
      .joule-app-hero .cta-buttons custom-button {
        margin: 7px; } }

@media (max-width: 600px) {
  .joule-app-hero {
    min-height: 600px; }
    .joule-app-hero .cta-buttons {
      flex-direction: column; }
      .joule-app-hero .cta-buttons custom-button {
        margin: 7px 0; } }
