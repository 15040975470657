.ingredients-wrapper {
  text-align: center; }

.ingredients {
  display: inline-block; }

.ingredient {
  text-align: left;
  display: flex;
  margin-left: -20px; }
  .ingredient p {
    display: inline; }

.ingredient-quantity-invalid input, .ingredient-quantity-invalid div {
  color: red; }

.ingredient-quantity {
  font-family: Akkurat;
  text-align: right;
  width: 120px;
  min-width: 120px;
  margin-right: 7px;
  margin-top: 3.5px;
  line-height: 28px; }
  .ingredient-quantity .scalable {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 4px; }
  .ingredient-quantity .scalable-focused {
    background: white;
    border-radius: 4px; }
  .ingredient-quantity .text {
    margin-right: 14px; }
  .ingredient-quantity * {
    font-family: Akkurat;
    font-weight: bold; }
  .ingredient-quantity input {
    width: 40px;
    border: 0px;
    padding: 0px 0px;
    font-size: 18px;
    height: auto;
    text-align: right; }
  .ingredient-quantity input[type=number]::-webkit-inner-spin-button,
  .ingredient-quantity input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.ingredient-title-desc {
  line-height: 35px;
  font-style: italic; }

.ingredient-cart-button {
  text-decoration: none !important; }

@media (max-width: 960px) {
  .ingredient {
    margin-bottom: 7px;
    margin-left: 0;
    padding: 0 28px; }
    .ingredient:last-of-type {
      margin-bottom: 0; }
  .ingredient-quantity {
    line-height: 24px;
    width: 80px;
    min-width: 80px;
    margin-top: 0; }
    .ingredient-quantity input {
      font-size: 16px; }
    .ingredient-quantity .text {
      margin-right: 10px; }
  .ingredient-title-desc {
    line-height: 24px; } }
