.getpremium .input-group {
  width: 100%;
  box-sizing: border-box;
  max-width: 400px;
  padding: 28px;
  background-color: white;
  border-radius: 4px;
  color: #43413f;
  margin: 28px auto;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .getpremium .input-group .input-error {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #ff674d; }
  .getpremium .input-group input {
    width: 100%;
    max-width: 300px;
    margin: 14px 0 28px;
    font-size: 28px;
    font-weight: bold;
    text-align: center; }
