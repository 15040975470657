.full-carousel {
  color: white; }
  .full-carousel button.owl-next,
  .full-carousel button.owl-prev {
    border-color: white !important; }
  .full-carousel .full-carousel-item {
    display: block;
    position: relative;
    background: #43413f;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06); }
    .full-carousel .full-carousel-item.empty-image .item-image cs-image {
      background: #edecea; }
    .full-carousel .full-carousel-item .item-image {
      width: 100%;
      border-radius: 4px 4px 0 0;
      position: relative;
      overflow: hidden; }
      .full-carousel .full-carousel-item .item-image:before {
        content: '';
        display: block;
        padding-top: 66.66%; }
      .full-carousel .full-carousel-item .item-image .image, .full-carousel .full-carousel-item .item-image cs-image {
        background-color: #43413f;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .full-carousel .full-carousel-item .item-image img {
        width: 100%; }
    .full-carousel .full-carousel-item .item-text {
      background-color: #43413f;
      color: white;
      padding: 28px; }
      .full-carousel .full-carousel-item .item-text h4, .full-carousel .full-carousel-item .item-text span.h4-block {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 0; }
      .full-carousel .full-carousel-item .item-text p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.85;
        padding-top: 10px; }

.full-carousel .underline-hover {
  text-shadow: 0.03em 0.07em white, -0.03em 0.07em white, 0 0.03em white, 0 -0.03em white, 0.06em 0.07em white, -0.06em 0.07em white, 0.09em 0.07em white, -0.09em 0.07em white, 0.12em 0.07em white, -0.12em 0.07em white, 0.15em 0.07em white, -0.15em 0.07em white; }

.component-type-settings .full-carousel .full-carousel-item {
  background: transparent;
  border-radius: none;
  overflow: hidden;
  box-shadow: none; }

.item-card-label {
  display: flex; }
  .item-card-label .tag, .item-card-label .premium {
    font-size: 12px;
    line-height: 21px;
    font-weight: bold;
    margin-top: 7px;
    text-transform: uppercase;
    margin-right: 5px;
    opacity: 0.5; }
  .item-card-label .premium {
    color: #ff674d;
    opacity: 1; }

.full-carousel .item-card-label .tag {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1; }

@media (max-width: 640px) {
  .full-carousel {
    justify-content: center; }
    .full-carousel .full-carousel-item .item-text {
      padding: 28px; }
      .full-carousel .full-carousel-item .item-text h4, .full-carousel .full-carousel-item .item-text span.h4-block {
        font-size: 16px;
        line-height: 21px; }
      .full-carousel .full-carousel-item .item-text p {
        font-size: 14px;
        line-height: 21px; }
  .full-carousel.matrix-1-col .full-carousel-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 0 28px; }
  full-carousel.matrix-2-col .full-carousel-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2);
    margin: 0 7px 28px; }
    full-carousel.matrix-2-col .full-carousel-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    full-carousel.matrix-2-col .full-carousel-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .full-carousel.matrix-3-col .full-carousel-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2);
    margin: 0 7px 28px; }
    .full-carousel.matrix-3-col .full-carousel-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .full-carousel.matrix-3-col .full-carousel-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .full-carousel.matrix-4-col .full-carousel-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2);
    margin: 0 7px 28px; }
    .full-carousel.matrix-4-col .full-carousel-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .full-carousel.matrix-4-col .full-carousel-item:nth-of-type(2n + 2) {
      margin-right: 0px; } }

@media (max-width: 450px) {
  .full-carousel .full-carousel-item .item-text {
    padding: 14px; }
  .full-carousel .item-card-label .tag, .full-carousel .item-card-label .premium {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500; } }
