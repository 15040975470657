.banner .banner-text {
  padding: 42px 28px;
  text-align: center; }
  .banner .banner-text h3, .banner .banner-text span.h3-block {
    margin-bottom: 14px; }
  .banner .banner-text h6, .banner .banner-text span.h6-block {
    margin-bottom: 21px;
    opacity: 0.6; }
  .banner .banner-text .custom-button {
    margin-bottom: 0; }
