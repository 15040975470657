.joule-app-demo {
  position: relative;
  height: 1000px;
  overflow: hidden; }
  .joule-app-demo .joule-app-demo-foreground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .joule-app-demo .joule-app-demo-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-size: auto 100%;
    background-repeat: repeat-x;
    background-position: center center; }
  .joule-app-demo .joule-app-demo-top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center; }
    .joule-app-demo .joule-app-demo-top .joule-app-demo-title {
      width: 700px;
      text-align: center;
      color: white;
      margin-top: 56px; }
      .joule-app-demo .joule-app-demo-top .joule-app-demo-title h2 {
        margin-bottom: 7px; }
  .joule-app-demo .joule-app-demo-center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
    .joule-app-demo .joule-app-demo-center .joule-app-demo-videos {
      flex: 0 0 1155px;
      height: 1000px;
      position: relative;
      overflow: hidden; }
      .joule-app-demo .joule-app-demo-center .joule-app-demo-videos .joule-app-demo-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0; }
        .joule-app-demo .joule-app-demo-center .joule-app-demo-videos .joule-app-demo-video.joule-app-demo-video-active {
          opacity: 1; }
    .joule-app-demo .joule-app-demo-center .joule-app-demo-controls {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
  .joule-app-demo .joule-app-demo-left {
    padding-right: 15%; }
    .joule-app-demo .joule-app-demo-left .joule-app-demo-button {
      text-align: right;
      z-index: 1; }
      .joule-app-demo .joule-app-demo-left .joule-app-demo-button .joule-app-button-title {
        justify-content: flex-end; }
      .joule-app-demo .joule-app-demo-left .joule-app-demo-button .button-circle-play {
        margin-left: 14px; }
  .joule-app-demo .joule-app-demo-right {
    padding-left: 15%; }
    .joule-app-demo .joule-app-demo-right .joule-app-demo-button {
      text-align: left;
      z-index: 1; }
      .joule-app-demo .joule-app-demo-right .joule-app-demo-button .button-circle-play {
        margin-right: 14px; }
  .joule-app-demo .joule-app-demo-button {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    border-radius: 4px;
    padding: 28px;
    max-width: 300px;
    width: 100%;
    transition: background-color 300ms ease-in-out;
    box-sizing: border-box; }
    .joule-app-demo .joule-app-demo-button .joule-app-button-title {
      display: flex;
      align-items: center;
      margin-bottom: 7px; }
    .joule-app-demo .joule-app-demo-button h4, .joule-app-demo .joule-app-demo-button span.h4-block {
      margin-bottom: 0px;
      text-decoration: underline; }
    .joule-app-demo .joule-app-demo-button p {
      margin-bottom: 0px; }
    .joule-app-demo .joule-app-demo-button:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.6); }
    .joule-app-demo .joule-app-demo-button.active {
      background-color: rgba(0, 0, 0, 0.8); }

@media (max-width: 1000px) {
  .joule-app-demo .joule-app-demo-button {
    max-width: 220px; }
  .joule-app-demo .joule-app-demo-left {
    padding-right: 18%; }
  .joule-app-demo .joule-app-demo-right {
    padding-left: 18%; } }

@media (max-width: 750px) {
  .joule-app-demo .joule-app-demo-title {
    padding: 0 28px; }
    .joule-app-demo .joule-app-demo-title h3 {
      font-size: 18px; }
    .joule-app-demo .joule-app-demo-title buy-joule-button {
      display: none; }
  .joule-app-demo .joule-app-demo-button {
    max-width: none;
    background-color: rgba(0, 0, 0, 0.6); }
    .joule-app-demo .joule-app-demo-button .button-circle-play {
      display: none; }
    .joule-app-demo .joule-app-demo-button.active {
      background-color: #ff674d; }
  .joule-app-demo .joule-app-demo-center .joule-app-demo-controls {
    align-items: flex-start; }
    .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control {
      margin-top: 140px;
      width: 100%; }
      .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control.joule-app-demo-left {
        padding: 14px 7px 14px 21px; }
      .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control.joule-app-demo-right {
        padding: 14px 21px 14px 7px; }
      .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control .joule-app-demo-button {
        width: 100%;
        padding: 14px;
        text-align: center; }
        .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control .joule-app-demo-button .joule-app-button-title {
          justify-content: center !important;
          margin-bottom: 0; }
        .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control .joule-app-demo-button h4, .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control .joule-app-demo-button span.h4-block {
          font-size: 16px;
          text-decoration: none; }
        .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control .joule-app-demo-button p {
          display: none; }
  .joule-app-demo .joule-app-demo-center .joule-button-bottom {
    display: block;
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center; } }

@media (max-width: 500px) {
  .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control {
    margin-top: 160px; }
    .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control .joule-app-demo-button h4, .joule-app-demo .joule-app-demo-center .joule-app-demo-controls .joule-app-demo-control .joule-app-demo-button span.h4-block {
      font-size: 14px; } }
