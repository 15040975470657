.joule-how .product-steps {
  display: flex;
  justify-content: center;
  margin-top: 42px; }

.joule-how .product-step {
  flex: 0 0 126px;
  box-sizing: border-box;
  text-align: center;
  padding: 0 14px; }
  .joule-how .product-step svg {
    width: 70px;
    height: 70px; }
  .joule-how .product-step .a, .joule-how .product-step .b {
    stroke-width: 2px; }
  .joule-how .product-step p {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 1; }

.joule-how .media-video-container {
  margin: 0 0 28px; }

@media (max-width: 1194px) {
  .joule-how .media-video-container {
    margin: 0 42px 28px; } }

@media (max-width: 640px) {
  .joule-how .media-video-container {
    margin: 0 28px 0;
    min-height: 200px; } }

@media (max-width: 500px) {
  .joule-how .product-step {
    flex: 0 0 90px;
    padding: 0 7px; }
    .joule-how .product-step p {
      font-size: 12px; } }
