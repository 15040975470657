.activity-intro {
  display: flex;
  justify-content: center; }

.activity-intro-content {
  position: relative;
  width: 100%;
  max-width: 700px;
  box-sizing: border-box; }
  .activity-intro-content p:first-child, .activity-intro-content div.paragraph-content:first-child {
    font-size: 23px;
    line-height: 35px;
    letter-spacing: -.8px; }

.activity-intro-creator {
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 56px;
  left: 0;
  right: 0;
  color: white;
  z-index: 10; }
  .activity-intro-creator img {
    border-radius: 50%;
    border: 1px solid white;
    margin: 0 14px; }
  .activity-intro-creator a {
    color: white; }

.activity-intro-creator, .activity-intro-adapted {
  margin-bottom: 14px;
  font-style: italic; }

.activity-intro-left,
.activity-intro-right {
  width: 100%;
  max-width: 200px;
  margin: 0 20px; }

.activity-intro-left {
  margin-right: 42px;
  text-align: right; }
  .activity-intro-left .social-buttons {
    width: 56px;
    margin-left: auto; }

.activity-intro-right {
  margin-left: 42px; }
  .activity-intro-right .activity-tools {
    margin-bottom: 14px; }
    .activity-intro-right .activity-tools .button-circle-container {
      display: inline-block; }
      .activity-intro-right .activity-tools .button-circle-container:first-of-type {
        padding-left: 0; }
      .activity-intro-right .activity-tools .button-circle-container:last-of-type {
        padding-right: 0; }

.activity-intro.short-description {
  flex-direction: column;
  align-items: center; }
  .activity-intro.short-description .activity-intro-content {
    padding: 0 28px; }
  .activity-intro.short-description .activity-intro-left {
    max-width: none;
    margin: 0 0 28px; }
    .activity-intro.short-description .activity-intro-left .social-buttons {
      display: flex;
      justify-content: center;
      width: auto; }
  .activity-intro.short-description .activity-intro-right {
    margin: 14px 0 0;
    max-width: none; }
    .activity-intro.short-description .activity-intro-right .activity-tools {
      width: auto;
      text-align: center;
      margin-bottom: 0; }

.activity-intro.no-description {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 0; }
  .activity-intro.no-description .activity-intro-left {
    flex: 0 1 auto;
    margin: 0; }
    .activity-intro.no-description .activity-intro-left .social-buttons {
      justify-content: flex-end; }
  .activity-intro.no-description .activity-intro-right {
    flex: 0 1 auto;
    margin: 0; }
    .activity-intro.no-description .activity-intro-right .activity-tools {
      text-align: left;
      margin-bottom: 0; }
      .activity-intro.no-description .activity-intro-right .activity-tools .button-circle-container:first-of-type {
        padding-left: 7px; }
      .activity-intro.no-description .activity-intro-right .activity-tools .button-circle-container:last-of-type {
        padding-right: 7px; }
  .activity-intro.no-description .activity-intro-content {
    display: none; }

.activity-intro.paywalled {
  padding-bottom: 0; }
  .activity-intro.paywalled::after {
    content: "";
    background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0.8) 60%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    height: 196px;
    width: 100%;
    bottom: 28px;
    left: 0; }

.activity-info hr {
  border-color: #cecece; }
  .activity-info hr:last-of-type {
    margin-bottom: 14px; }

.activity-amounts {
  margin-top: 14px; }

.activity-amounts-equipment {
  flex: 1 0 0;
  box-sizing: border-box; }

.activity-amounts-timing-yield {
  flex: 2 0 0;
  box-sizing: border-box;
  display: flex; }

.activity-amounts-item {
  flex: 1 0 0;
  text-align: center;
  margin-bottom: 42px;
  box-sizing: border-box;
  font-style: italic; }
  .activity-amounts-item h4, .activity-amounts-item span.h4-block {
    font-size: 22px;
    margin-bottom: 14px;
    font-style: normal; }

.cs-missing-timing-yield .activity-amounts-timing-yield {
  flex: 1 0 0; }

.cs-long-equipment {
  align-items: stretch; }
  .cs-long-equipment .activity-amounts-equipment,
  .cs-long-equipment .activity-amounts-timing-yield {
    flex: 1 0 0;
    box-sizing: border-box; }
  .cs-long-equipment .activity-amounts-equipment {
    text-align: right;
    padding-right: 42px;
    margin-right: 0;
    border-right: 1px solid #DAD8D6; }
  .cs-long-equipment .activity-amounts-timing-yield {
    padding-left: 42px;
    margin-left: 0;
    flex-direction: column; }
    .cs-long-equipment .activity-amounts-timing-yield .activity-amounts-item {
      display: block;
      flex: none;
      text-align: left; }

.cs-missing-all-amounts .activity-amounts-equipment,
.cs-missing-all-amounts .activity-amounts-timing-yield {
  flex: 1 0 0;
  box-sizing: border-box; }

.cs-missing-all-amounts .activity-amounts-equipment {
  text-align: center;
  padding-right: 0;
  border-right: none; }

.activity-yield-multiplier {
  font-family: Akkurat;
  font-weight: bold;
  color: #ff674d; }

.component-comments {
  padding-top: 42px;
  padding-bottom: 42px;
  background-color: #edecea; }

.activity-print-title {
  margin-top: 42px;
  margin-bottom: 0px; }

@media (max-width: 1220px) {
  cs-suggested-recipes {
    display: none; } }

@media (max-width: 1180px) {
  .activity-intro,
  .activity-intro.no-description {
    flex-direction: column;
    align-items: center; }
    .activity-intro .activity-intro-content,
    .activity-intro.no-description .activity-intro-content {
      padding: 0 28px; }
    .activity-intro .activity-intro-left,
    .activity-intro.no-description .activity-intro-left {
      max-width: none;
      margin: 0 0 28px; }
      .activity-intro .activity-intro-left .social-buttons,
      .activity-intro.no-description .activity-intro-left .social-buttons {
        display: flex;
        justify-content: center;
        width: auto; }
    .activity-intro .activity-intro-right,
    .activity-intro.no-description .activity-intro-right {
      margin: 14px 0 0;
      max-width: none; }
      .activity-intro .activity-intro-right .activity-tools,
      .activity-intro.no-description .activity-intro-right .activity-tools {
        width: auto;
        text-align: center; } }

@media (max-width: 961px) {
  .activity-intro-content p:first-child, .activity-intro-content div.paragraph-content:first-child {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -.2px; } }

@media (max-width: 700px) {
  .component-comments {
    padding-left: 28px;
    padding-right: 28px; }
  .component.activity-intro {
    padding-top: 42px !important; }
  .activity-amounts,
  .cs-long-equipment,
  .cs-missing-timing-yield,
  .cs-missing-all-amounts {
    display: block;
    margin-top: 0; }
    .activity-amounts .activity-amounts-item,
    .cs-long-equipment .activity-amounts-item,
    .cs-missing-timing-yield .activity-amounts-item,
    .cs-missing-all-amounts .activity-amounts-item {
      flex: auto;
      width: 100%;
      padding: 14px 28px;
      margin: 0;
      text-align: center !important; }
      .activity-amounts .activity-amounts-item h4, .activity-amounts .activity-amounts-item span.h4-block,
      .cs-long-equipment .activity-amounts-item h4,
      .cs-long-equipment .activity-amounts-item span.h4-block,
      .cs-missing-timing-yield .activity-amounts-item h4,
      .cs-missing-timing-yield .activity-amounts-item span.h4-block,
      .cs-missing-all-amounts .activity-amounts-item h4,
      .cs-missing-all-amounts .activity-amounts-item span.h4-block {
        font-size: 20px;
        margin-bottom: 7px; }
    .activity-amounts .activity-amounts-equipment,
    .cs-long-equipment .activity-amounts-equipment,
    .cs-missing-timing-yield .activity-amounts-equipment,
    .cs-missing-all-amounts .activity-amounts-equipment {
      padding-top: 0;
      text-align: center;
      border-right: none; }
    .activity-amounts .activity-amounts-timing-yield,
    .cs-long-equipment .activity-amounts-timing-yield,
    .cs-missing-timing-yield .activity-amounts-timing-yield,
    .cs-missing-all-amounts .activity-amounts-timing-yield {
      flex-direction: column;
      flex: 1 0 0;
      text-align: center;
      padding: 0; } }
