.joule-app-features {
  display: flex;
  flex-wrap: nowrap;
  padding: 70px 28px 0;
  box-sizing: border-box; }

.joule-app-feature {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column; }

.joule-app-feature-icon {
  width: 50px;
  margin-bottom: 14px; }

.joule-app-feature-text {
  padding: 0 42px 42px; }
  .joule-app-feature-text h4, .joule-app-feature-text span.h4-block {
    margin-bottom: 14px; }
  .joule-app-feature-text p {
    margin-bottom: 0;
    opacity: 0.85; }
  .joule-app-feature-text svg {
    max-height: 70px;
    width: 100%;
    margin-bottom: 14px; }
    .joule-app-feature-text svg .a {
      stroke-width: 2px; }

.joule-app-feature-phone {
  width: 100%; }
  .joule-app-feature-phone img {
    width: 100%;
    vertical-align: top; }

@media (max-width: 900px) {
  .joule-app-features {
    flex-wrap: wrap;
    border-bottom: 0px; }
  .joule-app-feature {
    flex: 0 1 90%;
    max-width: 600px;
    flex-direction: row-reverse;
    margin: auto; }
  .joule-app-feature-text {
    padding: 0 42px; }
  .joule-app-feature-phone {
    border-bottom: 1px solid #edecea; }
  .joule-app-feature:nth-of-type(2) {
    flex-direction: row; }
  .joule-app-feature:nth-of-type(3) .joule-app-feature-phone {
    margin-bottom: 0;
    border-bottom: none; } }

@media (max-width: 600px) {
  .joule-app-feature, .joule-app-feature:nth-of-type(2) {
    flex: 0 1 100%;
    flex-direction: column; }
  .joule-app-feature .joule-app-feature-text {
    padding: 0 0 28px;
    max-width: 300px; }
  .joule-app-feature .joule-app-feature-phone {
    max-width: 300px;
    margin-bottom: 42px; } }

@media (max-width: 450px) {
  .joule-app-features-title {
    padding: 56px 28px 14px; } }
