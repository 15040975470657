.search-typeahead {
  width: 100%;
  position: relative; }
  .search-typeahead .search-typeahead-input {
    width: 100%; }
  .search-typeahead .search-typeahead-results {
    position: absolute;
    background: white;
    width: 100%;
    z-index: 9999; }
    .search-typeahead .search-typeahead-results .search-typeahead-result {
      padding: 5px;
      box-sizing: border-box; }
      .search-typeahead .search-typeahead-results .search-typeahead-result:hover {
        color: #ff674d;
        cursor: pointer; }
