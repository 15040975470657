cs-embed-youtube iframe, cs-embed-vimeo iframe {
  width: 100%; }

.cs-embed-video-wrapper, cs-embed-vimeo, .video-iframe-container, .video-iframe {
  height: 100%; }

cs-embed-youtube, cs-embed-vimeo {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

.video-iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%; }
