.product-specs {
  background-color: #edecea;
  padding-bottom: 70px;
  display: flex;
  min-height: 800px;
  overflow: hidden; }
  .product-specs .external-specs, .product-specs .internal-specs {
    position: relative;
    flex: 1 1 50%; }
  .product-specs .joule-full {
    height: 800px;
    background-size: 2110px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto; }
  .product-specs .specs {
    position: absolute;
    top: 42px; }
    .product-specs .specs .spec {
      position: relative;
      max-width: 260px;
      margin-bottom: 42px;
      padding: 0 28px; }
      .product-specs .specs .spec h4, .product-specs .specs .spec span.h4-block, .product-specs .specs .spec p {
        margin-bottom: 0; }
      .product-specs .specs .spec h4, .product-specs .specs .spec span.h4-block {
        font-size: 18px;
        margin-bottom: 7px; }
      .product-specs .specs .spec p {
        font-size: 14px;
        line-height: 21px;
        font-style: italic; }
  .product-specs .dots .dot {
    position: absolute;
    color: #43413f;
    background-color: rgba(255, 255, 255, 0.9);
    width: 28px;
    height: 28px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; }

.external-specs {
  display: flex;
  justify-content: flex-end; }
  .external-specs .specs {
    right: 252px;
    text-align: right; }
  .external-specs .dot {
    right: 170px; }
  .external-specs .dot-1 {
    top: 100px; }
  .external-specs .dot-2 {
    top: 200px; }
  .external-specs .dot-3 {
    top: 300px;
    right: 80px; }
  .external-specs .dot-4 {
    top: 700px;
    right: 160px; }
  .external-specs .dot-5 {
    top: 730px;
    right: 110px; }

.internal-specs .specs {
  left: 252px;
  text-align: left; }

.internal-specs .dot {
  left: 140px; }

.internal-specs .dot-1 {
  top: 50px;
  left: 120px; }

.internal-specs .dot-2 {
  top: 180px; }

.internal-specs .dot-3 {
  top: 400px;
  left: 120px; }

.internal-specs .dot-4 {
  top: 600px;
  left: 160px; }

.product-specs {
  flex-wrap: wrap; }
  .product-specs .full-specs-link {
    flex: 0 1 100%;
    text-align: center; }

@media (max-width: 1100px) {
  .product-specs {
    padding-bottom: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: inherit; }
    .product-specs .joule-full {
      display: none; }
    .product-specs .cs-image {
      max-width: 400px; }
    .product-specs .external-specs, .product-specs .internal-specs {
      flex: 1 1 100%;
      width: 100%; }
      .product-specs .external-specs .specs .spec, .product-specs .internal-specs .specs .spec {
        max-width: none; }
    .product-specs .external-specs .specs {
      right: 308px; }
    .product-specs .external-specs .dot {
      right: 180px; }
    .product-specs .external-specs .dot-1 {
      top: 100px; }
    .product-specs .external-specs .dot-2 {
      top: 150px; }
    .product-specs .external-specs .dot-3 {
      top: 200px;
      right: 120px; }
    .product-specs .external-specs .dot-4 {
      top: 570px;
      right: 200px; }
    .product-specs .external-specs .dot-5 {
      top: 610px;
      right: 160px; }
    .product-specs .internal-specs .specs {
      left: 308px; }
    .product-specs .internal-specs .dot {
      left: 180px; }
    .product-specs .internal-specs .dot-1 {
      top: 30px; }
    .product-specs .internal-specs .dot-2 {
      top: 120px; }
    .product-specs .internal-specs .dot-3 {
      top: 330px;
      left: 160px; }
    .product-specs .internal-specs .dot-4 {
      top: 490px;
      left: 200px; } }

@media (max-width: 700px) {
  .product-specs .external-specs {
    margin-bottom: 120px; } }

@media (max-width: 600px) {
  .product-specs {
    padding-bottom: 42px; }
    .product-specs .cs-image {
      max-width: 250px; }
    .product-specs .external-specs {
      margin-bottom: 14px; }
      .product-specs .external-specs .cs-image {
        margin-right: -40px; }
      .product-specs .external-specs .specs {
        right: 140px; }
        .product-specs .external-specs .specs .spec:after {
          right: -84px; }
    .product-specs .internal-specs .cs-image {
      margin-left: -40px; }
    .product-specs .internal-specs .specs {
      left: 140px; }
      .product-specs .internal-specs .specs .spec:after {
        left: -84px; }
    .product-specs .specs .spec {
      margin-bottom: 28px; }
      .product-specs .specs .spec h4, .product-specs .specs .spec span.h4-block {
        font-size: 14px; }
      .product-specs .specs .spec p {
        display: none; }
    .product-specs .dots .dot {
      width: 21px;
      height: 21px; }
    .product-specs .external-specs .specs {
      right: 120px; }
    .product-specs .external-specs .dot {
      right: 90px; }
    .product-specs .external-specs .dot-1 {
      top: 18px; }
    .product-specs .external-specs .dot-2 {
      top: 50px; }
    .product-specs .external-specs .dot-3 {
      top: 100px;
      right: 40px; }
    .product-specs .external-specs .dot-4 {
      top: 360px; }
    .product-specs .external-specs .dot-5 {
      top: 380px;
      right: 60px; }
    .product-specs .internal-specs .specs {
      left: 120px; }
    .product-specs .internal-specs .dot {
      left: 90px; }
    .product-specs .internal-specs .dot-1 {
      top: 18px; }
    .product-specs .internal-specs .dot-2 {
      top: 90px;
      left: 70px; }
    .product-specs .internal-specs .dot-3 {
      top: 190px;
      left: 80px; }
    .product-specs .internal-specs .dot-4 {
      top: 300px;
      left: 70px; } }
