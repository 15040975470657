.matrix-item-popup-image {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  position: relative;
  overflow: hidden; }
  .matrix-item-popup-image:before {
    content: '';
    display: block;
    padding-top: 66.66%; }
  .matrix-item-popup-image cs-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    filter: brightness(1);
    -webkit-transition: -webkit-filter 300ms ease-in-out;
    -moz-transition: -moz-filter 300ms ease-in-out;
    -o-transition: -o-filter 300ms ease-in-out; }
  .matrix-item-popup-image custom-button {
    z-index: 2;
    opacity: 0;
    transition: opacity 300ms ease-in-out; }
    .matrix-item-popup-image custom-button .custom-button {
      margin: 0; }
  .matrix-item-popup-image.cs-on-hover-active {
    cursor: pointer; }
    .matrix-item-popup-image.cs-on-hover-active cs-image {
      filter: brightness(0.5); }
    .matrix-item-popup-image.cs-on-hover-active custom-button {
      opacity: 1; }

.matrix-item-popup-text {
  margin: 7px 14px 0; }
  .matrix-item-popup-text p:last-of-type {
    margin-bottom: 0; }
  .matrix-item-popup-text .popup-title {
    font-weight: 400;
    font-style: italic;
    margin-bottom: 0; }
  .matrix-item-popup-text .popup-link {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer; }

.matrix.matrix-3-col.matrix-popup .matrix-item-popup-image {
  min-height: 180px; }
