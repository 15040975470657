.cookie-banner-container {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 200px;
  z-index: 1000;
  padding: 28px 21px;
  background-color: #43413f;
  text-align: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  animation: slidein 1.5s;
  animation-timing-function: ease-in; }

@keyframes slidein {
  0% {
    transform: translateY(100%); }
  66% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

.cookie-banner-text {
  color: white;
  max-width: 600px;
  line-height: 1.3;
  font-size: 16px;
  margin: 0 auto 21px; }
  .cookie-banner-text a {
    text-decoration: underline !important; }

.cookie-banner-button {
  min-width: 150px; }

.cookie-banner-container.ng-leave {
  display: none !important; }

@media (min-width: 1110px) {
  .cookie-banner-container {
    display: flex;
    justify-content: center;
    text-align: left; }
  .cookie-banner-text {
    flex: 1;
    margin: 0; }
  .cookie-banner-button {
    flex: 0 0 150px;
    align-self: center;
    margin-left: 14px; } }
