.nav-ghost .intl-icon .intl-label {
  color: white; }
  .nav-ghost .intl-icon .intl-label:before {
    background-color: white; }

body .cs-nav-container.nav-fixed .cs-nav .intl-label {
  color: #43413f; }

.intl-icon-mobile .intl-label {
  color: #43413f; }

.intl-icon {
  position: relative; }
  .intl-icon .dropdown-wrapper-mobile {
    border-top: none;
    border-bottom: 1px solid #edecea;
    margin: 0 0 28px;
    padding: 0 0 14px; }
    .intl-icon .dropdown-wrapper-mobile .dropdown-menu-item {
      padding: 8px 0;
      margin: 0; }
      .intl-icon .dropdown-wrapper-mobile .dropdown-menu-item div {
        user-select: none; }
  .intl-icon .flag-icon {
    display: inline-block; }
  .intl-icon .divider {
    height: 1px;
    background-color: #edecea;
    margin: 8px 0; }
  .intl-icon .intl-label {
    vertical-align: middle;
    display: inline-block;
    margin-right: 14px;
    user-select: none; }
  .intl-icon .icon-spanner-down {
    vertical-align: middle;
    margin-left: 2px; }
  .intl-icon .intl-dropdown-menu.dropdown-inline {
    transform: none;
    width: 340px;
    margin: 0; }
  .intl-icon .dropdown-wrapper {
    max-height: 300px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff; }
    .intl-icon .dropdown-wrapper.dropdown-wrapper-short {
      height: 115px; }
  .intl-icon .dropdown-menu {
    text-align: left; }
  .intl-icon .intlFlag {
    display: inline-block;
    cursor: pointer;
    font-size: 13px; }
    .intl-icon .intlFlag:focus {
      outline: 1px solid; }
  .intl-icon .intlNoFlag {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 14px;
    display: block;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: background-color 400ms ease; }
    .intl-icon .intlNoFlag:hover, .intl-icon .intlNoFlag:active {
      background-color: rgba(0, 0, 0, 0.1); }
  .intl-icon .intlFlag, .intl-icon .dropdown-menu-item {
    white-space: nowrap;
    cursor: pointer;
    opacity: 1;
    transition: opacity 200ms ease; }
    .intl-icon .intlFlag .flag-icon, .intl-icon .dropdown-menu-item .flag-icon {
      padding: 0 20px 0 0;
      height: 18px; }
    .intl-icon .intlFlag div, .intl-icon .dropdown-menu-item div {
      user-select: none; }
  .intl-icon .dropdown-menu-item:hover {
    opacity: 0.5; }
