.banner .banner-joule-1 {
  position: fixed;
  bottom: -200px;
  left: -200px;
  transition: bottom cubic-bezier(0, 0, 0.5, 1) 300ms, left cubic-bezier(0, 0, 0.5, 1) 300ms;
  z-index: 100;
  background: #ff674d;
  display: flex;
  align-items: flex-end;
  width: 230px;
  height: 230px;
  border-radius: 0 230px 0 0; }
  .banner .banner-joule-1 .banner-image {
    overflow: hidden;
    flex: 0 1 auto; }
    .banner .banner-joule-1 .banner-image img {
      height: 200px;
      width: auto;
      margin-left: -60px; }
  .banner .banner-joule-1 .banner-text {
    padding: 0px;
    padding-bottom: 50px;
    margin-left: -65px; }
    .banner .banner-joule-1 .banner-text h3, .banner .banner-joule-1 .banner-text span.h3-block {
      font-size: 21px;
      line-height: 28px;
      text-align: left;
      margin-bottom: 8px; }

.banner .banner-joule-1.show-banner {
  bottom: 0px;
  left: 0px; }

.theme-dark .banner-joule-1 .button.outline,
.theme-orange .banner-joule-1 .button.outline {
  color: white;
  border-color: white; }
  .theme-dark .banner-joule-1 .button.outline:hover,
  .theme-orange .banner-joule-1 .button.outline:hover {
    background-color: white;
    color: #43413f; }

@media (max-width: 641px) {
  .banner .banner-joule-1 {
    transform: scale(0.7, 0.7) translate(-30%, 30%); } }
