/********** WHAT WE DO **********/
#whatWeValue .value-cont {
  display: flex; }

#whatWeValue .value-content {
  flex: 1 1 90%; }

#whatWeValue .value-img {
  flex: 1 1 10%; }

#whatWeValue .icon-circle {
  max-width: 200px;
  margin-right: 20px;
  width: 100px;
  height: 100px;
  border-radius: 50%; }
  @media (max-width: 756px) {
    #whatWeValue .icon-circle {
      width: 50px;
      height: 50px;
      background-size: 50%; } }
  #whatWeValue .icon-circle.community {
    background-color: #29DB91;
    background-image: url(https://d3awvtnmmsvyot.cloudfront.net/api/file/FXV4X87TWqYamnotKujb);
    background-position: 50% 50%;
    background-repeat: no-repeat; }
  #whatWeValue .icon-circle.experiment {
    background-color: #FDC65B;
    background-image: url(https://d3awvtnmmsvyot.cloudfront.net/api/file/fXy1rx7TQSoJGwyKPWU2);
    background-position: 50% 50%;
    background-repeat: no-repeat; }
  #whatWeValue .icon-circle.shipping {
    background-color: #9FBFD8;
    background-image: url(https://d3awvtnmmsvyot.cloudfront.net/api/file/2cRfoWBQlK1MPMKHdVmP);
    background-position: 50% 50%;
    background-repeat: no-repeat; }

#awards img {
  margin-bottom: 20px; }

#awards h2, #awards span.h2-text-block {
  color: #ff674d; }

@media (max-width: 756px) {
  #awards .matrix-item {
    text-align: left; } }

#press-love blockquote {
  font-size: 26px;
  line-height: 33px;
  font-weight: bold; }
  @media (max-width: 756px) {
    #press-love blockquote {
      font-size: 20px;
      line-height: 27px; } }

#featured-in {
  position: relative; }
  @media (min-width: 701px) {
    #featured-in {
      height: 290px; } }
  @media (max-width: 700px) {
    #featured-in img {
      position: relative;
      display: block;
      margin: 0 auto 40px auto;
      text-align: center; } }
  @media (min-width: 701px) {
    #featured-in img {
      width: 25%;
      position: absolute; }
      #featured-in img#atk {
        top: 0px;
        left: 9%; }
      #featured-in img#foodandwine {
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0; }
      #featured-in img#ci {
        top: 0px;
        right: 9%; }
      #featured-in img#nyt {
        top: 120px;
        left: 4%; }
      #featured-in img#cnet {
        top: 100px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        /*@media (max-width: 900px) {*/
        width: 10%;
        /*}*/ }
      #featured-in img#bonappetit {
        top: 120px;
        right: 4%; }
      #featured-in img#instyle {
        top: 200px;
        left: 17%; }
      #featured-in img#wired {
        top: 200px;
        right: 17%; } }

/********** WHO WE ARE **********/
#who-we-are-intro #intro-description {
  font-size: 23px;
  line-height: 35px;
  align-items: center;
  justify-content: center; }
  @media (max-width: 900px) {
    #who-we-are-intro #intro-description {
      margin: 40px 0;
      font-size: 20px; } }

/********** WORK AT CHEFSTEPS **********/
@media (max-width: 770px) {
  #where-we-work p {
    margin: 40px 0; } }

#perks .checkmark {
  background-image: url(https://d3awvtnmmsvyot.cloudfront.net/api/file/qnY014EPSXuNFeEGi7Tu);
  background-repeat: no-repeat;
  height: 100px;
  width: 60px;
  float: left; }

#perks .perk-desc {
  float: left;
  width: 80%; }
  @media (max-width: 700px) {
    #perks .perk-desc {
      width: 75%; } }
