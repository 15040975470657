@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 1); }
  50% {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 1); }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  33% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(50px); }
  33% {
    opacity: 0;
    transform: translateY(50px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

.joule-selector {
  background-color: white;
  transition: background-color 300ms ease;
  animation: fade-in 1s 0ms 1; }
  .joule-selector .joule-variant span,
  .joule-selector .joule-add-on label span {
    transition: color 300ms ease; }
  .joule-selector .joule-buy-cta .slash,
  .joule-selector .joule-info h2 span {
    transition: color 300ms ease, opacity 300ms ease; }

.joule-selector.joule-white {
  background-color: #8cc8e9;
  background-color: #8fc1dc; }
  .joule-selector.joule-white svg .a {
    stroke: white; }
  .joule-selector.joule-white svg .fill {
    fill: white; }
  .joule-selector.joule-white .joule-nav-items {
    color: white; }
  .joule-selector.joule-white .joule-nav-items span:before {
    background-color: white; }
  .joule-selector.joule-white .joule-info {
    color: white; }
    .joule-selector.joule-white .joule-info p {
      opacity: 0.9; }
  .joule-selector.joule-white .joule-info h2 span.slash,
  .joule-selector.joule-white .joule-variant span,
  .joule-selector.joule-white .joule-add-on .price {
    color: #5d7d8e; }
  .joule-selector.joule-white .joule-info h2 span,
  .joule-selector.joule-white .joule-add-on .price span {
    color: white; }

.joule-selector .joule-nav-items {
  padding: 16px 0;
  background-color: rgba(0, 0, 0, 0.03);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }

.joule-selector-wrapper {
  padding: 28px 42px;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; }

.joule-images {
  flex: 1 1 50%;
  position: relative;
  height: 650px;
  max-width: 420px;
  animation: slide-in-bottom 1s 0ms 1;
  z-index: 1; }
  .joule-images img {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-25%);
    height: 100%;
    width: auto; }

.joule-info {
  flex: 1 1 50%;
  max-width: 330px;
  margin: 0 20px 30px 0;
  color: #43413f;
  z-index: 2;
  transition: color 300ms ease, opacity 300ms ease; }
  .joule-info h1 {
    font-size: 63px;
    line-height: 1;
    display: inline-block;
    margin-bottom: 14px; }
  .joule-info p {
    font-size: 16px;
    opacity: 0.6;
    line-height: 24px;
    margin-bottom: 28px; }

@media (max-width: 750px) {
  .joule-selector-wrapper {
    flex-direction: column;
    padding: 28px 28px 56px; }
  .joule-info {
    margin: 0; }
  .joule-info h1 {
    display: block;
    text-align: center;
    font-size: 56px; }
  .joule-info p {
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 28px; }
  .joule-images, .joule-info {
    flex: 1 1 auto; }
  .joule-images {
    height: auto;
    max-width: none;
    width: 100%;
    height: 300px; }
    .joule-images img {
      transform: translateX(-50%);
      left: 50%; } }

.joule-variants {
  margin-bottom: 28px; }

.joule-variant {
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative; }
  .joule-variant.disabled {
    opacity: 0.5; }
  .joule-variant label {
    font-size: 16px;
    padding-left: 14px;
    cursor: pointer;
    user-select: none;
    flex-grow: 1; }
  .joule-variant span {
    font-size: 17px;
    font-family: 'akkurat';
    color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }

.joule-variant input {
  padding: 0 28px;
  margin-top: 7px;
  width: 100% !important;
  cursor: pointer;
  -webkit-transition: background-color ease 300ms;
  transition: background-color ease 300ms; }

.joule-variant input[type="radio"] {
  padding: 0;
  margin: 0 14px 0 0;
  width: 30px !important;
  min-width: 30px;
  height: 30px;
  background-color: white;
  border: 2px solid #edecea;
  background-image: none;
  box-shadow: none;
  border-radius: 50%;
  position: relative;
  -webkit-appearance: none; }

.joule-variant input[type="radio"]:after {
  border: 2px solid transparent;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: none;
  content: '';
  display: block;
  height: 38px;
  width: 38px;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale3d(0.8, 0.8, 1);
  transition: opacity 300ms ease-in-out, background-color 300ms ease-in-out, transform 300ms ease-in-out; }

.joule-variant input[type="radio"]:checked:after {
  opacity: 1;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  box-shadow: none; }

.joule-variant input[type="radio"]:checked + label {
  font-weight: bold; }

.joule-variant.joule-cs10001 input[type="radio"] {
  background-color: #a3a49e;
  border: 2px solid #a3a49e; }

.joule-variant.joule-cs10001 input[type="radio"]:checked {
  border: 2px solid #a3a49e; }

.joule-variant.joule-cs10001 input[type="radio"]:checked:after {
  border-color: #a3a49e; }

.joule-variant.joule-cs20001 input[type="radio"]:checked {
  border: 2px solid white; }

.joule-variant.joule-cs20001 input[type="radio"]:checked:after {
  border-color: white; }

@media (max-width: 600px) {
  .joule-variants {
    margin-bottom: 35px; } }

.joule-add-ons {
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  width: 100%; }

.joule-add-on {
  position: relative;
  flex: 0 0 50%;
  width: 100%; }
  .joule-add-on:not(:last-child) {
    margin-bottom: 7px; }
  .joule-add-on:hover label, .joule-add-on.checked:hover label {
    background-color: rgba(0, 0, 0, 0.05); }
  .joule-add-on:hover .plus, .joule-add-on.checked:hover .plus {
    transform: translateY(-50%) scale3d(1, 1, 1) rotateZ(90deg); }

.joule-add-on label {
  display: block;
  position: relative;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 28px 28px 56px 58px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
  user-select: none;
  transition: background-color 300ms ease-in-out; }
  .joule-add-on label .add, .joule-add-on label .added {
    display: block;
    position: absolute; }
  .joule-add-on label .add {
    top: 37%;
    transform: translateY(-50%) scale3d(1, 1, 1);
    opacity: 1;
    transition: opacity 300ms 200ms ease-in-out, transform 300ms 200ms ease-in-out; }
  .joule-add-on label .added {
    top: 50%;
    transform: translateY(-50%);
    line-height: 1.3;
    opacity: 0;
    transform: translate(20px, -50%);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out; }

.joule-add-on input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  height: 56px;
  width: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  background-color: transparent;
  border: none;
  background-image: none;
  box-shadow: none;
  border-radius: none;
  -webkit-appearance: none; }

.joule-add-on .check, .joule-add-on .plus {
  position: absolute;
  top: 50%;
  pointer-events: none;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out; }

.joule-add-on .plus {
  transform: translateY(-50%) scale3d(1, 1, 1);
  opacity: 1; }

.joule-add-on .check {
  transform: translateY(-50%) scale3d(0, 0, 0);
  opacity: 0; }

.joule-add-on .check, .joule-add-on .plus {
  content: "";
  left: 20px;
  width: 20px;
  height: 20px; }
  .joule-add-on .check svg .a, .joule-add-on .plus svg .a {
    stroke-width: 2px; }

.joule-add-on .price {
  position: absolute;
  top: 63%;
  left: 58px;
  transform: translateY(-50%) scale3d(1, 1, 1);
  pointer-events: none;
  transition: opacity 300ms 200ms ease-in-out, transform 300ms 200ms ease-in-out;
  font-size: 16px;
  font-family: 'akkurat';
  color: rgba(0, 0, 0, 0.4);
  opacity: 1; }
  .joule-add-on .price span {
    color: #ff674d;
    font-size: 14px;
    text-transform: uppercase;
    transition: color 300ms ease-in-out; }

.joule-add-on .hover {
  position: absolute;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  width: calc(100% + 7px);
  top: -20px;
  left: 0;
  transform: translateY(-90%);
  display: flex;
  background-color: #43413f;
  border-radius: 6px;
  color: white;
  padding: 21px;
  box-sizing: border-box;
  transition: opacity 300ms 0ms ease-in-out, transform 300ms 0ms ease-in-out; }
  .joule-add-on .hover svg, .joule-add-on .hover img {
    flex: 0 0 50px;
    width: 50px;
    margin-right: 14px; }
    .joule-add-on .hover svg .a, .joule-add-on .hover img .a {
      stroke: white; }
  .joule-add-on .hover p {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 0;
    opacity: 1; }
  .joule-add-on .hover:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50px;
    transform: translate(-50%, 100%);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #43413f; }

.joule-add-on:last-child .hover {
  right: 0;
  left: auto; }
  .joule-add-on:last-child .hover:after {
    left: auto;
    right: 50px; }

.joule-add-on:hover .hover {
  opacity: 1;
  transform: translateY(-100%);
  transition: opacity 300ms 100ms ease-in-out, transform 300ms 100ms ease-in-out; }

.joule-add-on.checked .hover {
  opacity: 0;
  transform: translateY(-90%); }

.joule-add-on.checked label {
  background-color: rgba(0, 0, 0, 0.08); }
  .joule-add-on.checked label .add {
    transform: translateY(-50%) scale3d(0.7, 0.7, 0);
    opacity: 0;
    transition: opacity 300ms 0ms ease-in-out, transform 300ms 0ms ease-in-out; }
  .joule-add-on.checked label .added {
    opacity: 1;
    transform: translate(0px, -50%);
    transition: opacity 300ms 100ms ease-in-out, transform 300ms 100ms ease-in-out; }

.joule-add-on.checked .price {
  transform: translateY(-50%) scale3d(0.7, 0.7, 0);
  opacity: 0;
  transition: opacity 300ms 0ms ease-in-out, transform 300ms 0ms ease-in-out; }

.joule-add-on.checked .check {
  transform: translateY(-50%) scale3d(1, 1, 1);
  opacity: 1; }

.joule-add-on.checked .plus {
  transform: translateY(-50%) scale3d(0, 0, 0);
  opacity: 0; }

@media (max-width: 750px) {
  .joule-add-ons {
    width: calc(100% + 28px);
    transform: translateX(-17px); }
  .joule-add-on .hover {
    display: none; } }

@media (max-width: 400px) {
  .joule-add-on label {
    height: 76px;
    display: flex;
    align-items: center; }
  .joule-add-on label span {
    display: block; } }

.joule-buy-cta {
  display: flex;
  justify-content: space-between; }
  .joule-buy-cta .button {
    width: 100%;
    min-width: 170px; }

h2.mobile-price {
  display: none; }

h2.desktop-price {
  display: inline-block;
  padding-left: 14px; }

h2.mobile-price,
h2.desktop-price {
  font-size: 35px;
  line-height: 1;
  margin: 0; }
  h2.mobile-price span,
  h2.desktop-price span {
    font-family: 'akkurat';
    font-weight: 400;
    color: #999999; }
  h2.mobile-price .sale,
  h2.desktop-price .sale {
    opacity: 1;
    color: #ff674d; }
  h2.mobile-price .slash,
  h2.desktop-price .slash {
    font-size: 28px;
    margin-left: 0;
    position: relative;
    text-decoration: line-through; }

.international-cta .button {
  min-width: 250px;
  margin-bottom: 14px; }

.international-prompt {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 28px;
  margin-bottom: 28px; }

@media (max-width: 750px) {
  .joule-buy-cta .button {
    width: auto; }
  h2.mobile-price {
    display: inline-block; }
  h2.desktop-price {
    display: none; }
  .international-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

@media (max-width: 450px) {
  .joule-buy-cta {
    flex-direction: column;
    justify-content: center; }
    .joule-buy-cta h2, .joule-buy-cta .buttonUrl {
      width: 100%;
      text-align: center; }
    .joule-buy-cta h2 {
      order: 1;
      margin-bottom: 28px; }
    .joule-buy-cta .button {
      order: 2; }
  .international-prompt {
    padding: 21px;
    text-align: center; } }

@media (max-width: 350px) {
  .joule-add-on label {
    padding: 28px 28px 56px 45px; }
  .joule-add-on .price {
    left: 45px; }
  .joule-add-on .check, .joule-add-on .plus {
    left: 15px; } }
