.joule-text-block .text-block {
  max-width: 600px;
  margin: 0 auto 28px; }
  .joule-text-block .text-block p {
    opacity: 0.8; }

.joule-text-block .text-icon {
  text-align: center; }
  .joule-text-block .text-icon svg {
    height: 85px;
    width: auto;
    margin-bottom: 7px; }
    .joule-text-block .text-icon svg .a, .joule-text-block .text-icon svg .b {
      stroke-width: 2px; }

.joule-pitch .text-block, .joule-pitch p:last-child {
  margin-bottom: 0; }
