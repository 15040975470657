.banner .banner-inline {
  box-sizing: border-box;
  padding: 56px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .banner .banner-inline h3, .banner .banner-inline span.h3-block {
    margin-bottom: 0;
    margin-right: 28px; }
  .banner .banner-inline .custom-button {
    margin-bottom: 0; }

@media (max-width: 640px) {
  .banner .banner-inline {
    padding: 28px 28px;
    flex-direction: column; }
    .banner .banner-inline h3, .banner .banner-inline span.h3-block {
      margin-bottom: 14px;
      margin-right: 0; } }
