.joule-intl-selector .joule-info {
  max-width: 340px; }

.joule-intl-selector .total-price {
  position: relative;
  width: 100%;
  margin-bottom: 14px; }

.joule-intl-selector .desktop-price {
  display: block; }

.joule-intl-selector .mobile-price {
  display: none;
  order: 1;
  margin-bottom: 28px;
  width: 100%;
  text-align: center;
  position: relative; }

.joule-intl-selector .mobile-price h2,
.joule-intl-selector .desktop-price h2 {
  font-size: 35px;
  line-height: 1;
  margin: 0;
  padding: 0; }
  .joule-intl-selector .mobile-price h2 span,
  .joule-intl-selector .desktop-price h2 span {
    font-family: 'akkurat';
    font-weight: 400;
    color: #999999; }
  .joule-intl-selector .mobile-price h2 .sale,
  .joule-intl-selector .desktop-price h2 .sale {
    opacity: 1;
    color: #ff674d; }
  .joule-intl-selector .mobile-price h2 .slash,
  .joule-intl-selector .desktop-price h2 .slash {
    font-size: 28px;
    margin-left: 0;
    position: relative;
    text-decoration: line-through; }

.joule-intl-selector .desktop-price .price-labels {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: 1px solid rgba(67, 65, 63, 0.6);
  padding: 4px;
  border-radius: 4px; }

.joule-intl-selector .mobile-price .price-labels {
  position: relative;
  top: auto;
  right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  transform: none; }
  .joule-intl-selector .mobile-price .price-labels .tax {
    padding-left: 10px; }

.joule-intl-selector .tax, .joule-intl-selector .free-shipping {
  font-size: 12px;
  line-height: 14px;
  color: rgba(67, 65, 63, 0.6);
  font-weight: 'circular'; }

.joule-intl-selector .out-of-stock {
  opacity: 0.8; }

@media (max-width: 750px) {
  .joule-intl-selector .mobile-price {
    display: block; }
  .joule-intl-selector .desktop-price {
    display: none; }
  .joule-intl-selector .joule-buy-cta {
    flex-direction: column;
    justify-content: center; }
  .joule-intl-selector .joule-buy-cta .button {
    order: 2; } }

.joule-variant.joule-stainless input[type="radio"] {
  background-color: #a3a49e;
  border: 2px solid #a3a49e; }

.joule-variant.joule-stainless input[type="radio"]:checked {
  border: 2px solid #a3a49e; }

.joule-variant.joule-stainless input[type="radio"]:checked:after {
  border-color: #a3a49e; }

.joule-variant.joule-white input[type="radio"]:checked {
  border: 2px solid white; }

.joule-variant.joule-white input[type="radio"]:checked:after {
  border-color: white; }

.joule-intl-selector .joule-info p {
  opacity: 1;
  color: rgba(67, 65, 63, 0.6);
  transition: color 300ms ease; }

.joule-intl-selector.joule-selector.joule-white .joule-info p {
  opacity: 1;
  color: rgba(255, 255, 255, 0.9); }

.joule-intl-selector .joule-add-on .hover p {
  color: white;
  margin-bottom: 0 !important; }

.joule-intl-selector.long-layout .joule-selector-wrapper {
  align-items: flex-start; }

.joule-intl-selector.long-layout .joule-info {
  padding-top: 42px; }

@media (max-width: 750px) {
  .joule-intl-selector.long-layout .joule-selector-wrapper {
    align-items: center; }
  .joule-intl-selector.long-layout .joule-info {
    padding-top: 0; } }

.joule-intl-selector .joule-add-on {
  flex: 1 0 50%; }
  .joule-intl-selector .joule-add-on .price {
    line-height: 1; }

.joule-intl-selector .joule-add-on.only-premium {
  margin-right: 0; }
  .joule-intl-selector .joule-add-on.only-premium label {
    font-size: 16px;
    padding-bottom: 45px; }
    .joule-intl-selector .joule-add-on.only-premium label .add {
      top: 50%; }
    .joule-intl-selector .joule-add-on.only-premium label .added {
      max-width: none; }
  .joule-intl-selector .joule-add-on.only-premium .price {
    top: 50%;
    right: 25px;
    left: auto; }
  .joule-intl-selector .joule-add-on.only-premium .hover {
    width: 100%; }

.joule-intl-selector .international-prompt {
  position: relative;
  background-color: transparent;
  padding: 21px 0 0;
  margin: 42px 0;
  border-radius: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .joule-intl-selector .international-prompt p {
    color: #43413f;
    margin-bottom: 0 !important; }
  .joule-intl-selector .international-prompt svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -60%);
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    color: #43413f;
    background-color: white;
    padding: 0 7px; }
    .joule-intl-selector .international-prompt svg .a, .joule-intl-selector .international-prompt svg .b {
      stroke: #43413f;
      stroke-width: 2px; }
    .joule-intl-selector .international-prompt svg .c {
      fill: #43413f; }

.joule-intl-selector .international-cta .button {
  width: 100%; }

@media (max-width: 750px) {
  .joule-intl-selector .international-prompt {
    padding: 21px 0 7px;
    margin: 42px 0 0;
    border-bottom: none; } }

.joule-intl-selector .joule-info p {
  margin-bottom: 14px; }

@media (max-width: 750px) {
  .joule-intl-selector .joule-info p {
    border-bottom: none;
    padding-bottom: 0; }
  .joule-intl-selector .joule-info .joule-desc {
    margin-bottom: 28px; } }

.joule-intl-selector .joule-info p.shipping-notif {
  color: #ff674d;
  opacity: 1; }

.joule-intl-selector.joule-white .joule-info p.shipping-notif {
  color: #5d7d8e;
  background-color: #fff;
  padding: 5px 10px;
  opacity: .4;
  border-radius: 5px; }

.joule-intl-selector .joule-info .joule-app-notif {
  padding-top: 28px; }
  .joule-intl-selector .joule-info .joule-app-notif p {
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin: 0;
    padding-bottom: 10px;
    border-bottom: none; }

.joule-warning, a.joule-warning {
  width: 100%;
  padding: 35px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #ff674d;
  color: white;
  transition: opacity 300ms ease; }
  .joule-warning svg, a.joule-warning svg {
    padding-right: 21px;
    flex-shrink: 0; }
    .joule-warning svg .a, a.joule-warning svg .a {
      stroke: white; }
  .joule-warning p, a.joule-warning p {
    max-width: 500px;
    margin: 0;
    font-size: 16px;
    line-height: 21px; }

a.joule-warning:hover {
  opacity: 0.8; }

@media (max-width: 750px) {
  .joule-warning {
    padding: 28px; }
  .joule-warning p,
  a.joule-warning p {
    font-size: 14px;
    line-height: 18px; } }
