body.show-modals {
  overflow: hidden; }

modals {
  position: relative; }

.modals {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1000; }
  .modals i.close-x, .modals span.close-x {
    padding: 14px;
    position: fixed;
    top: 14px;
    right: 14px;
    cursor: pointer;
    z-index: 99; }

.modal-message {
  color: #ff674d; }

.modal {
  max-width: 520px;
  max-height: 100vh;
  width: 100%;
  background-color: white;
  color: #43413f;
  box-sizing: border-box;
  padding: 56px 84px;
  border: 1px solid #edecea;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate3d(0, -50%, 0);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto; }
  .modal .modal-top {
    text-align: center; }
    .modal .modal-top .modal-error {
      color: #ff674d; }
  .modal .modal-body {
    text-align: center; }
    .modal .modal-body .form-input {
      width: 100%;
      margin-bottom: 14px; }
      .modal .modal-body .form-input .form-input-error {
        text-align: left; }
      .modal .modal-body .form-input input {
        width: 100%;
        border-color: #43413f;
        border-radius: 0; }
        .modal .modal-body .form-input input::-webkit-input-placeholder {
          color: #43413f;
          font-weight: 500; }
        .modal .modal-body .form-input input:-moz-placeholder {
          color: #43413f;
          font-weight: 500; }
        .modal .modal-body .form-input input::-moz-placeholder {
          color: #43413f;
          font-weight: 500; }
        .modal .modal-body .form-input input:-ms-input-placeholder {
          color: #43413f;
          font-weight: 500; }
      .modal .modal-body .form-input:last-of-type {
        margin-bottom: 56px; }
    .modal .modal-body .button {
      margin-bottom: 28px; }
    .modal .modal-body .modal-submit {
      min-width: 210px; }
  .modal .modal-bottom {
    display: flex;
    justify-content: space-around;
    color: #b8b6b4;
    text-align: center; }
    .modal .modal-bottom small {
      margin-bottom: 0; }
    .modal .modal-bottom a {
      text-decoration: underline;
      transition: color 200ms ease; }
      .modal .modal-bottom a:hover {
        cursor: pointer;
        color: #43413f; }

@media (max-width: 640px) {
  body.show-modals {
    position: fixed; }
  .modals {
    background-color: white; }
    .modals i.close-x, .modals span.close-x {
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 4px; }
  .modal {
    height: 100%;
    max-width: 100%;
    padding: 56px 42px;
    border: none; }
    .modal .modal-top, .modal .modal-body, .modal .modal-bottom {
      width: 100%; }
    .modal .modal-bottom {
      padding-bottom: 84px; } }

.modals-animate-fade, .modal-animate-fade {
  opacity: 1;
  transition: opacity ease 400ms; }

.modals-animate-fade.ng-hide, .ng-hide .modal-animate-fade {
  opacity: 0; }

.modal-animate-slide.ng-animate,
.modal-animate-fade.ng-animate {
  transition: transform ease 400ms, opacity ease 400ms; }

.modal-animate-slide.ng-enter,
.modal-animate-fade.ng-enter {
  transition: transform ease 400ms 400ms, opacity ease 400ms 400ms; }

.modal-animate-fade.ng-leave.ng-leave-active,
.modal-animate-fade.ng-enter {
  opacity: 0; }

.modal-animate-fade.ng-leave,
.modal-animate-fade.ng-enter.ng-enter-active {
  opacity: 1; }

.modal-animate-slide.ng-leave.ng-leave-active,
.modal-animate-slide.ng-enter {
  opacity: 0;
  transform: translate3d(0, -65%, 0); }

.modal-animate-slide.ng-leave,
.modal-animate-slide.ng-enter.ng-enter-active {
  opacity: 1;
  transform: translate3d(0, -50%, 0); }
