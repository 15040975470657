@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center; }
  .loading-spinner svg {
    animation: spin 2s infinite linear; }
