.component-divider .component-menu {
  flex-direction: row;
  z-index: 100;
  padding: 0 28px;
  align-items: flex-start; }
  .component-divider .component-menu .button {
    margin: -21px 3px 0 3px; }

.component-divider {
  overflow: visible; }
  .component-divider .divider {
    padding-top: 84px;
    padding-bottom: 84px; }
    .component-divider .divider hr {
      margin: 0; }

.component-divider .divider.divider-height-short {
  padding-top: -42px;
  padding-bottom: 42px; }

@media (max-width: 641px) {
  .component-divider .divider {
    padding-top: 56px;
    padding-bottom: 56px; }
  .component-divider .divider.divider-height-short {
    padding-top: 0;
    padding-bottom: 0; } }
