.input-switch {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden; }

.input-switch input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden; }

.input-switch label {
  background-color: white;
  font-weight: bold;
  color: #43413f;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  border: 1px solid #43413f;
  transition: all 0.1s ease-in-out; }

.input-switch label:hover {
  cursor: pointer; }

.input-switch input:checked + label {
  background-color: #43413f;
  color: white;
  box-shadow: none; }

.input-switch label:first-of-type {
  border-radius: 20px 0 0 20px; }

.input-switch label:last-of-type {
  border-radius: 0 20px 20px 0; }

.gallery-results.gallery-classes {
  min-height: 0px; }

.popular-classes {
  background-color: white !important;
  margin: 48px 0px !important; }

.text-item {
  padding: 0 14px; }
  .text-item .h3-lable {
    margin-bottom: 14px !important; }

.text-classes {
  padding-top: 0px !important; }

.button-padding {
  padding-bottom: 15px; }

.component.gallery-nav.activity-type-nav {
  padding: 14px;
  flex-direction: column; }
  .component.gallery-nav.activity-type-nav span.h5-block {
    margin-bottom: 0; }

@media (max-width: 961px) {
  .text-item {
    padding: 0 28px; } }
