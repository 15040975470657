.current-updateAddress h2 {
  margin-bottom: 14px; }

.current-updateAddress h4 {
  margin-bottom: 0; }

.update-address {
  max-width: 500px;
  margin: auto; }
  .update-address .form-input-group {
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap; }
  .update-address .form-input, .update-address .form-input input {
    width: 100%;
    flex: 1 0 100%; }
  .update-address .form-input {
    padding: 0;
    border: none; }
  .update-address .form-input input {
    height: 56px;
    border: none;
    border-bottom: 1px solid #edecea;
    padding: 0 10px; }
    .update-address .form-input input:active, .update-address .form-input input:focus {
      border-bottom: 2px solid #ff674d; }
  .update-address .three-col input {
    border-right: 1px solid #edecea; }
  .update-address .form-input:last-of-type input {
    border-right: none; }
  .update-address .form-input.three-col {
    width: 33%;
    flex: 1 0 33%;
    display: inline-block; }
  .update-address .button {
    margin: 42px auto 28px; }

.errors {
  margin-bottom: 28px; }
  .errors .error-message {
    color: #ff674d;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold; }

@media (max-width: 550px) {
  .update-address .form-input.three-col {
    width: 100%;
    flex: 1 0 100%;
    display: block; }
  .update-address .three-col input {
    border-right: none; } }
