.step-number {
  display: inline-block;
  border: 2px solid #43413f;
  color: #43413f;
  background-color: transparent;
  box-sizing: border-box;
  padding: 21px 0;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  margin-bottom: 28px; }
  .step-number h3, .step-number span.h3-block {
    font-family: Akkurat;
    font-size: 36px;
    line-height: 28px;
    font-weight: normal; }

.cs-no-step-title .step-number {
  margin-bottom: 14px; }

.component-step {
  display: block; }
  .component-step h3, .component-step span.h3-block {
    margin-bottom: 14px; }

.cs-step-ingredients .ingredients {
  padding: 28px 0 0;
  margin: 28px 0 0;
  border-top: 1px solid #edecea;
  display: block; }

.step-image-container + .component-small > .step-directions {
  margin-top: 42px; }
  .step-image-container + .component-small > .step-directions > div.paragraph-content:last-child {
    margin-bottom: 0; }

.step-directions.cs-short-step > p:first-child,
.step-directions.cs-short-step > div.paragraph-content:first-child {
  text-align: center;
  margin-top: 0;
  margin-bottom: 42px;
  padding-top: 0;
  border-top: none; }

.step-directions > :first-child {
  padding-top: 28px;
  margin-top: 28px;
  border-top: 1px solid #edecea; }

.aside .step-directions > :first-child {
  border-top: none;
  padding-top: 14px;
  margin-top: 0; }

.step-directions:last-child {
  margin-bottom: 0px; }

.step-directions img {
  width: 154.28571%;
  transform: translateX(-190px); }

.step-directions .cs-image img,
.cs-image img {
  width: 100%;
  display: block;
  transform: none; }

.component-step:first-of-type {
  margin-top: 0px !important; }

.step-contents-container {
  margin-bottom: 28px;
  transform: translateX(0px);
  transition: transform 0.3s; }

.step-contents-container.active {
  transform: translateX(-100px);
  transition: transform 0.3s; }

.step-image-only .step-image {
  margin-top: 0; }

.has-step-image + .step-image-only {
  margin-top: -42px; }

.step-image, .step-video {
  box-sizing: border-box;
  overflow: hidden;
  display: block;
  position: relative;
  margin-top: 56px; }
  .step-image .cs-image, .step-video .cs-image {
    background-color: #edecea; }

.step-image-container {
  display: flex;
  justify-content: center; }

.aside .aside-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 14px; }
  .aside .aside-icon i, .aside .aside-icon h5, .aside .aside-icon span.h5-block, .aside .aside-icon span[cs-icon] {
    display: inline-block;
    margin-bottom: 0; }
  .aside .aside-icon h5, .aside .aside-icon span.h5-block {
    margin-left: 7px; }

.aside-expander {
  margin-top: 28px; }

.aside .step-contents-wrapper {
  position: relative;
  max-height: 112px;
  overflow: hidden; }
  .aside .step-contents-wrapper:after {
    content: "";
    width: 100%;
    height: 112px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(top, rgba(237, 236, 234, 0) 0%, #edebea 84%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(237, 236, 234, 0)), color-stop(84%, #edebea));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(237, 236, 234, 0) 0%, #edebea 84%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(237, 236, 234, 0) 0%, #edebea 84%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(237, 236, 234, 0) 0%, #edebea 84%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(237, 236, 234, 0) 0%, #edebea 84%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00edecea', endColorstr='#edebea',GradientType=0 );
    /* IE6-9 */ }

.aside-expanded .step-contents-wrapper {
  max-height: none; }
  .aside-expanded .step-contents-wrapper:after {
    display: none; }

.step-video {
  height: 624.375px; }

.appliance-instruction {
  box-sizing: border-box;
  margin-top: 28px;
  padding: 28px 28px;
  width: 100%;
  border: 1px solid #edecea;
  border-radius: 4px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .appliance-instruction .appliance-instruction-image {
    margin-right: 28px;
    flex: 1;
    max-width: 200px; }
  .appliance-instruction p, .appliance-instruction div.paragraph-content {
    margin: 0;
    flex: 1;
    white-space: pre-wrap; }

@media (max-width: 1166px) {
  .step-video {
    height: 56.25vw; }
  .aside .step-image {
    padding: 0 28px;
    box-sizing: border-box; }
  .step-directions img {
    width: 100%;
    transform: translateX(0); } }

@media (max-width: 756px) {
  .step-contents-container .component-small {
    padding: 0 28px; } }

@media (max-width: 960px) {
  .step-contents-container .ingredient {
    padding: 0; } }

@media (max-width: 641px) {
  .appliance-instruction {
    flex-wrap: wrap;
    justify-content: center; }
    .appliance-instruction > div {
      padding-top: 14px; }
    .appliance-instruction .appliance-instruction-image {
      margin-right: 0; }
  .step-number {
    margin-bottom: 21px; }
  .step-image, .step-video {
    margin-top: 35px; }
  .cs-step-ingredients .ingredients,
  .step-directions > :first-child {
    padding: 21px 0 0;
    margin: 21px 0 0; } }
