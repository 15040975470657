carousel .carousel-more-block {
  display: block; }
  carousel .carousel-more-block .simple-carousel-with-description-more .more-image {
    position: relative;
    width: 100%;
    margin-bottom: 28px; }
    carousel .carousel-more-block .simple-carousel-with-description-more .more-image svg {
      position: absolute;
      bottom: 0; }
  carousel .carousel-more-block .simple-carousel-with-description-more .more-image.hover {
    display: none; }
  carousel .carousel-more-block .simple-carousel-with-description-more:hover .more-image {
    display: none; }
  carousel .carousel-more-block .simple-carousel-with-description-more:hover .more-image.hover {
    display: block; }
  carousel .carousel-more-block .simple-carousel-more .more-image {
    position: relative;
    width: 196px;
    height: 196px;
    margin-bottom: 14px; }
    carousel .carousel-more-block .simple-carousel-more .more-image svg {
      position: absolute;
      bottom: 0; }
  carousel .carousel-more-block .simple-carousel-more .more-image.hover {
    display: none; }
  carousel .carousel-more-block .simple-carousel-more:hover .more-image {
    display: none; }
  carousel .carousel-more-block .simple-carousel-more:hover .more-image.hover {
    display: block; }
  carousel .carousel-more-block .full-carousel-more .more-image {
    position: relative;
    width: 100%;
    border-radius: 4px 4px 0 0;
    position: relative;
    overflow: hidden; }
    carousel .carousel-more-block .full-carousel-more .more-image:before {
      content: '';
      display: block;
      padding-top: 66.66%; }
    carousel .carousel-more-block .full-carousel-more .more-image svg {
      position: absolute;
      bottom: 0; }
  carousel .carousel-more-block .full-carousel-more .more-image.hover {
    display: none; }
  carousel .carousel-more-block .full-carousel-more:hover .more-image {
    display: none; }
  carousel .carousel-more-block .full-carousel-more:hover .more-image.hover {
    display: block; }
  carousel .carousel-more-block .more-text h4, carousel .carousel-more-block .more-text span.h4-block {
    margin-bottom: 7px; }
  carousel .carousel-more-block .more-text-full {
    padding: 28px 0 !important; }
    carousel .carousel-more-block .more-text-full h4, carousel .carousel-more-block .more-text-full span.h4-block {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 0; }

carousel .hero-type-standard::after {
  content: none; }

carousel .hero-type-standard .matrix-media {
  margin-top: 56px; }

carousel .hero-type-standard .h1-block {
  margin-top: 14px; }

carousel .hero-type-standard .header > span {
  color: white; }

carousel .hero-type-standard .circle-block .content {
  top: 40%; }

carousel .owl-dot.active > span {
  background: #ff674d !important; }

carousel .no-carousel.owl-stage-outer {
  display: flex;
  align-items: center;
  justify-content: center; }

carousel .extend-padded {
  padding-right: calc((100% - 1150px)/2); }

carousel .truncate-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical; }

.carousel {
  position: relative; }
  .carousel .matrix-media {
    border-left: 2px solid transparent;
    border-right: 2px solid transparent; }
  .carousel .simple-carousel-with-description:not(.is-mobile-carousel),
  .carousel .full-carousel:not(.is-mobile-carousel) {
    padding-right: 10px;
    padding-left: 10px; }
  .carousel .h2-block {
    margin-bottom: 23.8px; }
  .carousel .right-border-continue:not(.is-mobile-carousel) {
    border-right: 2px solid #FF917E; }
    .carousel .right-border-continue:not(.is-mobile-carousel).simple-carousel {
      padding-right: 0; }
  .carousel .left-border-continue:not(.is-mobile-carousel) {
    border-left: 2px solid #FF917E; }
    .carousel .left-border-continue:not(.is-mobile-carousel).simple-carousel {
      padding-left: 0; }
  .carousel .matrix-media:not(.left-border-continue):not(.is-mobile-carousel) .owl-stage {
    padding-left: 0 !important; }
  .carousel .matrix-media.is-mobile-carousel .owl-stage {
    padding-left: 15px !important; }
  .carousel .matrix-media.is-mobile-carousel .owl-nav {
    display: none; }
  .carousel button.owl-prev {
    position: absolute;
    top: 42%;
    left: -6%;
    transform: rotate(90deg);
    border-radius: 50% !important;
    border: 2px solid !important; }
    .carousel button.owl-prev span.icon-spanner-down {
      position: absolute;
      top: 33%;
      left: 30%; }
    .carousel button.owl-prev.disabled {
      display: none; }
  .carousel button.owl-next {
    position: absolute;
    top: 42%;
    right: -6%;
    transform: rotate(270deg);
    border-radius: 50% !important;
    border: 2px solid !important; }
    .carousel button.owl-next span.icon-spanner-down {
      position: absolute;
      top: 33%;
      left: 30%; }
    .carousel button.owl-next.disabled {
      display: none; }
  .carousel .owl-nav.disabled > * {
    display: none; }

.component.theme-light .carousel .owl-nav button.owl-prev:hover, .component.theme-light .carousel .owl-nav button.owl-next:hover, .component.theme-light-with-bottom-line .carousel .owl-nav button.owl-prev:hover, .component.theme-light-with-bottom-line .carousel .owl-nav button.owl-next:hover {
  color: #43413f !important;
  background-color: #43413f !important; }
  .component.theme-light .carousel .owl-nav button.owl-prev:hover span.icon-spanner-down, .component.theme-light .carousel .owl-nav button.owl-next:hover span.icon-spanner-down, .component.theme-light-with-bottom-line .carousel .owl-nav button.owl-prev:hover span.icon-spanner-down, .component.theme-light-with-bottom-line .carousel .owl-nav button.owl-next:hover span.icon-spanner-down {
    color: white !important; }

.component.theme-dark .carousel .owl-nav button.owl-prev:hover, .component.theme-dark .carousel .owl-nav button.owl-next:hover {
  background-color: white !important; }
  .component.theme-dark .carousel .owl-nav button.owl-prev:hover span.icon-spanner-down, .component.theme-dark .carousel .owl-nav button.owl-next:hover span.icon-spanner-down {
    color: #43413f !important; }

.component.theme-gray .carousel .owl-nav button.owl-prev, .component.theme-gray .carousel .owl-nav button.owl-next {
  background-color: #edecea !important; }
  .component.theme-gray .carousel .owl-nav button.owl-prev:hover, .component.theme-gray .carousel .owl-nav button.owl-next:hover {
    color: #43413f !important;
    background-color: #43413f !important; }
    .component.theme-gray .carousel .owl-nav button.owl-prev:hover span.icon-spanner-down, .component.theme-gray .carousel .owl-nav button.owl-next:hover span.icon-spanner-down {
      color: white !important; }

.component.theme-orange .carousel .owl-nav button.owl-prev:hover, .component.theme-orange .carousel .owl-nav button.owl-next:hover {
  background-color: white !important; }
  .component.theme-orange .carousel .owl-nav button.owl-prev:hover span.icon-spanner-down, .component.theme-orange .carousel .owl-nav button.owl-next:hover span.icon-spanner-down {
    color: #ff674d !important; }

.component.theme-orange .owl-dot.active > span {
  background: #7a7876 !important; }

.empty-myrecipe a {
  margin-top: 14px; }

hr.divider {
  margin: 0 0 !important; }

@media (max-width: 360px) {
  .more-image {
    position: relative;
    overflow: hidden; }
    .more-image:before {
      content: '';
      display: block;
      padding-top: 50%; } }
