cs-background-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.cs-background-video-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 500ms ease-out; }

.cs-background-video-playing .cs-background-video-image {
  opacity: 0; }

.cs-background-video-playing cs-embed-youtube, .cs-background-video-playing cs-embed-vimeo, .cs-background-video-playing cs-embed-native {
  opacity: 1; }

.cs-background-video-paused .cs-background-video-image {
  opacity: 1; }

.cs-background-video-paused cs-embed-youtube, .cs-background-video-paused cs-embed-vimeo, .cs-background-video-paused cs-embed-native {
  opacity: 0; }
