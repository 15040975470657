.nell-popup {
  max-height: 90vh;
  position: fixed;
  background-color: white;
  z-index: 1000;
  border-radius: 4px;
  overflow-y: scroll;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  width: 100%;
  max-width: 500px;
  opacity: 1;
  transition: opacity ease 300ms; }
  .nell-popup.ng-hide {
    opacity: 0; }
  .nell-popup .loading-spinner {
    padding: 42px; }

.nell-close-icon {
  cursor: pointer;
  position: absolute;
  color: #43413f;
  padding: 14px;
  top: 14px;
  right: 14px;
  z-index: 101; }

.has-image .nell-card-media-header {
  display: block; }

.has-image .nell-card-header {
  display: none; }

.has-image .nell-close-icon {
  color: white; }

.nell-card-header {
  width: 100%;
  padding: 56px 42px 0;
  box-sizing: border-box;
  text-align: center; }
  .nell-card-header h6, .nell-card-header span.h6-block {
    margin-bottom: 0; }
  .nell-card-header h3, .nell-card-header span.h3-block {
    margin-bottom: 0; }

.nell-card-media-header {
  display: none;
  position: relative;
  width: 100%;
  height: 281.25px;
  background-color: #43413f; }
  .nell-card-media-header .nell-card-title {
    position: absolute;
    color: white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 28px;
    top: 0;
    left: 0;
    z-index: 100;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5); }
    .nell-card-media-header .nell-card-title h6, .nell-card-media-header .nell-card-title span.h6-block, .nell-card-media-header .nell-card-title h3, .nell-card-media-header .nell-card-title span.h3-block {
      margin-bottom: 0; }

.nell-card-body {
  width: 100%;
  padding: 42px;
  box-sizing: border-box;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .nell-card-body .short-summary p:last-child {
    margin-bottom: 0; }
  .nell-card-body .needs-work {
    color: #ff674d; }
  .nell-card-body .nell-card-body-buttons {
    display: flex;
    margin-top: 28px; }
    .nell-card-body .nell-card-body-buttons a {
      margin: 0 7px; }

@media (max-width: 528px) {
  .nell-popup {
    width: 90%;
    max-height: 90%; }
  .nell-close-icon {
    top: 0;
    right: 0; }
  .nell-card-media-header {
    height: 50.625vw; }
  .nell-card-header {
    padding: 42px 28px 0; }
  .nell-card-text {
    padding: 28px; } }

@media (max-width: 420px) {
  .nell-card-body .nell-card-body-buttons {
    flex-direction: column; }
    .nell-card-body .nell-card-body-buttons a {
      margin: 7px;
      min-width: 150px;
      text-align: center; } }
