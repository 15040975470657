.aspect-1-1 {
  position: relative;
  overflow: hidden; }
  .aspect-1-1:before {
    content: '';
    display: block;
    padding-top: 100%; }

.aspect-3-2 {
  position: relative;
  overflow: hidden; }
  .aspect-3-2:before {
    content: '';
    display: block;
    padding-top: 66.66%; }

.aspect-2-1 {
  position: relative;
  overflow: hidden; }
  .aspect-2-1:before {
    content: '';
    display: block;
    padding-top: 50%; }

.aspect-16-9 {
  position: relative;
  overflow: hidden; }
  .aspect-16-9:before {
    content: '';
    display: block;
    padding-top: 56.25%; }

.aspect-3-1 {
  position: relative;
  overflow: hidden; }
  .aspect-3-1:before {
    content: '';
    display: block;
    padding-top: 33.33%; }

.aspect-2-3 {
  position: relative;
  overflow: hidden; }
  .aspect-2-3:before {
    content: '';
    display: block;
    padding-top: 66.66%; }

.aspect-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
