.subscription-class .custom-button {
  padding: 24px 0 0 14px; }

.price-tag {
  padding: 24px 0 0 14px; }
  .price-tag .icon {
    position: relative;
    top: 1px;
    margin-right: 6px; }
  .price-tag .price {
    color: #b8b6b4;
    font-weight: bold;
    display: block;
    font-size: 20px;
    line-height: 20px; }
  .price-tag .tag-info {
    color: #43413f;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.01px;
    display: block; }
    .price-tag .tag-info.dark {
      color: white; }
