.buy-joule {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 700px;
  max-height: none; }
  .buy-joule .product-text {
    position: relative;
    max-width: 320px;
    width: 100%;
    padding: 42px 0 0;
    margin-right: 15%;
    background: #ff674d;
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15); }
    .buy-joule .product-text .buy-joule-title {
      margin-bottom: 42px; }
    .buy-joule .product-text h2 {
      font-size: 30px;
      line-height: 1;
      text-align: center;
      margin-bottom: 7px;
      color: white; }
    .buy-joule .product-text p {
      margin-bottom: 28px;
      line-height: 24px; }
    .buy-joule .product-text span {
      font-family: 'Akkurat';
      font-size: 42px;
      line-height: 56px;
      font-weight: regular; }
    .buy-joule .product-text .offer {
      color: white; }
    .buy-joule .product-text .msrp {
      opacity: 0.5;
      position: relative;
      font-size: 28px; }
      .buy-joule .product-text .msrp:after {
        content: "";
        width: 110%;
        height: 2px;
        position: absolute;
        background-color: white;
        border-radius: 2px;
        transform: rotate(18deg);
        left: -3px;
        top: 50%; }
    .buy-joule .product-text .compare-price {
      opacity: 0.8;
      position: relative; }
      .buy-joule .product-text .compare-price:after {
        content: "";
        width: 110%;
        height: 2px;
        position: absolute;
        background-color: white;
        border-radius: 2px;
        left: 0;
        top: 50%; }
    .buy-joule .product-text h6 {
      font-size: 14px;
      font-weight: 400;
      font-style: italic;
      text-align: center;
      margin-top: 7px;
      margin-bottom: 14px; }
    .buy-joule .product-text .joule-price {
      max-width: 340px;
      background-color: white;
      padding: 35px 35px 42px;
      box-sizing: border-box;
      text-align: center; }
      .buy-joule .product-text .joule-price .button {
        width: 100%;
        max-width: 300px; }
      .buy-joule .product-text .joule-price p {
        color: #ff674d;
        margin-bottom: 14px;
        font-size: 16px;
        line-height: 21px;
        font-weight: bold; }

@media (max-width: 800px) {
  .buy-joule {
    justify-content: center;
    align-items: flex-end; }
    .buy-joule .product-text {
      margin: 28px; }
      .buy-joule .product-text .joule-price {
        padding: 28px 28px 42px; }
        .buy-joule .product-text .joule-price p {
          font-size: 14px; } }
