.cs-background-image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  width: 100%; }
  .cs-background-image-container .cs-background-image, .cs-background-image-container .cs-background-image-thumbnail, .cs-background-image-container .cs-background-image-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 100%; }
  .cs-background-image-container .cs-background-image > .greyscale-layer {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%; }
  .cs-background-image-container .cs-background-image-thumbnail {
    transition: opacity 300ms ease-in-out;
    opacity: 1; }
    .cs-background-image-container .cs-background-image-thumbnail.thumbnail-image-hidden {
      opacity: 0; }
