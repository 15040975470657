.joule-app-guides-component {
  background-color: whitesmoke; }

.joule-app-guides-text.text-block {
  max-width: 700px;
  margin: auto;
  padding: 98px 28px 28px;
  text-align: center; }

.joule-app-guides-container {
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding-bottom: 42px; }

.joule-app-guides-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex; }

.joule-app-guides-control {
  flex: 0 1 50%;
  height: 100%;
  z-index: 1;
  user-select: none;
  position: relative; }
  .joule-app-guides-control .button-circle-large {
    transform: scale3d(0.8, 0.8, 1);
    position: absolute;
    top: 240px;
    opacity: 1;
    transition: opacity 300ms ease, transform 300ms ease; }
    .joule-app-guides-control .button-circle-large:hover {
      transform: scale3d(1, 1, 1); }
  .joule-app-guides-control i.fa, .joule-app-guides-control span.fa {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center; }
  .joule-app-guides-control .hide-controls, .joule-app-guides-control .hide-controls:hover {
    opacity: 0 !important; }
  .joule-app-guides-control:hover {
    cursor: pointer; }

.joule-app-guides-control-left {
  background: linear-gradient(right, rgba(245, 245, 245, 0) 70%, whitesmoke); }
  .joule-app-guides-control-left .button-circle-large {
    right: 175px; }

.joule-app-guides-control-right {
  background: linear-gradient(left, rgba(245, 245, 245, 0) 70%, whitesmoke); }
  .joule-app-guides-control-right .button-circle-large {
    left: 175px; }

.joule-app-guides {
  flex: 0 0 350px;
  width: 350px;
  display: flex;
  align-items: flex-start;
  transition: transform 0.5s cubic-bezier(0.3, 0.01, 0.4, 1);
  transform: translateX(0px); }

.joule-app-guide {
  flex: 0 0 350px;
  width: 350px;
  padding: 28px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  transition: opacity 400ms ease; }
  .joule-app-guide.active {
    opacity: 1; }
  .joule-app-guide .joule-app-guide-image {
    border-radius: 7px;
    box-shadow: 0 0 14px #b8b6b4;
    overflow: hidden; }
  .joule-app-guide .joule-app-guide-number {
    border-radius: 35px;
    border: 1px solid #43413f;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 28px 28px 14px; }
  .joule-app-guide .joule-app-guide-text {
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 28px; }

@media (max-width: 800px) {
  .joule-app-guides-text.text-block {
    padding: 70px 28px 42px; }
  .joule-app-guides-control-right, .joule-app-guides-control-left {
    background: transparent; } }

@media (max-width: 500px) {
  .joule-app-guides-text.text-block {
    padding: 56px 28px 28px; }
  .joule-app-guides-container {
    padding-bottom: 14px; }
  .joule-app-guides {
    flex: 0 0 220px;
    width: 220px; }
  .joule-app-guide {
    flex: 0 0 220px;
    width: 220px;
    padding: 14px; }
  .joule-app-guides-control .button-circle-large {
    top: 150px;
    min-width: 56px;
    width: 56px;
    height: 56px; }
  .joule-app-guides-control-left .button-circle-large {
    left: 7px;
    right: auto; }
  .joule-app-guides-control-right .button-circle-large {
    right: 7px;
    left: auto; } }
