.product-share {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
  overflow: visible; }
  .product-share h4, .product-share span.h4-block {
    margin-bottom: 14px; }
  .product-share .social-buttons {
    display: flex;
    justify-content: center; }
    .product-share .social-buttons .button-circle-container {
      padding: 0 7px; }
    .product-share .social-buttons .button-circle-comments {
      display: none; }

@media (max-width: 960px) {
  .product-share {
    padding: 42px 28px !important; } }

@media (max-width: 600px) {
  .product-share h2 {
    font-size: 28px;
    line-height: 35px; } }
