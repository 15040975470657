media {
  display: block; }

.media-video-container {
  position: relative;
  margin-bottom: 28px;
  position: relative;
  overflow: hidden; }
  .media-video-container:before {
    content: '';
    display: block;
    padding-top: 56.25%; }
  .media-video-container .media-button-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .media-video-container cs-embed-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.component-media .cs-image {
  margin-bottom: 28px; }

.media-bottom {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box; }
  .media-bottom .price-tag {
    padding: 0; }
  .media-bottom .media-description {
    font-style: italic;
    font-weight: 400;
    margin-right: 28px; }

.component-full .media-bottom {
  padding: 0 28px; }

@media (max-width: 1166px) {
  .component-standard .media-bottom {
    padding: 0 0 28px; } }

@media (max-width: 756px) {
  .component-small .media-bottom {
    padding: 0 28px; } }

@media (max-width: 500px) {
  .media-bottom {
    flex-direction: column;
    justify-content: flex-start; }
    .media-bottom h6, .media-bottom span.h6-block {
      margin-bottom: 14px; } }
