.fetch-activity-shortcode {
  display: flex;
  width: 100%; }

p.has-recipe-card:first-child .fetch-activity-top-hr,
p.has-recipe-card + p.has-recipe-card .fetch-activity-top-hr,
div.paragraph-content.has-recipe-card:first-child .fetch-activity-top-hr,
div.paragraph-content.has-recipe-card + div.paragraph-content.has-recipe-card .fetch-activity-top-hr {
  display: none; }

.fetch-activity-shortcode-image {
  width: 280px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  overflow: hidden; }
  .fetch-activity-shortcode-image:before {
    content: '';
    display: block;
    padding-top: 66.66%; }
  .fetch-activity-shortcode-image .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.fetch-activity-shortcode-text {
  padding-left: 42px;
  max-width: calc(60% - 42px);
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center; }
  .fetch-activity-shortcode-text h4, .fetch-activity-shortcode-text span.h4-block {
    margin-bottom: 0;
    font-style: normal; }
  .fetch-activity-shortcode-text p.fetch-activity-shortcode-timing {
    font-style: italic;
    margin-bottom: 0; }
  .fetch-activity-shortcode-text .button {
    text-decoration: none;
    display: inline-block;
    width: 200px;
    margin-top: 14px; }

.component.step-recipe-card-only {
  padding-bottom: 0; }
  .component.step-recipe-card-only .step-contents-container {
    margin-bottom: 0; }
  .component.step-recipe-card-only .step-directions {
    margin-bottom: 0; }
    .component.step-recipe-card-only .step-directions.cs-short-step > p:first-child, .component.step-recipe-card-only .step-directions.cs-short-step > div.paragraph-content:first-child {
      margin-bottom: 0; }

.aside.component.step-recipe-card-only {
  padding-bottom: 42px; }
  .aside.component.step-recipe-card-only .step-contents-container {
    margin-bottom: 28px; }

.component.step-recipe-card-only + .component.step-recipe-card-only cs-fetch-activity-shortcode hr:first-of-type {
  display: none; }

@media (max-width: 960px) {
  .aside.component.step-recipe-card-only {
    padding-bottom: 28px; } }

@media (max-width: 550px) {
  .fetch-activity-shortcode {
    flex-direction: column;
    align-items: center; }
  .fetch-activity-shortcode-image {
    width: 100%;
    position: relative;
    overflow: hidden; }
    .fetch-activity-shortcode-image:before {
      content: '';
      display: block;
      padding-top: 56.25%; }
  .fetch-activity-shortcode-text {
    max-width: 100%;
    text-align: center;
    align-items: center;
    padding: 28px 0 14px; } }
