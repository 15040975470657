.claim-subscription-gift .gift-message-container {
  text-align: center; }

.claim-subscription-gift .button .loading-spinner {
  margin-left: 14px;
  display: inline; }

.claim-subscription-gift .claimed-gifts {
  margin-top: 14px; }
  .claim-subscription-gift .claimed-gifts .text-block {
    max-width: 600px;
    margin: 0 auto; }
    .claim-subscription-gift .claimed-gifts .text-block p {
      margin-bottom: 0; }
  .claim-subscription-gift .claimed-gifts .gift-message {
    border: 1px solid #b8b6b4;
    border-radius: 4px;
    padding: 14px;
    margin: 14px 0;
    text-align: center; }
    .claim-subscription-gift .claimed-gifts .gift-message .time {
      font-style: italic;
      font-size: 14px;
      display: block;
      margin-top: 14px; }
    .claim-subscription-gift .claimed-gifts .gift-message h5, .claim-subscription-gift .claimed-gifts .gift-message span.h5-block {
      margin-bottom: 7px; }
    .claim-subscription-gift .claimed-gifts .gift-message h5,
    .claim-subscription-gift .claimed-gifts .gift-message span.h5-block,
    .claim-subscription-gift .claimed-gifts .gift-message p {
      word-wrap: break-word; }
