.prompt-item {
  display: inline-block; }

.text-orange {
  color: #ff674d !important; }

.icon-block {
  text-align: center;
  width: 196px;
  height: 196px;
  margin-bottom: 14px; }
  .icon-block svg {
    height: 70px;
    margin-top: 42px;
    margin-bottom: 28px; }
