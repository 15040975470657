.cs-image-hover {
  overflow: hidden; }
  .cs-image-hover .cs-image-hover-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
    border-radius: 4px; }
    .cs-image-hover .cs-image-hover-content cs-image {
      background-color: #43413f; }
      .cs-image-hover .cs-image-hover-content cs-image img {
        filter: brightness(1);
        transform: scale3d(1, 1, 1);
        transition: all 500ms ease;
        backface-visibility: hidden; }
    .cs-image-hover .cs-image-hover-content .cs-image-hover-hide {
      transition: all 500ms ease;
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .cs-image-hover .cs-image-hover-content .cs-image-hover-show {
      transition: all 500ms ease;
      transition-delay: 100ms;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .cs-image-hover.cs-on-hover-active .cs-image-hover-content cs-image img {
    cursor: pointer;
    transform: scale3d(1.06, 1.06, 1.06);
    filter: brightness(0.4); }
  .cs-image-hover.cs-on-hover-active .cs-image-hover-content .cs-image-hover-hide {
    opacity: 0; }
  .cs-image-hover.cs-on-hover-active .cs-image-hover-content .cs-image-hover-show {
    opacity: 1; }

.cs-image-hover-icons {
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, rgba(0, 0, 0, 0.2)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#33000000',GradientType=0 );
  /* IE6-9 */ }
  .cs-image-hover-icons .button-circle-play {
    position: absolute;
    bottom: 14px;
    right: 14px; }

.cs-image-hover-info {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; }
  .cs-image-hover-info .info-counters {
    display: flex;
    font-size: 28px;
    line-height: 28px;
    font-weight: normal;
    margin-bottom: 7px; }
    .cs-image-hover-info .info-counters i, .cs-image-hover-info .info-counters span[cs-icon] {
      margin-right: 7px;
      margin-top: 2px; }
  .cs-image-hover-info .info-more {
    display: flex;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    padding: 0 14px; }
