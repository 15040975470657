.ingredients-scaler-title button {
  min-width: 200px; }

.ingredients-scaler-wrapper {
  justify-content: space-between; }

.ingredients-scaler-item {
  text-align: left;
  padding: 0px 28px; }
  .ingredients-scaler-item p {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 7px;
    text-transform: uppercase; }
  .ingredients-scaler-item button {
    font-family: Akkurat;
    padding: 7px;
    min-width: 77px; }
    .ingredients-scaler-item button.button.solid:hover {
      opacity: 1; }
  .ingredients-scaler-item .unit-metric {
    padding-top: 4px;
    vertical-align: top; }

.repeat-button {
  margin-right: 7px; }
  .repeat-button:last-of-type {
    margin-right: 0; }

@media (max-width: 641px) {
  .ingredients-scaler-options {
    padding: 0 28px; }
  .ingredients-scaler-wrapper {
    flex-direction: column-reverse;
    align-items: center; }
  .ingredients-scaler-item {
    text-align: center;
    padding: 0; }
    .ingredients-scaler-item p {
      font-size: 14px; }
    .ingredients-scaler-item button {
      min-width: 59.5px;
      font-size: 15px; }
    .ingredients-scaler-item:first-of-type {
      margin-top: 21px; } }
