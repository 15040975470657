.joule-press-quotes {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  transform: translate(-50%, 0);
  background-color: #ff674d;
  padding: 42px 42px 56px;
  box-sizing: border-box;
  border-radius: 6px;
  z-index: 2; }

.press-scroll-prompt {
  display: none; }

.press-wrapper {
  display: flex;
  justify-content: center; }

.press-quote {
  padding: 28px;
  text-align: center;
  flex: 1 0 25%;
  box-sizing: border-box;
  max-width: 280px;
  border-right: 1px solid rgba(255, 255, 255, 0.08);
  color: white; }
  .press-quote img {
    height: 35px;
    width: auto;
    opacity: 0.5; }
  .press-quote p {
    font-weight: bold;
    line-height: 1.15;
    margin: 28px 0 0; }

.nyt p {
  font-size: 32px; }

.wired p {
  font-size: 21px; }

.sweethome p {
  font-size: 32px; }

.foodwine {
  border: none; }
  .foodwine p {
    font-size: 19px; }

@media (max-width: 1200px) {
  .joule-press-quotes {
    padding: 28px; } }

@media (max-width: 1150px) {
  .joule-press-quotes {
    padding: 56px 28px; }
  .press-wrapper {
    flex-wrap: wrap; }
  .press-quote {
    flex: 1 1 50%;
    max-width: 350px; }
  .wired {
    border: none; }
  .sweethome {
    order: 4;
    border: none; }
  .foodwine {
    order: 3;
    border-right: 1px solid rgba(255, 255, 255, 0.08); } }

@media (max-width: 670px) {
  .joule-press-quotes {
    padding: 28px 0 70px;
    width: 90vw; }
  .press-scroll-prompt {
    display: flex;
    align-items: center;
    position: absolute;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: 14px;
    width: 170px;
    bottom: 21px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.5; }
    .press-scroll-prompt svg .a {
      stroke: white;
      stroke-width: 2px; }
  .press-wrapper {
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    padding: 0 5vw; }
    .press-wrapper::-webkit-scrollbar {
      display: none; }
    .press-wrapper::-moz-scrollbar {
      display: none; }
  .press-quote {
    flex: 1 0 80vw;
    max-width: 350px;
    min-width: 230px;
    border-right: none;
    padding: 14px; }
    .press-quote:last-child {
      flex: 1 0 85vw;
      padding-right: 10vw; }
  .nyt p {
    font-size: 28px; }
  .wired p {
    font-size: 19px; }
  .sweethome {
    order: 3; }
    .sweethome p {
      font-size: 28px; }
  .foodwine {
    order: 4; }
    .foodwine p {
      font-size: 18px; } }
