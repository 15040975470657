.media-multi .hero-high-z {
  z-index: 1; }

.media-multi .hero {
  height: 625px;
  min-height: auto; }
  .media-multi .hero:after {
    background: rgba(0, 0, 0, 0.5); }
  .media-multi .hero .hero-video-landscape .cs-embed-video-wrapper {
    height: 500px;
    width: 888.88889px; }

@media (min-width: 1110px) {
  .component-standard .media-multi .hero .cs-embed-video-wrapper {
    height: 625px;
    width: 1111.11111px; } }

.media-multi-bottom {
  display: flex;
  justify-content: center; }

.media-multi-item-wrapper {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 28px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; }
  .media-multi-item-wrapper::-webkit-scrollbar {
    display: none; }

.media-multi-item {
  width: 180px;
  flex: 0 0 180px;
  padding: 7px; }
  .media-multi-item:hover {
    cursor: pointer; }
  .media-multi-item.active .item-image {
    border: 4px solid #ff674d;
    border-radius: 4px; }
    .media-multi-item.active .item-image .cs-image-hover-content {
      border-radius: 0; }
  .media-multi-item .cs-image-hover-info .info-more {
    text-transform: none; }

@media (max-width: 550px) {
  .media-multi .hero {
    height: 400px;
    min-height: 400px; }
  .media-multi-item-wrapper {
    padding: 21px 14px; }
  .media-multi-item {
    width: 110px;
    flex: 0 0 110px;
    padding: 7px; }
    .media-multi-item .cs-image-hover-info .info-more {
      font-size: 12px;
      padding: 0 7px; } }
