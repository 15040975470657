.joule-app-main-hero::after {
  background: transparent; }

.joule-app-main-hero .hero-text {
  width: 50%;
  text-align: left;
  transform: translateX(50%); }

.joule-app-main-hero .hero-footer {
  z-index: 2;
  color: white;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 42px 28px;
  box-sizing: border-box; }
  .joule-app-main-hero .hero-footer img {
    max-width: 70px; }
  .joule-app-main-hero .hero-footer p {
    margin-bottom: 0; }

@media (max-width: 1000px) {
  .joule-app-main-hero .hero-text {
    width: 58%;
    transform: translateX(35%); } }

@media (max-width: 641px) {
  .joule-app-main-hero::after {
    background: rgba(0, 0, 0, 0.5); }
  .joule-app-main-hero .hero-text {
    width: 100%;
    transform: translateX(0%);
    text-align: center;
    max-width: 500px; } }
