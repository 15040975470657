.nell-backdrop {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity ease 400ms; }
  .nell-backdrop.ng-hide {
    opacity: 0; }
