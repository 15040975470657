.flex-container {
  display: flex;
  flex-wrap: wrap; }

.flex-full {
  flex: 1 100%; }

.flex-main {
  flex: 0 600px; }

.flex-aside {
  box-sizing: border-box;
  flex: 1 280px; }
  .flex-aside.flex-aside-left {
    padding-right: 10px; }

.flex-dropdown {
  position: relative; }
  .flex-dropdown .flex-dropdown-toggle {
    position: relative; }
  .flex-dropdown .flex-dropdown-menu {
    position: absolute;
    top: 60px;
    right: 0px;
    background: white;
    padding: 14px;
    border-radius: 2px;
    box-shadow: 1px 1px 5px #cccccc;
    white-space: nowrap;
    z-index: 1;
    box-sizing: border-box; }
    .flex-dropdown .flex-dropdown-menu ul {
      margin: 0px;
      list-style: none;
      text-align: left; }
      .flex-dropdown .flex-dropdown-menu ul li {
        margin: 10px 0px;
        color: rgba(51, 51, 51, 0.5);
        transition: all 300ms;
        display: flex;
        align-items: center; }
        .flex-dropdown .flex-dropdown-menu ul li .flex-dropdown-icon {
          margin-right: 10px;
          min-width: 27px; }
        .flex-dropdown .flex-dropdown-menu ul li:hover {
          color: #333333;
          cursor: pointer; }
      .flex-dropdown .flex-dropdown-menu ul .flex-medium-only {
        display: none; }
    .flex-dropdown .flex-dropdown-menu.open {
      display: block; }
    .flex-dropdown .flex-dropdown-menu.closed {
      display: none; }
    .flex-dropdown .flex-dropdown-menu:after {
      content: " ";
      position: absolute;
      top: -7px;
      right: 15px;
      width: 15px;
      height: 15px;
      background: white;
      transform: rotate(45deg);
      box-shadow: -1px -1px 1px #e8e8e8; }

.flex-large-only {
  display: block; }

.flex-medium-only {
  display: none; }

@media (max-width: 1240px) {
  .flex-container {
    justify-content: center; }
  .flex-full {
    flex: 0 1 auto; }
  .flex-main {
    flex: 0 1 auto;
    width: 600px;
    align-self: center; }
  .flex-aside {
    flex: 0 1 auto;
    width: 600px;
    align-self: center; }
    .flex-aside.flex-aside-left {
      padding-right: 0px; }
  .flex-large-only {
    display: none; }
  .flex-medium-only {
    display: block; }
  .flex-tool-abtest {
    width: 100%; }
  .flex-aside .flex-tools {
    justify-content: center; } }

@media (max-width: 640px) {
  .flex-item.flex-full {
    width: 100%; }
    .flex-item.flex-full .flex-title {
      width: 100%; }
  .flex-main {
    width: 100%; }
  .flex-aside {
    width: 100%; } }

.course-wrapper .flex-item {
  margin: 0 auto;
  flex: 0 700px; }

.course-wrapper .flex-item.flex-full .flex-title {
  max-width: 700px;
  margin-bottom: 14px; }

.flex-title {
  margin: 0 auto;
  width: 600px; }
  .flex-title .flex-title-item {
    margin-bottom: 14px; }

.flex-tools-container {
  display: flex;
  justify-content: flex-end; }

.flex-tools {
  display: inline-flex;
  flex-direction: column;
  width: 60px; }
  .flex-tools .flex-tool {
    align-self: center;
    text-align: center;
    color: rgba(51, 51, 51, 0.5);
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 12px;
    transition: all 300ms; }

.flex-aside-right .flex-tools {
  display: flex;
  flex-direction: row;
  width: 100%; }

.flex-social-title {
  margin-bottom: 14px;
  color: rgba(51, 51, 51, 0.5); }

.flex-social {
  width: 175px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box; }
  .flex-social .flex-social-item {
    color: rgba(51, 51, 51, 0.5);
    transition: all 300ms;
    display: flex; }
    .flex-social .flex-social-item svg {
      align-self: center; }
    .flex-social .flex-social-item .flex-dropdown-toggle {
      display: flex; }
      .flex-social .flex-social-item .flex-dropdown-toggle svg {
        align: self(center); }
    .flex-social .flex-social-item:hover {
      color: #333333;
      cursor: pointer; }
    .flex-social .flex-social-item .flex-dropdown-menu {
      color: rgba(51, 51, 51, 0.5);
      top: 45px;
      right: -10px; }
    .flex-social .flex-social-item.active {
      color: #333333; }
      .flex-social .flex-social-item.active .flex-button-circle {
        border-color: #333333; }

.flex-suggestions {
  padding: 14px; }

@media (max-width: 1240px) {
  .flex-tools-container {
    justify-content: flex-start; }
  .flex-tools {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-right: 0px; }
    .flex-tools .flex-tool {
      align-self: inherit;
      margin-bottom: 10px; } }
