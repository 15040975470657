.matrix-media {
  justify-content: left; }
  .matrix-media .matrix-item {
    display: block; }
    .matrix-media .matrix-item .item-image {
      width: 100%;
      margin-bottom: 20px;
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      box-shadow: 0px 0px 15px rgba(0, 0, 20, 0.08); }
      .matrix-media .matrix-item .item-image:before {
        content: '';
        display: block;
        padding-top: 66.66%; }
      .matrix-media .matrix-item .item-image .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .matrix-media .matrix-item .item-image cs-image {
        border-radius: 4px;
        -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
      .matrix-media .matrix-item .item-image img {
        width: 100%; }
    .matrix-media .matrix-item .item-text {
      color: #43413f;
      padding: 0 14px; }
      .matrix-media .matrix-item .item-text h4, .matrix-media .matrix-item .item-text span.h4-block {
        margin-bottom: 7px; }
        .matrix-media .matrix-item .item-text h4.truncate-2-lines, .matrix-media .matrix-item .item-text span.h4-block.truncate-2-lines {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 55px; }
      .matrix-media .matrix-item .item-text .item-card-label {
        display: none;
        font-size: 14px;
        text-transform: uppercase;
        opacity: 0.6; }
      .matrix-media .matrix-item .item-text p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 24px;
        opacity: 0.85; }
        .matrix-media .matrix-item .item-text p.truncate-4-lines {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical; }
        .matrix-media .matrix-item .item-text p.truncate-3-lines {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          height: 70px; }
      .matrix-media .matrix-item .item-text .button {
        margin-top: 28px; }

.component-full .matrix-media .matrix-item .item-image {
  position: relative;
  overflow: hidden; }
  .component-full .matrix-media .matrix-item .item-image:before {
    content: '';
    display: block;
    padding-top: 56.25%; }

.theme-dark .matrix-media .matrix-item .item-text, .theme-orange .matrix-media .matrix-item .item-text {
  color: white; }

@media (max-width: 960px) {
  .matrix-media .matrix-item .item-image {
    position: relative;
    overflow: hidden; }
    .matrix-media .matrix-item .item-image:before {
      content: '';
      display: block;
      padding-top: 50%; } }
