p .time-temp-guide {
  font-style: normal;
  text-align: left;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: 1080px; }

.time-temp-section {
  padding: 28px 42px;
  box-sizing: border-box; }
  .time-temp-section h3, .time-temp-section span.h3-block {
    margin-bottom: 0; }

.time-temp-left {
  text-align: left; }
  .time-temp-left p {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    background-color: #edecea;
    padding: 0 14px;
    margin: 42px 0 0;
    border-radius: 4px;
    box-sizing: border-box; }

.time-temp-left:first-of-type p {
  margin: 14px 0 0; }

.time-temp-right {
  flex: 1 0 auto; }

.time-temp-item {
  display: block;
  position: relative;
  margin: 28px 0; }

.time-temp-text-top {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 14px;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px; }

.time-temp-text-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  line-height: 14px; }

.time-temp-bar {
  position: relative;
  height: 40px; }
  .time-temp-bar:hover {
    cursor: pointer; }
    .time-temp-bar:hover .time-temp-axis {
      opacity: 1; }

.time-temp-line {
  position: absolute;
  border: 1px solid #edecea;
  left: 0;
  top: 50%;
  transform: translateY(-1px); }

.time-temp-bar-span {
  position: absolute;
  top: 0;
  height: 40px;
  border-radius: 60px;
  background-color: #80e0ce;
  padding-right: 40px;
  z-index: 2; }

.time-temp-circle {
  position: absolute;
  top: 0;
  height: 40px;
  width: 40px;
  color: white;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 12px; }
  .time-temp-circle.time-temp-recommended {
    border: 2px solid white;
    background-color: #1abc9c;
    z-index: 200; }
  .time-temp-circle.time-temp-high {
    transform: translate(1px, 1px);
    height: 38px;
    width: 38px;
    border: 1px solid white;
    z-index: 100; }

.time-temp-axis {
  opacity: 0;
  transition: 200ms opacity ease-in-out; }
  .time-temp-axis .time-temp-axis-line {
    position: absolute;
    border: 1px solid #edecea;
    left: 0;
    top: 50%;
    right: 0;
    transform: translateY(-1px); }
  .time-temp-axis .time-temp-axis-marker {
    position: absolute;
    font-size: 12px;
    line-height: 14px;
    top: 0;
    margin-left: -14px; }
    .time-temp-axis .time-temp-axis-marker:after {
      content: '';
      width: 50%;
      height: 100%;
      position: absolute;
      z-index: 1;
      border-right: 4px solid white; }

.time-temp-key {
  background-color: #edecea;
  padding: 42px 42px 56px;
  box-sizing: border-box;
  border-radius: 4px; }
  .time-temp-key .time-temp-axis {
    opacity: 1; }
    .time-temp-key .time-temp-axis .time-temp-axis-line {
      border: 1px solid #b8b6b4; }
    .time-temp-key .time-temp-axis .time-temp-axis-marker:after {
      border-right: 4px solid #edecea; }
  .time-temp-key .time-temp-line {
    border: 1px solid #b8b6b4; }
  .time-temp-key .time-temp-text-top,
  .time-temp-key .time-temp-text-bottom,
  .time-temp-key .time-temp-bar {
    opacity: 0.4; }

.time-temp-label {
  position: absolute;
  top: -50px; }
  .time-temp-label p {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0; }
  .time-temp-label::before {
    content: "";
    width: 2px;
    height: 10px;
    background-color: #43413f;
    position: absolute;
    left: 50%;
    bottom: -10px; }

.label-favorite {
  left: 45%;
  transform: translateX(-15px); }

.label-max {
  left: 60%;
  transform: translateX(-5px); }

.label-range {
  left: 40%;
  top: auto;
  bottom: -40px;
  width: calc(20% + 40px);
  text-align: center; }
  .label-range p {
    background-color: #edecea;
    z-index: 2;
    display: inline;
    position: relative;
    padding: 0 7px; }
  .label-range::before {
    bottom: 49%;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 1; }

.label-temp {
  top: auto;
  bottom: -50px; }
  .label-temp::before {
    bottom: auto;
    top: -10px;
    left: 15px; }

.time-temp-spacer {
  border-bottom: 2px solid #43413f; }

.time-temp-desc {
  display: flex;
  padding-top: 28px; }

.time-temp-desc-item {
  flex: 0 0 20%;
  padding-right: 28px;
  box-sizing: border-box; }
  .time-temp-desc-item h6, .time-temp-desc-item span.h6-block {
    width: 100%;
    border-bottom: 2px solid #43413f;
    margin-bottom: 7px; }
  .time-temp-desc-item p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0; }

@media (max-width: 900px) {
  .time-temp-desc {
    flex-wrap: wrap; }
  .time-temp-desc-item {
    flex: 0 0 33%;
    margin-bottom: 14px; } }

@media (max-width: 767px) {
  .time-temp-item {
    height: 60px;
    padding-bottom: 14px;
    margin: 14px 0;
    border-bottom: 1px solid #edecea; }
  .time-temp-spacer, .time-temp-key {
    display: none; }
  .time-temp-section {
    flex-direction: column;
    padding: 28px; }
  .time-temp-text-top,
  .time-temp-text-bottom {
    max-width: 60%;
    position: relative !important;
    line-height: 18px; }
  .time-temp-text-top {
    padding-top: 7px; }
  .time-temp-bar {
    height: 60px;
    position: absolute;
    top: 0;
    right: 0; }
  .time-temp-line {
    top: auto;
    bottom: 21px; }
  .time-temp-bar-span, .time-temp-circle {
    top: auto;
    bottom: 0; }
  .time-temp-bar-span, .time-temp-line, .time-temp-axis {
    display: none; }
  .time-temp-circle {
    width: 42px !important;
    height: 42px !important;
    border: none !important;
    left: auto !important; }
    .time-temp-circle::before {
      font-size: 12px;
      color: #b8b6b4;
      width: 100%;
      text-align: center;
      position: absolute;
      top: -21px;
      left: 0; }
    .time-temp-circle.time-temp-recommended {
      right: 50px; }
      .time-temp-circle.time-temp-recommended::before {
        content: 'We like'; }
    .time-temp-circle.time-temp-high {
      right: 0;
      transform: none;
      background-color: #80e0ce; }
      .time-temp-circle.time-temp-high::before {
        content: 'Max'; } }

@media (max-width: 650px) {
  .time-temp-desc-item {
    flex: 0 0 50%; } }

@media (max-width: 400px) {
  .time-temp-desc-item {
    flex: 0 0 100%;
    padding-right: 0; } }
