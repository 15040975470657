.page-manage {
  position: fixed;
  bottom: 14px;
  right: 14px;
  z-index: 9999;
  padding: 14px 0; }
  .page-manage .button {
    margin: 0 7px; }
    .page-manage .button i {
      margin-left: 3px; }

.page-actions {
  position: fixed;
  bottom: 0px;
  background-color: white;
  width: 100%;
  z-index: 9999;
  padding: 14px 0;
  border-top: 1px solid #edecea;
  display: flex;
  justify-content: center;
  align-items: center; }
  .page-actions .button {
    margin: 0 7px; }

@media (max-width: 800px) {
  .page-manage {
    display: none; } }
