.button {
  display: inline-block;
  border: 2px solid #43413f;
  color: #43413f;
  background-color: transparent;
  border-radius: 42px;
  box-sizing: border-box;
  font-size: 18px;
  min-height: 42px;
  line-height: 28px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  padding: 4px 28px 5px;
  transition-property: background-color, border, color, opacity;
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
  user-select: none; }
  .button i, .button span {
    line-height: 1; }
  .button:not(.static):hover, .button:not(.static):active, .button.active, .button:focus {
    cursor: pointer;
    background-color: #43413f;
    color: white; }
  .button:disabled, .button:disabled:hover {
    opacity: 0.6 !important; }

.button.off {
  color: #b8b6b4;
  border-color: #b8b6b4; }

.button.outline {
  border: 2px solid #43413f;
  background-color: transparent;
  color: #43413f;
  text-decoration: none; }
  .button.outline:not(.static):hover, .button.outline:not(.static):active {
    background-color: #43413f;
    color: white; }
  .button.outline.light {
    border-color: white;
    color: white; }
    .button.outline.light:not(.static):hover, .button.outline.light:not(.static):active {
      background-color: white;
      color: #43413f; }
  .button.outline.orange {
    border-color: #ff674d;
    color: #ff674d; }
    .button.outline.orange:hover, .button.outline.orange:active, .button.outline.orange.active {
      background-color: #ff674d;
      color: white; }
  .button.outline.red {
    border-color: #d44950;
    color: #d44950; }
    .button.outline.red:hover, .button.outline.red:active, .button.outline.red.active {
      background-color: #d44950;
      color: white; }

.button.solid {
  border: 2px solid #43413f;
  background-color: #43413f;
  color: white;
  text-decoration: none; }
  .button.solid:hover, .button.solid:active, .button.solid.active {
    opacity: 0.8; }
  .button.solid.light {
    border-color: white;
    background-color: white;
    color: #43413f; }
  .button.solid.orange {
    border-color: #ff674d;
    background-color: #ff674d;
    color: white; }
  .button.solid.red {
    border-color: #d44950;
    background-color: #d44950;
    color: white; }
  .button.solid.text-orange {
    color: #ff674d; }

.button-group-inline {
  display: flex;
  align-items: center; }
  .button-group-inline button, .button-group-inline div, .button-group-inline span {
    margin-left: 7px;
    margin-right: 7px; }

.button-underline,
.button.button-underline {
  text-decoration: underline;
  font-weight: 500;
  font-style: normal;
  line-height: 42px;
  height: auto;
  padding: 0;
  border: none;
  color: #43413f; }
  .button-underline:hover, .button-underline:active, .button-underline.active,
  .button.button-underline:hover,
  .button.button-underline:active,
  .button.button-underline.active {
    cursor: pointer;
    background-color: transparent;
    color: #43413f; }

.theme-light .button.button-underline,
.theme-gray .button.button-underline {
  color: #43413f; }
  .theme-light .button.button-underline:hover, .theme-light .button.button-underline:active, .theme-light .button.button-underline.active,
  .theme-gray .button.button-underline:hover,
  .theme-gray .button.button-underline:active,
  .theme-gray .button.button-underline.active {
    color: #43413f; }

.theme-dark .button.button-underline,
.theme-orange .button.button-underline {
  color: white; }
  .theme-dark .button.button-underline:hover, .theme-dark .button.button-underline:active, .theme-dark .button.button-underline.active,
  .theme-orange .button.button-underline:hover,
  .theme-orange .button.button-underline:active,
  .theme-orange .button.button-underline.active {
    color: white; }

.button.small {
  min-height: 35px;
  line-height: 14px;
  padding: 8px 14px 6px; }

.button.button-circle {
  min-width: 42px;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center; }

.button.button-circle-large {
  min-width: 63px;
  width: 63px;
  height: 63px;
  border-radius: 31.5px;
  padding-left: 0px;
  padding-right: 0px;
  transform: scale3d(1, 1, 1);
  transition: transform ease 300ms;
  -webkit-tap-highlight-color: transparent; }
  .button.button-circle-large:hover, .button.button-circle-large:active, .button.button-circle-large.active {
    transform: scale3d(1.1, 1.1, 1.1);
    opacity: 1; }
  .button.button-circle-large:focus {
    outline: 0; }

.button-circle-play {
  width: 28px;
  height: 28px;
  min-height: none;
  border-radius: 14px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #43413f;
  background-color: white;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent; }
  .button-circle-play i, .button-circle-play span {
    padding-left: 3px;
    margin: auto; }

.button-circle-play-large {
  width: 63px;
  height: 63px;
  border-radius: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #43413f;
  background-color: white;
  border: none;
  cursor: pointer;
  transform: scale3d(1, 1, 1);
  transition: transform ease 300ms;
  -webkit-tap-highlight-color: transparent; }
  .button-circle-play-large i, .button-circle-play-large span {
    margin: auto;
    padding-left: 5px; }
  .button-circle-play-large .cs-icon-play-dims {
    width: 25px;
    height: 25px; }
  .button-circle-play-large:hover, .button-circle-play-large:active, .button-circle-play-large.active {
    transform: scale3d(1.1, 1.1, 1.1); }

.button-circle.button-circle-gray,
.button.button-circle-facebook,
.button.button-circle-twitter,
.button.button-circle-chefsteps {
  padding: 8px;
  margin: 0 auto;
  border: 2px solid #7a7876;
  background-color: #7a7876;
  color: white;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }
  .button-circle.button-circle-gray:hover, .button-circle.button-circle-gray:active, .button-circle.button-circle-gray.active,
  .button.button-circle-facebook:hover,
  .button.button-circle-facebook:active,
  .button.button-circle-facebook.active,
  .button.button-circle-twitter:hover,
  .button.button-circle-twitter:active,
  .button.button-circle-twitter.active,
  .button.button-circle-chefsteps:hover,
  .button.button-circle-chefsteps:active,
  .button.button-circle-chefsteps.active {
    opacity: 0.8; }
  .button-circle.button-circle-gray.outline,
  .button.button-circle-facebook.outline,
  .button.button-circle-twitter.outline,
  .button.button-circle-chefsteps.outline {
    background-color: transparent;
    border: 2px solid #7a7876;
    color: #7a7876; }
    .button-circle.button-circle-gray.outline:hover, .button-circle.button-circle-gray.outline:active, .button-circle.button-circle-gray.outline.active,
    .button.button-circle-facebook.outline:hover,
    .button.button-circle-facebook.outline:active,
    .button.button-circle-facebook.outline.active,
    .button.button-circle-twitter.outline:hover,
    .button.button-circle-twitter.outline:active,
    .button.button-circle-twitter.outline.active,
    .button.button-circle-chefsteps.outline:hover,
    .button.button-circle-chefsteps.outline:active,
    .button.button-circle-chefsteps.outline.active {
      border-color: #7a7876;
      background-color: #7a7876;
      color: white;
      opacity: 1; }

.button.floating-action-button {
  position: fixed;
  bottom: 28px;
  right: 28px;
  width: 70px;
  height: 70px;
  padding: 0;
  border-radius: 35px;
  z-index: 400;
  opacity: 1;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  transform: scale3d(1, 1, 1);
  transition: transform ease 300ms;
  -webkit-tap-highlight-color: transparent; }
  .button.floating-action-button:hover, .button.floating-action-button:active, .button.floating-action-button.active {
    transform: scale3d(1.1, 1.1, 1.1);
    opacity: 1 !important; }
  .button.floating-action-button:focus {
    outline: 0; }

.button.button-circle-facebook {
  background-color: #3b5998;
  border: #3b5998; }
  .button.button-circle-facebook:hover, .button.button-circle-facebook:active, .button.button-circle-facebook.active {
    background-color: #3b5998;
    border-color: #3b5998; }
  .button.button-circle-facebook.outline {
    color: #3b5998;
    border-color: #3b5998;
    background-color: transparent; }
    .button.button-circle-facebook.outline:hover, .button.button-circle-facebook.outline:active {
      background-color: #3b5998;
      border-color: #3b5998;
      color: white; }

.button.button-circle-twitter {
  background-color: #1daced;
  border-color: #1daced; }
  .button.button-circle-twitter:hover, .button.button-circle-twitter:active, .button.button-circle-twitter.active {
    background-color: #1daced;
    border-color: #1daced; }
  .button.button-circle-twitter.outline {
    color: #1daced;
    border-color: #1daced;
    background-color: transparent; }
    .button.button-circle-twitter.outline:hover, .button.button-circle-twitter.outline:active {
      background-color: #1daced;
      border-color: #1daced;
      color: white; }

.button.button-circle-chefsteps {
  background-color: #ff674d;
  border-color: #ff674d; }
  .button.button-circle-chefsteps:hover, .button.button-circle-chefsteps:active {
    background-color: #ff674d;
    border-color: #ff674d; }
  .button.button-circle-chefsteps.outline {
    color: #ff674d;
    border-color: #ff674d;
    background-color: transparent; }
    .button.button-circle-chefsteps.outline:hover, .button.button-circle-chefsteps.outline:active, .button.button-circle-chefsteps.outline.active {
      background-color: #ff674d;
      border-color: #ff674d;
      color: white; }

.button-circle-container {
  text-align: center;
  align-self: center;
  padding: 7px; }
  .button-circle-container .button-circle-text {
    font-weight: normal;
    font-size: 16px;
    margin-top: 7px;
    color: #7a7876; }

.button-group {
  display: flex; }
  .button-group .button {
    border-radius: 0px;
    border-right-width: 0px; }
  .button-group .button:first-of-type {
    border-radius: 5px 0 0 5px; }
  .button-group .button:last-of-type {
    border-radius: 0 5px 5px 0;
    border-right-width: 1px; }

.button.button-tab {
  border-radius: 14px 14px 0 0;
  border-bottom: 0;
  margin-right: 2px; }

@media (max-width: 640px) {
  .button {
    font-size: 16px; }
  .button.floating-action-button {
    bottom: 21px;
    right: 21px; } }

jobs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; }
  jobs .jobs {
    margin-bottom: 14px;
    word-wrap: break-word;
    width: 100%;
    box-sizing: border-box; }
    jobs .jobs .job {
      margin-bottom: 84px; }
  jobs custom-button {
    display: flex;
    justify-content: center;
    align-items: center; }

.ta-hidden-input {
  width: 1px;
  height: 1px;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -10000px;
  left: -10000px;
  opacity: 0;
  overflow: hidden; }

text-angular {
  width: 100%; }
  text-angular .ta-toolbar {
    display: flex; }
    text-angular .ta-toolbar .button-group {
      margin-bottom: 14px;
      margin-right: 14px; }
    text-angular .ta-toolbar .button {
      height: 35px;
      line-height: 14px;
      padding: 9px 14px 6px; }
      text-angular .ta-toolbar .button.active {
        background: #43413f;
        color: white; }
  text-angular .ta-editor {
    background: white;
    border: 1px solid white;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box; }
    text-angular .ta-editor .ta-bind {
      outline: none;
      padding: 14px;
      min-height: 112px; }
      text-angular .ta-editor .ta-bind::before {
        color: #b8b6b4;
        font-style: italic; }

.button-group {
  display: flex; }
  .button-group .button {
    border-radius: 0px;
    border-right-width: 0px; }
  .button-group .button:first-of-type {
    border-radius: 4px 0 0 4px; }
  .button-group .button:last-of-type {
    border-radius: 0 4px 4px 0;
    border-right-width: 1px; }
  .button-group .button:only-child {
    border-radius: 4px; }

.component-full .jobs {
  padding: 0 42px; }

.component-small jobs {
  max-width: 700px; }

@media (max-width: 1194px) {
  .component-standard {
    padding-left: 0;
    padding-right: 0; }
  .component-standard .jobs {
    padding: 0 42px; } }

@media (max-width: 756px) {
  .component-full,
  .component-standard,
  .component-small {
    padding-left: 0;
    padding-right: 0; }
  .component-full .jobs,
  .component-standard .jobs,
  .component-small .jobs {
    padding: 0 28px; } }
