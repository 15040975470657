.banner .banner-text.banner-joule-Nav {
  padding: 154px 28px 112px;
  max-width: 400px;
  margin: auto; }

.theme-dark .banner-text.banner-joule-Nav .button.outline,
.theme-orange .banner-text.banner-joule-Nav .button.outline {
  color: white;
  border-color: white; }
  .theme-dark .banner-text.banner-joule-Nav .button.outline:hover,
  .theme-orange .banner-text.banner-joule-Nav .button.outline:hover {
    background-color: white;
    color: #43413f; }
