.joule-magnet {
  background-color: #e7afa4; }
  .joule-magnet::after {
    display: none; }
  .joule-magnet .hero-text {
    max-width: 500px; }
  .joule-magnet .joule-horizontal {
    position: absolute;
    left: 0;
    top: 100px;
    width: 450px; }
  .joule-magnet .joule-vertical {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 750px; }
  .joule-magnet .joule-magnet-mobile {
    display: none; }
  .joule-magnet .text-icon svg {
    height: 85px;
    width: auto;
    margin-bottom: 7px; }
    .joule-magnet .text-icon svg .a, .joule-magnet .text-icon svg .b {
      stroke: white;
      stroke-width: 2px; }

@media (max-width: 1400px) {
  .joule-magnet .joule-horizontal {
    width: 400px; }
  .joule-magnet .joule-vertical {
    width: 650px; } }

@media (max-width: 1200px) {
  .joule-magnet {
    min-height: 70vh; }
    .joule-magnet .joule-horizontal {
      width: 300px; }
    .joule-magnet .joule-vertical {
      width: 550px; } }

@media (max-width: 1000px) {
  .joule-magnet .joule-horizontal {
    width: 300px;
    top: 30px; }
  .joule-magnet .joule-vertical {
    width: 550px;
    top: -150px; } }

@media (max-width: 800px) {
  .joule-magnet {
    flex-direction: column; }
    .joule-magnet .hero-background {
      position: relative; }
    .joule-magnet .joule-magnet-mobile {
      display: block;
      position: relative;
      width: 100%; }
    .joule-magnet .hero-text {
      padding-top: 0px;
      padding-bottom: 70px; }
    .joule-magnet .joule-horizontal, .joule-magnet .joule-vertical {
      display: none; } }
