.modal-ftue {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  top: auto;
  position: relative;
  transform: none; }

.ftue-container {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%; }
  .ftue-container .modal-top {
    padding-top: 84px;
    padding-bottom: 56px; }
    .ftue-container .modal-top h3, .ftue-container .modal-top span.h3-block {
      margin-bottom: 7px; }
    .ftue-container .modal-top h5, .ftue-container .modal-top span.h5-block {
      margin-bottom: 0; }
  .ftue-container .modal-body {
    max-width: 1110px;
    margin: auto;
    padding: 0 28px 112px; }
  .ftue-container .modal-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 21px;
    background-color: white;
    border-top: 1px solid #edecea; }
    .ftue-container .modal-bottom .modal-bottom-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1110px; }
      .ftue-container .modal-bottom .modal-bottom-wrapper .button {
        min-width: 200px; }
      .ftue-container .modal-bottom .modal-bottom-wrapper.modal-ftue-recommendations {
        justify-content: center; }

.matrix.ftue-interests .matrix-item {
  position: relative;
  transition: all 500ms ease-in-out;
  border-radius: 4px;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  overflow: hidden;
  -webkit-tap-highlight-color: transparent; }
  .matrix.ftue-interests .matrix-item .item-image {
    width: 100%;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
    transition: all 500ms ease-in-out;
    filter: brightness(0.6);
    transform: scale3d(1, 1, 1); }
    .matrix.ftue-interests .matrix-item .item-image:before {
      content: '';
      display: block;
      padding-top: 100%; }
    .matrix.ftue-interests .matrix-item .item-image cs-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      .matrix.ftue-interests .matrix-item .item-image cs-image .cs-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
  .matrix.ftue-interests .matrix-item .item-text {
    margin: 0px;
    padding: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    transition: all 500ms ease-in-out;
    transform: translate3d(0, 0, 0);
    z-index: 1; }
    .matrix.ftue-interests .matrix-item .item-text:after {
      content: "";
      position: absolute;
      width: 200%;
      height: 200%;
      border-radius: 150%;
      top: -61%;
      left: -50%;
      background-color: #ff674d;
      transform: scale3d(0, 0, 0);
      transform-origin: center center;
      opacity: 0;
      transition: transform ease 400ms, opacity ease 200ms 200ms; }
    .matrix.ftue-interests .matrix-item .item-text .item-radio {
      width: 21px;
      height: 21px;
      border: 2px solid white;
      border-radius: 14px;
      margin-bottom: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      transform: scale3d(1, 1, 1);
      transform-origin: center center;
      transition: transform cubic-bezier(0.39, 0.2, 0.57, 1.8) 500ms; }
      .matrix.ftue-interests .matrix-item .item-text .item-radio:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: white;
        top: 0;
        left: 0;
        transform: scale3d(0, 0, 0);
        transform-origin: center center;
        opacity: 0;
        transition: transform ease 300ms, opacity ease 400ms; }
      .matrix.ftue-interests .matrix-item .item-text .item-radio .fa.fa-check {
        color: #ff674d;
        font-size: 14px;
        transform: scale3d(0, 0, 0);
        transform-origin: center center;
        opacity: 0;
        transition: transform cubic-bezier(0.69, 0.2, 0.57, 1.8) 400ms, opacity ease 400ms; }
    .matrix.ftue-interests .matrix-item .item-text h4, .matrix.ftue-interests .matrix-item .item-text span.h4-block {
      font-size: 20px;
      line-height: 28px;
      max-width: 160px;
      color: white;
      transform: translate3d(0, 0, 0);
      transition: transform 500ms ease;
      z-index: 2;
      margin-bottom: 0;
      text-decoration: none; }
  .matrix.ftue-interests .matrix-item:hover {
    cursor: pointer; }
    .matrix.ftue-interests .matrix-item:hover .item-image {
      filter: brightness(0.4);
      transform: scale3d(1.2, 1.2, 1.2); }
    .matrix.ftue-interests .matrix-item:hover .item-text .item-radio {
      transform: scale3d(1.2, 1.2, 1.2); }
    .matrix.ftue-interests .matrix-item:hover .item-text h4, .matrix.ftue-interests .matrix-item:hover .item-text span.h4-block {
      transform: translate3d(0, 4px, 0); }
  .matrix.ftue-interests .matrix-item.checked .item-image {
    transform: scale3d(1.2, 1.2, 1.2); }
  .matrix.ftue-interests .matrix-item.checked .item-text:after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
    transition: transform ease 400ms; }
  .matrix.ftue-interests .matrix-item.checked .item-text .item-radio {
    transform: scale3d(1, 1, 1); }
    .matrix.ftue-interests .matrix-item.checked .item-text .item-radio:before {
      transform: scale3d(1.1, 1.1, 1.1);
      opacity: 1; }
    .matrix.ftue-interests .matrix-item.checked .item-text .item-radio .fa.fa-check {
      transform: scale3d(1, 1, 1);
      opacity: 1; }
  .matrix.ftue-interests .matrix-item.checked .item-text h4, .matrix.ftue-interests .matrix-item.checked .item-text span.h4-block {
    transform: translate3d(0, 0, 0); }

.matrix.ftue-interests .matrix-item.ftue-interest-suggestion {
  background-color: #edecea;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color ease 400ms, color ease 400ms; }
  .matrix.ftue-interests .matrix-item.ftue-interest-suggestion input.input {
    border-bottom: 1px;
    border-style: dashed;
    border-color: #43413f;
    border-top: 0px;
    border-left: 0px;
    border-right: 10px;
    margin: 0px 14px;
    text-align: center;
    width: 100%;
    max-width: 180px;
    font-weight: 500; }
  .matrix.ftue-interests .matrix-item.ftue-interest-suggestion.form-filled {
    background-color: #ff674d; }
    .matrix.ftue-interests .matrix-item.ftue-interest-suggestion.form-filled input.input {
      color: white;
      border-color: white; }

@media (max-width: 1166px) {
  .ftue-container .modal-top {
    padding: 84px 28px 56px;
    box-sizing: border-box; }
  .ftue-container .modal-body {
    padding: 0 0 112px; } }

@media (max-width: 640px) {
  .ftue-container .modal-top {
    padding: 70px 28px 42px;
    box-sizing: border-box; }
  .ftue-container .modal-body .matrix.ftue-interests {
    margin-bottom: 70px;
    padding: 0 7px; }
    .ftue-container .modal-body .matrix.ftue-interests .matrix-item {
      flex: 0 1 calc((100% - 28px)/2);
      margin: 0 7px 14px; }
      .ftue-container .modal-body .matrix.ftue-interests .matrix-item.ftue-interest-suggestion {
        min-height: 50vw;
        flex: 0 1 100%;
        margin: 0 7px; }
      .ftue-container .modal-body .matrix.ftue-interests .matrix-item .item-text h4, .ftue-container .modal-body .matrix.ftue-interests .matrix-item .item-text span.h4-block {
        margin: 0 14px;
        font-size: 18px;
        line-height: 21px; }
  .ftue-container .modal-bottom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    pointer-events: none;
    border: none;
    padding: 0; }
    .ftue-container .modal-bottom .modal-bottom-wrapper {
      position: absolute;
      pointer-events: auto;
      z-index: 999;
      bottom: 0;
      left: 0;
      height: 80px;
      box-sizing: border-box;
      padding: 21px;
      background-color: white;
      border-top: 1px solid #edecea; }
  .matrix.ftue-interests .matrix-item:hover {
    cursor: pointer; }
    .matrix.ftue-interests .matrix-item:hover .item-image {
      filter: brightness(0.6);
      transform: none; }
    .matrix.ftue-interests .matrix-item:hover .item-text .item-radio {
      transform: scale3d(1, 1, 1); }
    .matrix.ftue-interests .matrix-item:hover .item-text h4, .matrix.ftue-interests .matrix-item:hover .item-text span.h4-block {
      transform: none; } }
