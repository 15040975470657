.flex-column {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.form-section {
  width: 100%; }

.form-item {
  border: 1px solid #b8b6b4;
  padding: 28px;
  box-sizing: border-box;
  margin-bottom: 14px;
  display: flex;
  flex-wrap: wrap; }

.form-input {
  position: relative;
  padding-top: 14px; }
  .form-input .form-input-error {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    font-size: 14px;
    color: #ff674d; }

.component-form {
  background-color: #edecea;
  padding: 56px 0; }
  .component-form .input-group {
    align-items: flex-start; }
    .component-form .input-group input, .component-form .input-group textarea, .component-form .input-group select {
      background-color: white;
      border: none;
      border-radius: 4px;
      margin: 0;
      padding: 7px;
      font-size: 16px; }
    .component-form .input-group .upload-input {
      display: flex;
      flex: 0 1 75%; }
      .component-form .input-group .upload-input input {
        flex: 1 1 auto; }
      .component-form .input-group .upload-input .filepicker {
        margin-left: 7px; }
    .component-form .input-group label {
      padding-right: 14px;
      font-weight: bold;
      font-size: 16px;
      color: #b8b6b4; }
    .component-form .input-group .input-tip {
      width: 100%;
      border: 1px solid #b8b6b4;
      border-radius: 4px;
      padding: 14px;
      box-sizing: border-box;
      margin: 14px 0;
      background-color: white; }
      .component-form .input-group .input-tip h5, .component-form .input-group .input-tip p {
        margin-bottom: 7px;
        font-size: 14px; }
  .component-form .component-settings, .component-form .component-type-settings {
    display: flex;
    flex-wrap: wrap; }
    .component-form .component-settings .input-group, .component-form .component-type-settings .input-group {
      flex: 0 1 50%; }
      .component-form .component-settings .input-group:nth-of-type(even) label, .component-form .component-type-settings .input-group:nth-of-type(even) label {
        box-sizing: border-box;
        padding-left: 14px;
        text-align: right; }
      .component-form .component-settings .input-group.input-group-full, .component-form .component-type-settings .input-group.input-group-full {
        flex: 0 1 100%;
        display: flex; }
        .component-form .component-settings .input-group.input-group-full label, .component-form .component-type-settings .input-group.input-group-full label {
          width: 100%;
          margin-bottom: 14px; }
        .component-form .component-settings .input-group.input-group-full textarea, .component-form .component-type-settings .input-group.input-group-full textarea {
          width: 100%;
          min-height: 140px; }
    .component-form .component-settings h3, .component-form .component-settings h4, .component-form .component-type-settings h3, .component-form .component-type-settings h4 {
      width: 100%; }
  .component-form .component-type-settings {
    padding-top: 28px; }
    .component-form .component-type-settings .matrix {
      width: 100%;
      padding-top: 56px; }
      .component-form .component-type-settings .matrix .matrix-item .input-group {
        flex-direction: column; }
        .component-form .component-type-settings .matrix .matrix-item .input-group label, .component-form .component-type-settings .matrix .matrix-item .input-group input, .component-form .component-type-settings .matrix .matrix-item .input-group textarea, .component-form .component-type-settings .matrix .matrix-item .input-group .upload-input {
          width: 100%; }
        .component-form .component-type-settings .matrix .matrix-item .input-group label {
          padding: 0;
          margin-bottom: 7px;
          text-align: left; }
      .component-form .component-type-settings .matrix .matrix-item .input-group.input-group-inline {
        flex-direction: row;
        justify-content: center;
        align-items: center; }
        .component-form .component-type-settings .matrix .matrix-item .input-group.input-group-inline label {
          flex: 0 1 auto; }
        .component-form .component-type-settings .matrix .matrix-item .input-group.input-group-inline input {
          width: inherit;
          flex: 1 0 auto; }
  .component-form .input-country:checked + .country-flag {
    outline: 3px solid #43413f;
    outline-offset: 4px; }
  .component-form .country-flag {
    font-size: 28px !important;
    margin-right: 22px;
    cursor: pointer; }

.component-form-preview {
  margin-bottom: 112px; }

.component-save {
  position: fixed;
  bottom: 0px;
  background-color: white;
  z-index: 1000; }
  .component-save .button {
    margin: 14px 7px; }

.tabs {
  margin-bottom: 0; }

.tab-content {
  border: 2px solid #ff674d;
  padding: 14px; }

.left_label {
  text-align: left !important;
  padding-left: 0 !important; }

@media (max-width: 961px) {
  input {
    font-size: 16px; } }
