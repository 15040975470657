.animate-switch-fade {
  opacity: 1;
  transition: opacity linear 1000ms;
  display: block; }

.animate-switch-fade.ng-animate {
  opacity: 1; }

.animate-switch-fade.ng-leave.ng-leave-active,
.animate-switch-fade.ng-enter {
  opacity: 0; }

.animate-switch-fade.ng-leave,
.animate-switch-fade.ng-enter.ng-enter-active {
  opacity: 1; }

.animate-if-fade.ng-enter, .animate-if-fade.ng-leave {
  transition: opacity cubic-bezier(0, 0, 0.5, 1) 500ms; }

.animate-if-fade.ng-enter,
.animate-if-fade.ng-leave.ng-leave-active {
  opacity: 0; }

.animate-if-fade.ng-leave,
.animate-if-fade.ng-enter.ng-enter-active {
  opacity: 1; }

.animate-show-fade {
  opacity: 1;
  transition: opacity cubic-bezier(0, 0, 0.5, 1) 500ms; }

.animate-show-fade.ng-hide {
  opacity: 0; }

.animate-show-collapse {
  flex: 1 1 auto;
  transition: opacity cubic-bezier(0, 0, 0.5, 1) 500ms; }

.animate-show-collapse.ng-hide {
  flex: 0.001 1 20px; }

.animate-if-slide-down.ng-enter, .animate-if-slide-down.ng-leave {
  transition: transform cubic-bezier(0, 0, 0.5, 1) 500ms, opacity ease 500ms; }

.animate-if-slide-down.ng-enter,
.animate-if-slide-down.ng-leave.ng-leave-active {
  opacity: 0;
  transform: translateY(-50px); }

.animate-if-slide-down.ng-leave,
.animate-if-slide-down.ng-enter.ng-enter-active {
  opacity: 1;
  transform: translateY(0px); }
