.main-view {
  padding-top: 76px; }

@keyframes nav-fadein {
  0% {
    opacity: 0; }
  33% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.cs-nav-container {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 200;
  animation: nav-fadein 1.5s 1; }
  .cs-nav-container .cs-nav {
    height: 76px;
    padding: 23px 28px;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    font-weight: 500; }
    .cs-nav-container .cs-nav .cs-nav-center {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center; }
    .cs-nav-container .cs-nav .cs-nav-left {
      position: absolute;
      height: 30px;
      top: 23px;
      left: 28px; }
      .cs-nav-container .cs-nav .cs-nav-left img {
        height: 100%; }
      .cs-nav-container .cs-nav .cs-nav-left .logo-color {
        display: inline-block; }
      .cs-nav-container .cs-nav .cs-nav-left .logo-white {
        display: none; }
    .cs-nav-container .cs-nav .cs-nav-right {
      position: absolute;
      height: 30px;
      top: 23px;
      right: 28px;
      display: flex;
      align-items: center; }
      .cs-nav-container .cs-nav .cs-nav-right .cs-nav-item {
        margin: 0 0 0 14px;
        line-height: 14px;
        font-weight: 500;
        font-size: 17px; }
        .cs-nav-container .cs-nav .cs-nav-right .cs-nav-item:before {
          display: none; }
      .cs-nav-container .cs-nav .cs-nav-right .cs-nav-item:last-of-type {
        margin-right: 0; }
      .cs-nav-container .cs-nav .cs-nav-right img {
        width: 26px;
        height: 26px;
        border: 2px solid white;
        border-radius: 14px; }
    .cs-nav-container .cs-nav .button.solid.orange {
      padding: 4px 14px 5px;
      border-color: #ff674d; }
    .cs-nav-container .cs-nav .cs-nav-icon-item {
      margin: 0 0 0 10px;
      padding: 0 0 0 10px;
      color: #43413f; }
      .cs-nav-container .cs-nav .cs-nav-icon-item.favourite-logo svg {
        width: 12px; }
      .cs-nav-container .cs-nav .cs-nav-icon-item:before {
        display: none; }
      .cs-nav-container .cs-nav .cs-nav-icon-item:hover {
        color: #43413f; }
    .cs-nav-container .cs-nav #cart-icon span,
    .cs-nav-container .cs-nav #profile-dropdown-toggle span {
      font-size: 18px;
      vertical-align: middle; }
    .cs-nav-container .cs-nav .cs-nav-item {
      position: relative;
      margin: 0px 14px;
      font-weight: 500;
      font-size: 17px;
      color: #43413f;
      text-decoration: none; }
      .cs-nav-container .cs-nav .cs-nav-item.button {
        margin: 0 0 0 28px; }
      .cs-nav-container .cs-nav .cs-nav-item.button.hide-desktop {
        display: none; }
      .cs-nav-container .cs-nav .cs-nav-item:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        bottom: -1px;
        left: 0;
        background-color: #43413f;
        visibility: hidden;
        transform: scale3d(0, 1, 1);
        transition: transform ease 200ms; }
      .cs-nav-container .cs-nav .cs-nav-item:hover {
        text-decoration: none;
        cursor: pointer; }
        .cs-nav-container .cs-nav .cs-nav-item:hover:before {
          visibility: visible;
          transform: scale3d(1, 1, 1); }
    .cs-nav-container .cs-nav .button:hover {
      color: white; }

.nav-ghost {
  margin-top: 0px; }
  .nav-ghost .cs-nav-container {
    margin-top: 0;
    opacity: 1;
    transition: opacity ease 300ms;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.3) 20%, transparent 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, rgba(0, 0, 0, 0.3)), color-stop(100%, transparent));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3) 20%, transparent 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.3) 20%, transparent 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.3) 20%, transparent 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 20%, transparent 100%);
    /* W3C */ }
    .nav-ghost .cs-nav-container .cs-nav {
      background-color: transparent;
      color: white; }
      .nav-ghost .cs-nav-container .cs-nav .cs-nav-left .logo-color {
        display: none; }
      .nav-ghost .cs-nav-container .cs-nav .cs-nav-left .logo-white {
        display: inline-block; }
      .nav-ghost .cs-nav-container .cs-nav .cs-nav-item {
        color: white; }
        .nav-ghost .cs-nav-container .cs-nav .cs-nav-item:before {
          background-color: white; }
      .nav-ghost .cs-nav-container .cs-nav .button {
        border-color: white; }
        .nav-ghost .cs-nav-container .cs-nav .button:hover {
          background-color: white;
          color: #43413f; }
    .nav-ghost .cs-nav-container .cs-nav-menu-toggle .lines, .nav-ghost .cs-nav-container .cs-nav-menu-toggle .lines::before, .nav-ghost .cs-nav-container .cs-nav-menu-toggle .lines::after {
      background-color: white; }
  .nav-ghost .main-view {
    padding-top: 0px; }

.nav-sticky .cs-nav-container.nav-transparent .cs-nav {
  background-color: transparent;
  color: white;
  border-bottom: 0px; }
  .nav-sticky .cs-nav-container.nav-transparent .cs-nav .cs-nav-left .logo-color {
    display: none; }
  .nav-sticky .cs-nav-container.nav-transparent .cs-nav .cs-nav-left .logo-white {
    display: inline-block; }
  .nav-sticky .cs-nav-container.nav-transparent .cs-nav .cs-nav-item {
    color: white; }
    .nav-sticky .cs-nav-container.nav-transparent .cs-nav .cs-nav-item:before {
      background-color: white; }

.nav-sticky .cs-nav-container.nav-transparent .cs-nav-menu-toggle .lines, .nav-sticky .cs-nav-container.nav-transparent .cs-nav-menu-toggle .lines::before, .nav-sticky .cs-nav-container.nav-transparent .cs-nav-menu-toggle .lines::after {
  background-color: white; }

.nav-sticky .cs-nav-container, .nav-sticky .cs-nav-container.nav-fixed {
  position: fixed;
  transform: translate3d(0px, 0px, 0px); }
  .nav-sticky .cs-nav-container.nav-hidden, .nav-sticky .cs-nav-container.nav-fixed.nav-hidden {
    visibility: visible; }

.cs-nav-menu-toggle {
  display: none; }

.hide-desktop {
  display: none; }

.hide-mobile {
  display: inherit; }

body .cs-nav-container.nav-fixed {
  position: fixed;
  transform: translate3d(0, -76px, 0);
  transition: transform ease 400ms; }
  body .cs-nav-container.nav-fixed .cs-nav {
    background-color: white;
    border-bottom: 1px solid #edecea;
    color: #43413f; }
    body .cs-nav-container.nav-fixed .cs-nav .cs-nav-item {
      color: #43413f; }
      body .cs-nav-container.nav-fixed .cs-nav .cs-nav-item:before {
        background-color: #43413f; }
      body .cs-nav-container.nav-fixed .cs-nav .cs-nav-item.button {
        border-color: #43413f; }
      body .cs-nav-container.nav-fixed .cs-nav .cs-nav-item.button:hover {
        color: white;
        background-color: #43413f; }
    body .cs-nav-container.nav-fixed .cs-nav .cs-nav-left .logo-color {
      display: inline-block; }
    body .cs-nav-container.nav-fixed .cs-nav .cs-nav-left .logo-white {
      display: none; }
    body .cs-nav-container.nav-fixed .cs-nav .cs-nav-right .cs-nav-menu-toggle .lines, body .cs-nav-container.nav-fixed .cs-nav .cs-nav-right .cs-nav-menu-toggle .lines::before, body .cs-nav-container.nav-fixed .cs-nav .cs-nav-right .cs-nav-menu-toggle .lines::after {
      background-color: #43413f; }
  body .cs-nav-container.nav-fixed.nav-hidden {
    visibility: hidden;
    opacity: 0; }
  body .cs-nav-container.nav-fixed.nav-visible {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: transform ease 400ms; }
  body .cs-nav-container.nav-fixed.nav-visible-add, body .cs-nav-container.nav-fixed.nav-visible-remove {
    visibility: visible;
    opacity: 1;
    transition: transform ease 400ms; }
  body .cs-nav-container.nav-fixed.nav-show-menu .cs-nav-right .cs-nav-menu-toggle .lines, body .cs-nav-container.nav-fixed.nav-show-menu .cs-nav-right .cs-nav-menu-toggle .lines::before, body .cs-nav-container.nav-fixed.nav-show-menu .cs-nav-right .cs-nav-menu-toggle .lines::after {
    background-color: transparent; }
  body .cs-nav-container.nav-fixed.nav-show-menu .cs-nav-right .cs-nav-menu-toggle .lines::before, body .cs-nav-container.nav-fixed.nav-show-menu .cs-nav-right .cs-nav-menu-toggle .lines::after {
    background-color: #43413f; }

ul.dropdown-wrapper {
  margin: 0; }
  ul.dropdown-wrapper > li {
    list-style-type: none; }

@media (max-width: 1200px) {
  .cs-nav-container .cs-nav .cs-nav-center {
    justify-content: left;
    align-items: left;
    margin-left: 135px; }
  .cs-nav-container .cs-nav .cs-nav-item {
    margin-right: 8px;
    margin-left: 8px; }
  .cs-nav-container .cs-nav .cs-nav-item {
    margin-left: 8px; }
  .cs-nav-container .cs-nav .cs-nav-item.button {
    margin-left: 14px; } }

@media (min-width: 951px) {
  .always-hide-desktop {
    display: none !important; } }

@media (max-width: 950px) {
  .hide-desktop {
    display: block; }
  .hide-mobile, .button.hide-mobile {
    display: none; }
  .cs-nav-container {
    z-index: 700;
    overflow: hidden; }
    .cs-nav-container .cs-nav {
      z-index: -1; }
      .cs-nav-container .cs-nav .cs-nav-center {
        text-align: center;
        visibility: hidden;
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;
        top: 0;
        left: 0;
        margin: 0;
        background-color: transparent;
        padding-top: 118px;
        padding-bottom: 56px;
        transition: visibility ease 0ms 400ms, background ease 0ms 0ms; }
        .cs-nav-container .cs-nav .cs-nav-center:before {
          content: "";
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          width: 200%;
          height: 140%;
          border-radius: 100%;
          background-color: white;
          opacity: 0;
          transform: translate3d(-25%, -55%, 0) scale3d(0.4, 0.4, 0.4);
          transform-origin: top right;
          transition: transform cubic-bezier(0.3, 0.51, 1, 1.1) 300ms, opacity ease 300ms; }
        .cs-nav-container .cs-nav .cs-nav-center .cs-nav-item {
          opacity: 0;
          display: block;
          width: 100%;
          margin: 0 0 28px;
          transition: opacity ease 400ms 0ms;
          text-align: center;
          align-items: center;
          color: #43413f; }
          .cs-nav-container .cs-nav .cs-nav-center .cs-nav-item i {
            margin-right: 7px; }
          .cs-nav-container .cs-nav .cs-nav-center .cs-nav-item.hide-desktop {
            display: inline-block; }
          .cs-nav-container .cs-nav .cs-nav-center .cs-nav-item.button {
            max-width: 200px;
            margin: 0 auto 28px; }
            .cs-nav-container .cs-nav .cs-nav-center .cs-nav-item.button:hover {
              color: white; }
          .cs-nav-container .cs-nav .cs-nav-center .cs-nav-item:before {
            display: none; }
        .cs-nav-container .cs-nav .cs-nav-center hr.cs-nav-item {
          width: auto;
          margin: 28px; }
      .cs-nav-container .cs-nav .cs-nav-left {
        height: 76px;
        top: 0;
        left: 0;
        padding: 23px 28px;
        background-color: transparent;
        width: 100%;
        transition: background-color ease 0ms 0ms;
        box-sizing: border-box; }
        .cs-nav-container .cs-nav .cs-nav-left img {
          height: 30px;
          display: inline-block; }
      .cs-nav-container .cs-nav .cs-nav-right .favourite-logo svg {
        width: 12px !important; }
      .cs-nav-container .cs-nav .cs-nav-left, .cs-nav-container .cs-nav .cs-nav-right {
        z-index: 500; }
  .cs-nav-menu-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 6px;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 6px;
    line-height: 20px; }
    .cs-nav-menu-toggle .lines, .cs-nav-menu-toggle .lines::before, .cs-nav-menu-toggle .lines::after {
      display: inline-block;
      height: 2px;
      background-color: #43413f;
      border-radius: 1.71429px;
      transition: 0.3s; }
    .cs-nav-menu-toggle .lines::before, .cs-nav-menu-toggle .lines::after {
      position: absolute;
      left: 0;
      content: '';
      transform-origin: 1.71429px center; }
    .cs-nav-menu-toggle .lines {
      position: relative;
      width: 15px; }
    .cs-nav-menu-toggle .lines::before {
      top: 7px;
      width: 19px; }
    .cs-nav-menu-toggle .lines::after {
      top: -7px;
      width: 21px; }
  .cs-nav-container.nav-show-menu {
    position: fixed;
    transform: none !important;
    visibility: visible !important;
    height: 100%;
    z-index: 800; }
    .cs-nav-container.nav-show-menu .cs-nav .cs-nav-menu-toggle .lines {
      background-color: transparent !important; }
    .cs-nav-container.nav-show-menu .cs-nav .cs-nav-menu-toggle .lines:before, .cs-nav-container.nav-show-menu .cs-nav .cs-nav-menu-toggle .lines:after {
      transform-origin: 50% 50%;
      top: 0;
      width: 21px;
      background-color: #43413f; }
    .cs-nav-container.nav-show-menu .cs-nav .cs-nav-menu-toggle .lines:before {
      transform: rotate3d(0, 0, 1, 45deg); }
    .cs-nav-container.nav-show-menu .cs-nav .cs-nav-menu-toggle .lines:after {
      transform: rotate3d(0, 0, 1, -45deg); }
    .cs-nav-container.nav-show-menu .cs-nav .cs-nav-left .logo-color {
      display: inline-block; }
    .cs-nav-container.nav-show-menu .cs-nav .cs-nav-left .logo-white {
      display: none; }
    .cs-nav-container.nav-show-menu .cs-nav .cs-nav-center {
      visibility: visible;
      transition: visibility ease 0ms 0ms, background ease 0ms 400ms;
      background-color: white;
      overflow-y: scroll; }
      .cs-nav-container.nav-show-menu .cs-nav .cs-nav-center:before {
        opacity: 1;
        transform: translate3d(-25%, -20%, 0) scale3d(1, 1, 1); }
      .cs-nav-container.nav-show-menu .cs-nav .cs-nav-center .cs-nav-item {
        opacity: 1;
        transition: opacity ease 400ms 200ms; }
    .cs-nav-container.nav-show-menu .cs-nav .cs-nav-left {
      background-color: white;
      transition: background-color ease 400ms 400ms; }
  .cs-nav-item-active span {
    text-decoration: underline; } }

@media (max-width: 320px) {
  .cs-nav-container .cs-nav .cs-nav-left .logo-color, .cs-nav-container .cs-nav .cs-nav-left .logo-white {
    display: none !important; }
  .cs-nav-container .cs-nav .cs-nav-left .logo-color-symbol {
    display: inline-block; }
  .cs-nav-container .cs-nav .cs-nav-left .logo-white-symbol {
    display: none; }
  .cs-nav-container .cs-nav .cs-nav-left .logo-color-symbol svg, .cs-nav-container .cs-nav .cs-nav-left .logo-white-symbol svg {
    width: 26%;
    height: 100%; }
  .nav-ghost .cs-nav-container .cs-nav .cs-nav-left .logo-color-symbol {
    display: none; }
  .nav-ghost .cs-nav-container .cs-nav .cs-nav-left .logo-white-symbol {
    display: inline-block; }
  .nav-sticky .cs-nav-container.nav-transparent .cs-nav .cs-nav-left .logo-color-symbol {
    display: none; }
  .nav-sticky .cs-nav-container.nav-transparent .cs-nav .cs-nav-left .logo-white-symbol {
    display: inline-block; }
  .cs-nav-container.nav-fixed .cs-nav .cs-nav-left .logo-color-symbol, .cs-nav-container.nav-show-menu .cs-nav .cs-nav-left .logo-color-symbol {
    display: inline-block; }
  .cs-nav-container.nav-fixed .cs-nav .cs-nav-left .logo-white-symbol, .cs-nav-container.nav-show-menu .cs-nav .cs-nav-left .logo-white-symbol {
    display: none; } }
