.joule-size .media-video-container {
  margin: 56px 0 28px; }

.joule-size .text-block h2 {
  max-width: 390px;
  margin: 0 auto 28px; }

.joule-size .text-icon svg {
  height: 85px;
  width: auto;
  margin-bottom: 7px; }
  .joule-size .text-icon svg .a, .joule-size .text-icon svg .b {
    stroke-width: 2px; }

@media (max-width: 1194px) {
  .joule-size .media-video-container {
    margin: 56px 42px 28px; } }

@media (max-width: 640px) {
  .joule-size .media-video-container {
    margin: 42px 28px 0;
    min-height: 200px; } }
