.component-comments .component-menu {
  height: inherit; }

.comments .comments-title {
  margin-bottom: 42px; }
  .comments .comments-title h2, .comments .comments-title span.h2-block {
    margin-bottom: 14px; }
  .comments .comments-title h5, .comments .comments-title span.h5-block {
    margin-bottom: 0; }

.comments .comments-login {
  margin-bottom: 28px;
  display: flex;
  justify-content: center;
  align-items: center; }

.comments .comment-input {
  margin-bottom: 14px;
  background-color: white;
  color: #43413f;
  padding: 14px;
  border-radius: 4px;
  position: relative; }
  .comments .comment-input text-angular {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px; }
    .comments .comment-input text-angular .ta-toolbar {
      order: 2;
      margin-top: 14px; }
      .comments .comment-input text-angular .ta-toolbar .button-group {
        margin: 0;
        margin-right: 14px;
        z-index: 1; }
      .comments .comment-input text-angular .ta-toolbar .button {
        padding: 7px 14px;
        font-size: 14px; }
    .comments .comment-input text-angular .ta-editor {
      border: 1px solid #b8b6b4;
      min-height: 84px; }
      .comments .comment-input text-angular .ta-editor p {
        margin-bottom: 0px; }
      .comments .comment-input text-angular .ta-editor img {
        width: 100%; }
  .comments .comment-input .comment-input-actions {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    bottom: 28px;
    right: 14px; }
    .comments .comment-input .comment-input-actions .button {
      margin-left: 14px; }

.comments .comments-list {
  display: block;
  background-color: white;
  color: #43413f;
  padding: 14px; }

.comments .loading-spinner {
  padding: 14px; }

.comment {
  width: 100%;
  box-sizing: border-box; }
  .comment .comment-parent {
    display: flex;
    box-sizing: border-box;
    margin-bottom: 21px; }
  .comment .comment-author {
    flex: 0 0 42px; }
    .comment .comment-author img {
      width: 42px;
      border-radius: 50%; }
  .comment .comment-body {
    flex: 0 1 100%;
    padding-left: 14px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px; }
    .comment .comment-body .comment-top {
      color: #b8b6b4; }
      .comment .comment-body .comment-top strong {
        color: #43413f; }
    .comment .comment-body .comment-content {
      word-break: break-word; }
      .comment .comment-body .comment-content p {
        margin-bottom: 0px; }
      .comment .comment-body .comment-content img {
        width: 100%;
        margin: 14px 0; }
  .comment .comment-children {
    display: block;
    box-sizing: border-box; }
  .comment .comment-liked {
    color: #d44950; }
  .comment .comment-top .fa.fa-reply {
    transform: scaleX(-1); }
  .comment .comment-bottom {
    color: #7a7876;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400; }
    .comment .comment-bottom .comment-item {
      margin-right: 14px;
      transition: color 200ms ease-in-out; }
      .comment .comment-bottom .comment-item:hover {
        cursor: pointer;
        color: #43413f;
        text-decoration: underline; }
    .comment .comment-bottom .comment-item.comment-like {
      display: flex;
      justify-content: center;
      align-items: center; }
      .comment .comment-bottom .comment-item.comment-like .comment-likes-count {
        margin-left: 7px; }
      .comment .comment-bottom .comment-item.comment-like:hover {
        color: #d44950;
        text-decoration: none; }
  .comment .comment-input {
    margin-top: 14px; }

.comment-indent {
  padding-left: 56px; }

.comments-paginations {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  color: #7a7876; }
  .comments-paginations .comments-pagination {
    border: 1px solid #7a7876;
    border-right: 0px;
    padding: 0 14px;
    transition: 200ms all ease-in-out; }
    .comments-paginations .comments-pagination:first-of-type {
      border-radius: 4px 0 0 4px; }
    .comments-paginations .comments-pagination:last-of-type {
      border-right: 1px solid #b8b6b4;
      border-radius: 0 4px 4px 0; }
    .comments-paginations .comments-pagination:hover {
      background-color: #b8b6b4;
      color: white;
      cursor: pointer;
      border-color: #b8b6b4; }
    .comments-paginations .comments-pagination.active {
      background-color: #7a7876;
      color: white;
      border-color: #7a7876; }

@media (max-width: 961px) {
  .component.component-comments {
    padding-left: 42px;
    padding-right: 42px; } }

@media (max-width: 641px) {
  .component.component-comments {
    padding-left: 14px;
    padding-right: 14px; } }

@media (max-width: 641px) {
  .comment .comment-content {
    margin-left: -49px; }
  .comment .comment-children {
    margin-left: 14px;
    padding-left: 14px;
    border-left: 1px solid #edecea; }
  .comment .comment-children .comment-children {
    margin-left: 0;
    padding-left: 0;
    border-left: none; }
  .comment .comment-top {
    min-height: 42px; }
  .comment .comment-author img {
    width: 35px; }
  .comment-indent {
    padding-left: 7px; }
  .comments .comment-input.comment-input-reply text-angular .ta-toolbar {
    order: 0;
    margin-bottom: 14px;
    margin-top: 0;
    display: flex;
    justify-content: center; }
  .comments .comment-input.comment-input-reply .comment-input-actions {
    position: relative;
    bottom: 0px;
    justify-content: center; } }
