.slider {
  position: relative; }
  .slider .slides {
    position: relative;
    overflow: hidden;
    background-color: #43413f;
    max-height: 90vh; }
    .slider .slides:before {
      content: '';
      display: block;
      padding-top: 56.25%; }
  .slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; }
    .slider .slide .slide-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; }
      .slider .slide .slide-content .slide-video {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%; }
        .slider .slide .slide-content .slide-video:before {
          content: '';
          display: block;
          padding-top: 56.25%; }
        .slider .slide .slide-content .slide-video cs-embed-video {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }
      .slider .slide .slide-content .slide-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 1;
        flex-direction: column;
        padding: 28px 112px 112px;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.3);
        transition: background-color ease 300ms;
        transition: opacity ease 300ms;
        opacity: 1; }
        .slider .slide .slide-content .slide-text .slide-text-wrapper {
          max-width: 700px;
          color: white; }
        .slider .slide .slide-content .slide-text .slide-title {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 28px; }
          .slider .slide .slide-content .slide-text .slide-title .button-circle-play-large {
            flex-shrink: 0;
            margin-bottom: 14px;
            transition: all 300ms ease; }
          .slider .slide .slide-content .slide-text .slide-title h2 {
            font-size: 70px;
            line-height: 70px;
            font-weight: bold;
            margin-bottom: 0;
            opacity: 1;
            transition: opacity ease 300ms; }
        .slider .slide .slide-content .slide-text p, .slider .slide .slide-content .slide-text .button {
          opacity: 1;
          transition: opacity ease 300ms; }
  .slider .slider-controls {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none; }
    .slider .slider-controls .slider-prev, .slider .slider-controls .slider-next {
      z-index: 500;
      opacity: 0.85;
      margin: 84px 7px 0; }
      .slider .slider-controls .slider-prev .button-circle-large, .slider .slider-controls .slider-next .button-circle-large {
        transform: scale(0.8, 0.8);
        border: 1px solid #b8b6b4; }
      .slider .slider-controls .slider-prev i.fa, .slider .slider-controls .slider-next i.fa {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center; }

.slider.video-playing .slide .slide-content .slide-text {
  background-color: transparent; }
  .slider.video-playing .slide .slide-content .slide-text h2, .slider.video-playing .slide .slide-content .slide-text p, .slider.video-playing .slide .slide-content .slide-text .button {
    opacity: 0; }
  .slider.video-playing .slide .slide-content .slide-text .button-circle-play-large {
    opacity: 0; }

.slider-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  /* IE6-9 */ }
  .slider-nav .slider-nav-items {
    width: 100%;
    max-width: 900px;
    display: flex;
    padding: 84px 28px 42px;
    color: white;
    border-radius: 4px;
    transition: transform ease 300ms; }
    .slider-nav .slider-nav-items .slider-nav-item {
      flex: 0 0 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box; }
      .slider-nav .slider-nav-items .slider-nav-item.has-line {
        position: relative; }
        .slider-nav .slider-nav-items .slider-nav-item.has-line:before {
          content: "";
          position: absolute;
          top: 8px;
          left: 0;
          width: calc(100% - 16px);
          height: 0px;
          border-top: 2px solid rgba(255, 255, 255, 0.5); }
        .slider-nav .slider-nav-items .slider-nav-item.has-line:before {
          transform: translateX(-50%); }
      .slider-nav .slider-nav-items .slider-nav-item .slider-nav-item-circle {
        position: relative;
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: 14px;
        width: 14px;
        height: 14px;
        margin-bottom: 14px;
        transition: 300ms all ease-in-out; }
        .slider-nav .slider-nav-items .slider-nav-item .slider-nav-item-circle:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 14px;
          height: 14px;
          background-color: white;
          border-radius: 100%;
          transform: translate(-50%, -50%) scale3d(0, 0, 1);
          transform-origin: center;
          transition: transform 300ms ease; }
      .slider-nav .slider-nav-items .slider-nav-item .slider-nav-item-title {
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        transition: 300ms all ease-in-out; }
      .slider-nav .slider-nav-items .slider-nav-item:hover {
        cursor: pointer; }
        .slider-nav .slider-nav-items .slider-nav-item:hover .slider-nav-item-circle {
          border: 2px solid white; }
        .slider-nav .slider-nav-items .slider-nav-item:hover .slider-nav-item-title {
          color: white; }
      .slider-nav .slider-nav-items .slider-nav-item.active .slider-nav-item-circle {
        border: 2px solid white;
        transform: scale3d(1.2, 1.2, 1.2);
        transition: transform 300ms ease; }
        .slider-nav .slider-nav-items .slider-nav-item.active .slider-nav-item-circle:before {
          transform: translate(-50%, -50%) scale3d(1.1, 1.1, 1); }
      .slider-nav .slider-nav-items .slider-nav-item.active .slider-nav-item-title {
        color: white; }

.slider-mobile-cta {
  display: none; }

.slider .slide {
  opacity: 0;
  transition: all 400ms ease-in-out; }
  .slider .slide.slide-no-transition {
    transition: all 0ms; }

.slide-in {
  opacity: 1; }

@media (max-width: 900px) {
  .slider .slide .slide-content .slide-text .slide-title h2 {
    font-size: 56px;
    line-height: 56px; } }

@media (max-width: 750px) {
  .slider .slide .slide-content .slide-text .slide-title .button-circle-play-large {
    transform: scale3d(0.8, 0.8, 1); }
  .slider .slide .slide-content .slide-text .slide-title h2 {
    font-size: 35px;
    line-height: 35px; }
  .slider .slider-nav-item-title {
    max-width: 150px;
    white-space: wrap; } }

@media (max-width: 600px) {
  .slider .slides {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.8); }
    .slider .slides:before {
      content: '';
      display: block;
      padding-top: 150%; }
    .slider .slides:before {
      padding-top: 120%; }
  .slider .slide {
    margin: 42px;
    margin-bottom: 112px;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    background-color: white; }
    .slider .slide .slide-content {
      display: block; }
      .slider .slide .slide-content .slide-video {
        width: 100%;
        height: 60%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        .slider .slide .slide-content .slide-video .button-circle-play-large {
          z-index: 3; }
      .slider .slide .slide-content .slide-text {
        position: relative;
        flex: 0 1 100%;
        width: 100%;
        height: 40%;
        padding: 28px;
        background-color: white;
        display: flex;
        vertical-align: center; }
        .slider .slide .slide-content .slide-text .slide-title {
          margin-bottom: 0; }
          .slider .slide .slide-content .slide-text .slide-title h2 {
            max-width: 300px;
            font-size: 21px;
            line-height: 28px;
            font-weight: 500;
            color: #43413f;
            margin-bottom: 0; }
          .slider .slide .slide-content .slide-text .slide-title .button-circle-play-large {
            display: none; }
        .slider .slide .slide-content .slide-text p {
          display: none; }
  .slider .slider-controls {
    display: flex; }
  .slider .slider-nav {
    display: none; }
  .slider .slider-mobile-cta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .slider .slider-mobile-cta .button {
      margin-bottom: 42px; } }
