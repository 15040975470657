.hero-slider {
  position: relative;
  width: 100%;
  min-height: 80vh; }
  .hero-slider .hero-type-standard, .hero-slider .hero-height-tall {
    min-height: 80vh; }
  .hero-slider.slider-height-tall {
    min-height: 90vh; }
    .hero-slider.slider-height-tall .hero-type-standard, .hero-slider.slider-height-tall .hero-height-tall {
      min-height: 90vh; }
  .hero-slider .hero-slider-item {
    position: absolute;
    left: 0;
    width: 100%; }
  .hero-slider .hero-text {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: transform cubic-bezier(0.35, 0.13, 0.22, 1) 400ms 250ms, opacity cubic-bezier(0.35, 0.13, 0.22, 1) 400ms 250ms; }
  .hero-slider .is-selected .hero-text {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.flickity-prev-next-button svg {
  display: none; }

.flickity-prev-next-button {
  width: 49px;
  height: 49px;
  background: white;
  opacity: 0;
  transform: scale3d(0.9, 0.9, 1) translateY(-50%);
  transition: transform cubic-bezier(0.35, 0.13, 0.22, 1) 300ms, opacity cubic-bezier(0.35, 0.13, 0.22, 1) 300ms; }
  .flickity-prev-next-button:before, .flickity-prev-next-button:after {
    content: "";
    display: block;
    position: absolute;
    background: #43413f;
    border-radius: 10px;
    width: 2px;
    height: 12px;
    box-shadow: 0 0 65px rgba(0, 0, 0, 0.3); }
  .flickity-prev-next-button:before {
    top: 14px; }
  .flickity-prev-next-button:after {
    top: 22px; }
  .flickity-prev-next-button:hover {
    cursor: pointer;
    opacity: 1 !important; }

.flickity-prev-next-button.previous:before {
  transform: rotate(45deg);
  right: 51%; }

.flickity-prev-next-button.previous:after {
  transform: rotate(-45deg);
  right: 51%; }

.flickity-prev-next-button.next:before {
  transform: rotate(-45deg);
  left: 51%; }

.flickity-prev-next-button.next:after {
  transform: rotate(45deg);
  left: 51%; }

.hero-slider:hover .flickity-prev-next-button {
  opacity: 0.5;
  transform: scale3d(1, 1, 1) translateY(-50%); }

.flickity-page-dots {
  bottom: 21px; }
  .flickity-page-dots .dot {
    position: relative;
    background-color: transparent;
    border: 2px solid white;
    opacity: 1;
    margin: 0 5px;
    transition: background-color cubic-bezier(0.35, 0.13, 0.22, 1) 300ms;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08); }
    .flickity-page-dots .dot:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      background: white;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale3d(0, 0, 0);
      opacity: 0.2;
      transition: transform 300ms cubic-bezier(0.35, 0.13, 0.22, 1), opacity 300ms cubic-bezier(0.35, 0.13, 0.22, 1); }
    .flickity-page-dots .dot.is-selected:before, .flickity-page-dots .dot:hover:before {
      transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1);
      opacity: 1;
      cursor: pointer; }

@media (max-width: 640px) {
  .hero-slider {
    min-height: 532px; }
    .hero-slider .hero-type-standard, .hero-slider .hero-height-tall {
      min-height: 532px; }
    .hero-slider.slider-height-tall {
      min-height: 532px; }
      .hero-slider.slider-height-tall .hero-type-standard, .hero-slider.slider-height-tall .hero-height-tall {
        min-height: 532px; }
    .hero-slider .hero-text {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
      transition: transform cubic-bezier(0.35, 0.13, 0.22, 1) 400ms 0ms, opacity cubic-bezier(0.35, 0.13, 0.22, 1) 400ms 0ms; }
    .hero-slider .flickity-prev-next-button {
      display: none; } }
