.joule-ad.component {
  display: flex;
  justify-content: center;
  background-color: #9ccbdd; }

.joule-ad-content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.joule-ad-image {
  flex: 1 1 60%;
  padding-top: 28px; }
  .joule-ad-image img {
    width: 100%;
    height: auto; }

.joule-text {
  flex: 1 1 40%;
  max-width: 360px;
  text-align: center;
  padding: 0 28px 0 70px; }
  .joule-text svg .a {
    stroke-width: 2px;
    stroke: white; }

@media (max-width: 1000px) {
  .joule-ad-content {
    padding: 28px 0; }
  .joule-ad-image {
    flex: 1 1 50%; }
  .joule-text {
    flex: 1 1 50%;
    padding: 56px 14px 56px 56px; } }

@media (max-width: 900px) {
  .joule-ad-content {
    display: block; }
  .joule-text {
    max-width: 500px;
    margin: auto;
    padding: 56px 28px 0; } }

@media (max-width: 500px) {
  .joule-ad-content {
    padding: 0 0 28px; }
  .joule-text {
    padding: 56px 28px 28px; } }
