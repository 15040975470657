fifty-fifty {
  display: block; }

.fifty-fifty {
  display: flex; }
  .fifty-fifty .fifty {
    flex: 0 0 50%;
    position: relative; }
  .fifty-fifty .fifty.fifty-text {
    padding: 140px 112px;
    box-sizing: border-box; }
    .fifty-fifty .fifty.fifty-text .fifty-text-wrapper {
      max-width: 600px;
      margin: 0 auto; }
      .fifty-fifty .fifty.fifty-text .fifty-text-wrapper p:last-of-type,
      .fifty-fifty .fifty.fifty-text .fifty-text-wrapper div.text-content:last-of-type {
        margin-bottom: 0; }
      .fifty-fifty .fifty.fifty-text .fifty-text-wrapper .button {
        margin-top: 28px; }

.fifty-fifty.fifty-fifty-align-right {
  flex-direction: row-reverse; }

.fifty-fifty.fifty-fifty-style-padded {
  padding: 42px;
  box-sizing: border-box;
  margin: auto;
  max-width: 1500px; }

@media (min-width: 961px) and (max-width: 1166px) {
  .fifty-fifty .fifty.fifty-text {
    padding: 84px; } }

@media (min-width: 641px) and (max-width: 960px) {
  .fifty-fifty .fifty.fifty-text {
    padding: 56px; }
  .fifty-fifty.fifty-fifty-style-padded {
    padding: 28px; }
    .fifty-fifty.fifty-fifty-style-padded .fifty.fifty-text {
      padding: 56px 56px 56px 28px; }
  .fifty-fifty.fifty-fifty-style-padded.fifty-fifty-align-right {
    padding: 28px; }
    .fifty-fifty.fifty-fifty-style-padded.fifty-fifty-align-right .fifty.fifty-text {
      padding: 56px 28px 56px 56px; } }

@media (max-width: 640px) {
  .fifty-fifty {
    flex-direction: column; }
    .fifty-fifty .fifty.fifty-text {
      padding: 56px 42px;
      flex: 0 0 auto; }
    .fifty-fifty .fifty.fifty-image {
      position: relative;
      overflow: hidden;
      flex: 0 0 auto; }
      .fifty-fifty .fifty.fifty-image:before {
        content: '';
        display: block;
        padding-top: 66.66%; }
  .fifty-fifty.fifty-fifty-align-right {
    flex-direction: column; }
  .fifty-fifty.fifty-fifty-style-padded {
    padding: 14px; }
    .fifty-fifty.fifty-fifty-style-padded .fifty.fifty-text {
      padding: 56px 42px; } }

.component-fifty-fifty.theme-orange .button {
  border-color: white;
  color: white; }
  .component-fifty-fifty.theme-orange .button:hover {
    background-color: white;
    color: #ff674d; }

.component-fifty-fifty.theme-dark .button {
  border-color: white;
  color: white; }
  .component-fifty-fifty.theme-dark .button:hover {
    background-color: white;
    color: #43413f; }
