.component.component-pageNav {
  padding-top: 0;
  padding-bottom: 0;
  border-top: 1px solid #f5f5f5;
  background-color: white; }

.component.component-pageNav.theme-dark {
  background-color: #282828; }
  .component.component-pageNav.theme-dark .page-nav-item svg .a, .component.component-pageNav.theme-dark .page-nav-item svg .b {
    stroke: white; }
  .component.component-pageNav.theme-dark .page-nav-item svg .fill {
    fill: white; }

.page-nav-items {
  padding: 16px 0 26px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .page-nav-items.subnav {
    background-color: #edecea;
    padding: 30px 0; }

.page-nav-item {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  opacity: 1;
  transition: 200ms opacity ease-in-out, color 200ms ease-in-out;
  font-size: 14px;
  font-weight: 500;
  padding: 0 14px;
  animation: scale-in 1.5s 0ms 1;
  min-width: 70px; }
  .page-nav-item:nth-child(1) {
    animation-delay: -200ms; }
  .page-nav-item:nth-child(2) {
    animation-delay: -100ms; }
  .page-nav-item:nth-child(3) {
    animation-delay: 00ms; }
  .page-nav-item svg {
    width: 50px;
    height: 50px;
    margin-bottom: 0; }
    .page-nav-item svg .a, .page-nav-item svg .b {
      stroke-width: 1.7px;
      stroke: #43413f;
      transition: 200ms stroke ease-in-out, 200ms fill ease-in-out; }
    .page-nav-item svg .fill {
      fill: #43413f;
      transition: 200ms stroke ease-in-out, 200ms fill ease-in-out; }
  .page-nav-item:hover {
    opacity: 0.5; }

.page-nav-item.selected span {
  position: relative; }
  .page-nav-item.selected span::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #43413f;
    bottom: 3px;
    left: 0;
    transition: background-color 300ms ease; }

.page-nav-item.new {
  position: relative; }
  .page-nav-item.new::before {
    content: "New";
    position: absolute;
    top: 3px;
    right: 10px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 8px;
    line-height: 8px;
    height: 8px;
    width: 28px;
    padding: 10px 0;
    text-transform: uppercase;
    text-align: center;
    color: white;
    background-color: #ff674d; }

.page-nav-item.selected:hover {
  opacity: 1; }

@media (max-width: 700px) {
  .page-nav-items {
    flex-wrap: nowrap;
    padding: 16px 28px 26px;
    -webkit-overflow-scrolling: touch; }
    .page-nav-items::-webkit-scrollbar {
      display: none; }
    .page-nav-items .last-item-mobile {
      padding-right: 28px; }
  .page-nav-items::-webkit-scrollbar {
    display: none; }
  .page-nav-item {
    padding: 0 7px;
    min-width: 70px;
    font-size: 12px; }
  .page-nav-item.new::before {
    right: 2px; } }

@media (max-width: 350px) {
  .page-nav-items .page-nav-item {
    min-width: 60px; } }
