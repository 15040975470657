.matrix-list .matrix-list-item {
  display: flex; }

.matrix-list .item-text {
  color: #43413f;
  flex: 0 1 100%;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .matrix-list .item-text h4, .matrix-list .item-text span.h4-block {
    margin-bottom: 0; }
  .matrix-list .item-text p, .matrix-list .item-text div.text-block-content {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0; }

.matrix-list .item-number {
  flex: 0 0 auto;
  margin-right: 21px; }

.matrix-list .item-image {
  flex: 0 0 112px;
  width: 112px;
  height: 112px;
  margin-right: 28px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 0px 15px rgba(0, 0, 20, 0.08);
  position: relative;
  overflow: hidden; }
  .matrix-list .item-image:before {
    content: '';
    display: block;
    padding-top: 100%; }
  .matrix-list .item-image .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .matrix-list .item-image cs-image {
    border-radius: 4px;
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .matrix-list .item-image img {
    max-width: 100%; }
  .matrix-list .item-image .item-info .item-info-counters {
    margin-bottom: 0; }
  .matrix-list .item-image .item-info .item-info-more {
    display: none; }

.matrix-list .item-card-label .tag, .matrix-list .item-card-label .premium {
  margin-top: 0; }

.theme-dark .matrix.matrix-list .matrix-item .item-text, .theme-orange .matrix.matrix-list .matrix-item .item-text {
  color: white; }

@media (min-width: 551px) and (max-width: 960px) {
  .matrix-list {
    padding: 0px 42px;
    justify-content: center; }
  .matrix-list.matrix-1-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 0 42px;
    max-width: 600px; }
  .matrix-list.matrix-2-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 0 42px;
    max-width: 600px; }
  .matrix-list.matrix-3-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 0 42px;
    max-width: 600px; }
  .matrix-list.matrix-4-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 0 42px;
    max-width: 600px; } }

@media (max-width: 550px) {
  .matrix-list {
    padding: 0px 28px; }
    .matrix-list .matrix-item {
      margin-bottom: 42px; }
    .matrix-list .item-image {
      flex: 0 0 70px;
      width: 70px;
      height: 70px; }
      .matrix-list .item-image .cs-image-hover-icons {
        display: flex;
        justify-content: center;
        align-items: center; }
        .matrix-list .item-image .cs-image-hover-icons .button-circle-play {
          position: relative;
          bottom: auto;
          right: auto; }
      .matrix-list .item-image .cs-image-hover-show .info-counters {
        font-size: 18px; }
      .matrix-list .item-image .cs-image-hover-show .info-more {
        display: none; }
    .matrix-list .item-text {
      display: flex; }
      .matrix-list .item-text p {
        display: none; }
    .matrix-list .item-icons {
      padding: 21px 14px;
      justify-content: center; }
  .matrix-list.matrix-1-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 0 28px; }
  .matrix-list.matrix-2-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 0 28px; }
  .matrix-list.matrix-3-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 0 28px; }
  .matrix-list.matrix-4-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 0 28px; } }

.component-matrix-collapsible .matrix-list .matrix-item {
  margin-bottom: 28px; }

.component-matrix-collapsible .matrix-list .item-image {
  flex: 0 0 98px;
  width: 98px;
  height: 98px; }

.component-matrix-collapsible .matrix-list .item-text p {
  display: none; }

@media (min-width: 820px) {
  .component-matrix-collapsible .matrix-2-col.matrix-item-numbered {
    display: block;
    column-count: 2;
    column-gap: 28px; }
  .component-matrix-collapsible .matrix-3-col.matrix-item-numbered {
    display: block;
    column-count: 3;
    column-gap: 28px; }
  .component-matrix-collapsible .matrix-4-col.matrix-item-numbered {
    display: block;
    column-count: 4;
    column-gap: 28px; }
  .component-matrix-collapsible .matrix-item-numbered .matrix-item {
    display: inline-block;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .component-matrix-collapsible .matrix-item-numbered .item-image {
    overflow: visible; }
    .component-matrix-collapsible .matrix-item-numbered .item-image img {
      border-radius: 4px; } }

@media (min-width: 1194px) {
  .component-matrix-collapsible .matrix {
    padding: 0 14px; } }

@media (max-width: 960px) {
  .component-matrix-collapsible .matrix-list {
    justify-content: flex-start; }
  .component-matrix-collapsible .matrix-list.matrix-1-col .matrix-item {
    max-width: none; }
  .component-matrix-collapsible .matrix-list.matrix-2-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2);
    max-width: none;
    margin: 0 14px 28px; }
    .component-matrix-collapsible .matrix-list.matrix-2-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .component-matrix-collapsible .matrix-list.matrix-2-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .component-matrix-collapsible .matrix-list.matrix-3-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2);
    max-width: none;
    margin: 0 14px 28px; }
    .component-matrix-collapsible .matrix-list.matrix-3-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .component-matrix-collapsible .matrix-list.matrix-3-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .component-matrix-collapsible .matrix-list.matrix-4-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2);
    max-width: none;
    margin: 0 14px 28px; }
    .component-matrix-collapsible .matrix-list.matrix-4-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .component-matrix-collapsible .matrix-list.matrix-4-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; } }

@media (max-width: 820px) {
  .component-matrix-collapsible .matrix-list.matrix-2-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px; }
  .component-matrix-collapsible .matrix-list.matrix-3-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px; }
  .component-matrix-collapsible .matrix-list.matrix-4-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px; } }

@media (max-width: 550px) {
  .component-matrix-collapsible .matrix-list.matrix-2-col .matrix-item,
  .component-matrix-collapsible .matrix-list.matrix-3-col .matrix-item,
  .component-matrix-collapsible .matrix-list.matrix-4-col .matrix-item {
    margin: 10px 0; }
  .component-matrix-collapsible .matrix-list .matrix-item h4, .component-matrix-collapsible .matrix-list .matrix-item span.h4-block {
    font-size: 16px; }
  .component-matrix-collapsible .matrix-list .item-number {
    margin-right: 14px; }
  .component-matrix-collapsible .matrix-list .item-image {
    flex: 0 0 70px;
    width: 70px;
    height: 70px;
    margin-right: 21px; } }
