html, body {
  margin: 0px !important;
  padding: 0px;
  color: #43413f;
  min-height: 100%; }

#main-view {
  position: relative;
  z-index: 0; }

.main-view {
  min-height: calc(100vh - 200px); }

.text-light {
  color: white; }

.text-dark {
  color: #43413f; }

.text-orange {
  color: #ff674d; }

.text-center {
  text-align: center; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start; }

input, select, textarea {
  border: 1px solid #b8b6b4;
  height: 42px;
  padding: 0px 7px;
  font-size: 18px;
  box-sizing: border-box;
  background-color: transparent;
  line-height: 28px;
  color: #43413f; }
  input:focus, select:focus, textarea:focus {
    outline: none; }
  input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #b8b6b4; }
  input:-moz-placeholder, select:-moz-placeholder, textarea:-moz-placeholder {
    color: #b8b6b4; }
  input::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder {
    color: #b8b6b4; }
  input:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #b8b6b4; }
  input.dotted, select.dotted, textarea.dotted {
    border-width: 0px;
    border-bottom: 1px;
    border-style: dashed;
    border-color: #b8b6b4; }
  input.light, select.light, textarea.light {
    border-color: rgba(255, 255, 255, 0.6);
    color: rgba(255, 255, 255, 0.6);
    background-color: transparent; }
    input.light::-webkit-input-placeholder, select.light::-webkit-input-placeholder, textarea.light::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.6); }
    input.light:-moz-placeholder, select.light:-moz-placeholder, textarea.light:-moz-placeholder {
      color: rgba(255, 255, 255, 0.6); }
    input.light::-moz-placeholder, select.light::-moz-placeholder, textarea.light::-moz-placeholder {
      color: rgba(255, 255, 255, 0.6); }
    input.light:-ms-input-placeholder, select.light:-ms-input-placeholder, textarea.light:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.6); }

textarea {
  min-height: 112px; }

.input-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
  flex-wrap: wrap; }
  .input-group .label {
    flex: 0 1 25%;
    font-size: 18px;
    line-height: 28px;
    box-sizing: border-box; }
  .input-group .input {
    flex: 0 1 75%; }

.input-action {
  text-align: center; }

i[cs-icon], span[cs-icon] {
  display: flex;
  justify-content: center;
  align-items: center; }

hr {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #edecea;
  margin: 28px 0; }

.theme-gray hr {
  border-bottom: 1px solid #DAD8D6; }

.theme-dark hr, .theme-orange hr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

@media (max-width: 641px) {
  hr {
    margin: 21px 0; } }
