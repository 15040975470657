.expandable {
  position: relative; }
  .expandable .content {
    max-height: 280px;
    overflow: hidden;
    padding-bottom: 42px; }
  .expandable .gradient {
    position: absolute;
    z-index: 1;
    bottom: 0;
    height: 168px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 60%); }
  .expandable a {
    position: absolute;
    bottom: 0;
    z-index: 2; }

.expandable.expanded .content {
  max-height: none; }
