custom-button-form {
  display: flex;
  width: 100%;
  flex-direction: column; }
  custom-button-form .custom-button-inputs {
    display: flex; }
  custom-button-form .button-form {
    display: flex;
    width: 100%;
    flex-wrap: wrap; }

.custom-button {
  display: block; }

.multi-sub {
  width: 100%; }
  .multi-sub > .matrix.matrix-2-col {
    align-items: center;
    justify-content: center; }
    .multi-sub > .matrix.matrix-2-col .button {
      min-width: 300px; }
    .multi-sub > .matrix.matrix-2-col > .matrix-item {
      margin-left: 0; }

.component-text-block .custom-button {
  margin-bottom: 28px; }
