.banner .promo-paywall {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 56px; }
  .banner .promo-paywall .banner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 1 auto;
    width: 100%; }
    .banner .promo-paywall .banner-text:not(.studio) {
      max-width: 700px; }
    .banner .promo-paywall .banner-text h2, .banner .promo-paywall .banner-text span.h2-head {
      font-size: 40px;
      line-height: 42px;
      margin-bottom: 10px; }
    .banner .promo-paywall .banner-text span.h2-head {
      font-weight: bold;
      letter-spacing: -.02em;
      margin-top: 0; }
    .banner .promo-paywall .banner-text span.h6-head {
      font-style: italic;
      margin-bottom: 28px; }
    .banner .promo-paywall .banner-text .display-btn {
      font-weight: bold;
      margin: 0px 30px;
      font-size: 16px; }
    .banner .promo-paywall .banner-text.studio > span.h4-head {
      margin-bottom: 0px !important; }
    .banner .promo-paywall .banner-text .input-group {
      margin-bottom: 0px !important; }
    .banner .promo-paywall .banner-text span.h4-head {
      margin-bottom: 10px; }
    .banner .promo-paywall .banner-text .activity-or-btn {
      color: #B8B6B4; }

.theme-dark .promo-paywall .button.outline,
.theme-orange .promo-paywall .button.outline {
  color: white;
  border-color: white; }
  .theme-dark .promo-paywall .button.outline:hover,
  .theme-orange .promo-paywall .button.outline:hover {
    background-color: white;
    color: #43413f; }

@media (max-width: 960px) {
  .banner .promo-paywall {
    padding: 0 0 28px; }
    .banner .promo-paywall .banner-text {
      padding-top: 14px; }
      .banner .promo-paywall .banner-text h2, .banner .promo-paywall .banner-text span.h2-head {
        font-size: 26px;
        line-height: 28px; }
      .banner .promo-paywall .banner-text .input-group {
        display: block; }
      .banner .promo-paywall .banner-text .display-btn {
        font-weight: bold;
        margin: 10px 0px; } }

@media (max-width: 450px) {
  .banner .promo-paywall {
    padding: 0; }
    .banner .promo-paywall .banner-text h2, .banner .promo-paywall .banner-text span.h2-head {
      font-size: 24px; } }
