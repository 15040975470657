.hero.hero-type-collection {
  flex-direction: column;
  align-items: center; }
  .hero.hero-type-collection h1 {
    margin-bottom: 21px;
    text-align: center; }
  .hero.hero-type-collection .subhead-row {
    margin-bottom: 42px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
  .hero.hero-type-collection .fav-btn {
    margin-bottom: 14px; }
  .hero.hero-type-collection .button-circle-text {
    display: none; }
  .hero.hero-type-collection .type-label {
    display: flex;
    align-items: center;
    margin-right: 14px;
    margin-bottom: 14px;
    padding-right: 15px; }
    .hero.hero-type-collection .type-label i, .hero.hero-type-collection .type-label span {
      margin-right: 7px;
      width: 18px;
      height: 18px; }
    .hero.hero-type-collection .type-label h6, .hero.hero-type-collection .type-label span.h6-block {
      margin-bottom: 0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold; }
  .hero.hero-type-collection .social-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 14px; }
    .hero.hero-type-collection .social-buttons .button-circle-container {
      padding: 0 4px; }
    .hero.hero-type-collection .social-buttons .button-circle-comments {
      display: none; }
  .hero.hero-type-collection .main-row {
    align-items: flex-start;
    display: flex; }
    .hero.hero-type-collection .main-row div {
      flex: 1 1 50%; }
    .hero.hero-type-collection .main-row .video-group {
      margin-right: 56px; }
      .hero.hero-type-collection .main-row .video-group .cs-image {
        margin-bottom: 28px; }

@media (max-width: 1194px) {
  .hero.hero-type-collection {
    padding-left: 42px;
    padding-right: 42px; } }

@media (max-width: 900px) {
  .hero.hero-type-collection .main-row {
    flex-direction: column; }
    .hero.hero-type-collection .main-row div {
      flex: 1 1 auto;
      width: 100%; }
    .hero.hero-type-collection .main-row .video-group {
      margin-right: 0; } }

@media (max-width: 640px) {
  .hero.hero-type-collection {
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 28px; }
    .hero.hero-type-collection h1 {
      margin-bottom: 14px; }
    .hero.hero-type-collection .subhead-row {
      margin-bottom: 7px; }
    .hero.hero-type-collection .type-label, .hero.hero-type-collection .social-buttons {
      width: 60%;
      justify-content: center;
      padding-bottom: 14px;
      border-bottom: 1px solid #edecea; }
    .hero.hero-type-collection .fav-btn {
      margin-bottom: 25px; } }
