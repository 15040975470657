.joule-app-fifties .fifty-fifty {
  margin-bottom: 70px; }

.joule-app-fifties .fifty {
  display: flex;
  align-items: center; }

.joule-app-fifties .fifty.fifty-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 42px; }
  .joule-app-fifties .fifty.fifty-text svg {
    max-height: 65px;
    width: 100%;
    margin-bottom: 14px; }

.joule-app-fifties .fifty.fifty-image::before {
  display: none; }

@media (max-width: 641px) {
  .joule-app-fifties .fifty-fifty {
    margin-bottom: 0; }
  .joule-app-fifties .fifty.fifty-text {
    padding: 0 28px 42px; }
  .joule-app-fifties .fifty.fifty-image {
    padding-bottom: 56px; } }
