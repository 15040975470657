.hero {
  display: flex;
  position: relative; }

.hero-high-z {
  z-index: 700; }

.component.component-hero.theme-dark {
  background-color: transparent; }

.hero-video {
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center; }

.hero-video-portrait .cs-embed-video-wrapper {
  width: 100%;
  height: 56.25vw; }

.hero-video-landscape .cs-embed-video-wrapper {
  height: 90%;
  width: calc(16/9 * .9 * 80vh); }

.button.hero-close-button {
  z-index: 2;
  position: absolute;
  top: 14px;
  right: 14px;
  border-color: rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: opacity ease 300ms; }
  .button.hero-close-button:hover {
    color: #43413f;
    border-color: white;
    background-color: white; }

.hero-high-z .button.hero-close-button {
  opacity: 1; }

.component-standard.component-hero .hero {
  border-radius: 4px; }
