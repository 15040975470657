.subscribe-error-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center; }
  .subscribe-error-wrapper .subscribe-error {
    background-color: white;
    border: 2px solid #b8b6b4;
    border-radius: 14px;
    color: #43413f;
    padding: 28px; }
    .subscribe-error-wrapper .subscribe-error button {
      margin-top: 14px; }
