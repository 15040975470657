.joule-app-from-scratch {
  min-height: 56vw; }
  .joule-app-from-scratch::after {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.6+13,0+66 */
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 40%, transparent 85%, transparent 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 40%, transparent 85%, transparent 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 40%, transparent 85%, transparent 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99000000', endColorstr='#00000000',GradientType=1 );
    /* IE6-9 */ }
  .joule-app-from-scratch .hero-text {
    max-width: 600px; }

@media (max-width: 950px) {
  .hero.joule-app-from-scratch {
    display: flex;
    flex-direction: column; }
    .hero.joule-app-from-scratch::after {
      background: transparent; }
    .hero.joule-app-from-scratch .hero-background {
      position: relative;
      width: 100%;
      height: 56vw; }
    .hero.joule-app-from-scratch .hero-text {
      width: 100%;
      background-color: #fea894;
      max-width: none;
      padding-left: 0;
      padding: 70px 70px 42px;
      text-align: center !important; } }

@media (max-width: 750px) {
  .hero.joule-app-from-scratch .hero-text {
    padding: 42px 28px; } }
