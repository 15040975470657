.spree-checkout-button .price, .spree-checkout-button .out-of-stock {
  display: inline-block; }

.spree-checkout-button .msrp, .spree-checkout-button .out-of-stock {
  position: relative;
  opacity: 0.5; }
  .spree-checkout-button .msrp .strike, .spree-checkout-button .out-of-stock .strike {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    border-bottom: 2px solid; }
