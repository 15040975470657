.nav-ghost .component-hero:nth-child(1) .hero-text,
.nav-ghost .component-hero:nth-child(2) .hero-text {
  transform: translateY(20px); }

.hero-type-standard {
  width: 100%;
  min-height: 80vh;
  justify-content: center;
  align-items: center;
  overflow: hidden; }
  .hero-type-standard .hero-background {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%; }
  .hero-type-standard .hero-text {
    text-align: center;
    width: 100%;
    max-width: 700px;
    padding: 42px 0;
    box-sizing: border-box;
    overflow: hidden;
    /* To make sure z-index works in Safari */
    position: relative;
    z-index: 2; }
    .hero-type-standard .hero-text .button-circle-play-large {
      margin: 0 auto 14px;
      z-index: 3; }
    .hero-type-standard .hero-text h1 {
      margin-bottom: 14px;
      font-weight: bold;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
      position: relative; }
    .hero-type-standard .hero-text h4, .hero-type-standard .hero-text span.h4-block {
      font-size: 30px; }
    .hero-type-standard .hero-text h5, .hero-type-standard .hero-text span.h5-block {
      font-size: 22px;
      margin-bottom: 28px;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); }
  .hero-type-standard::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.4)), color-stop(100%, transparent));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
    /* W3C */ }
  .hero-type-standard.hero-no-overlay::after {
    display: none; }
  .hero-type-standard.light {
    color: white; }
  .hero-type-standard.orange {
    color: #ff674d; }
  .hero-type-standard.hero-text-left {
    justify-content: flex-start; }
    .hero-type-standard.hero-text-left .hero-text {
      text-align: left;
      margin-left: calc((100% - 1080px)/2); }
  .hero-type-standard.hero-text-right {
    justify-content: flex-end; }
    .hero-type-standard.hero-text-right .hero-text {
      text-align: right;
      margin-right: calc((100% - 1080px)/2); }

.nav-ghost .page .component:first-of-type .hero-type-standard .hero-text {
  padding-top: 77px; }

.component-small.component-hero .hero-type-standard {
  border-radius: 4px; }
  .component-small.component-hero .hero-type-standard .hero-text {
    padding: 42px 28px; }

.hero-type-standard.hero-height-short {
  min-height: 420px; }

.hero-type-standard.hero-height-tall {
  min-height: 90vh; }

.hero-type-standard.hero-align-left {
  justify-content: flex-start; }
  .hero-type-standard.hero-align-left .hero-text {
    text-align: left;
    padding-left: 84px; }

.hero-type-standard.hero-align-right {
  justify-content: flex-end; }
  .hero-type-standard.hero-align-right .hero-text {
    text-align: right;
    padding-right: 84px; }

.hero-type-standard.hero-background-video .cs-embed-video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

.hero-type-standard.hero-background-video .video-iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%; }

@media (max-width: 1136px) {
  .hero-type-standard .hero-text {
    padding: 84px 28px 42px; }
  .hero-type-standard.hero-text-left .hero-text, .hero-type-standard.hero-text-right .hero-text {
    margin: 0; }
  .nav-ghost .component-hero:first-of-type .hero-type-standard .hero-text {
    padding: 84px 28px 42px; } }

@media (max-width: 800px) {
  .hero-type-standard.hero-align-left .hero-text {
    padding-left: 56px; }
  .hero-type-standard.hero-align-right .hero-text {
    padding-right: 56px; } }

@media (max-width: 640px) {
  .hero-type-standard {
    min-height: 532px; }
    .hero-type-standard .hero-text h5 {
      font-size: 20px; }
  .hero-type-standard.hero-height-short {
    min-height: 350px; }
  .hero-type-standard.hero-height-tall {
    min-height: 532px; }
  .component-standard.component-hero, .component-small.component-hero {
    padding: 0; }
    .component-standard.component-hero .hero, .component-small.component-hero .hero {
      border-radius: 0; }
  .hero-type-standard.hero-align-left .hero-text {
    padding-left: 28px; }
  .hero-type-standard.hero-align-right .hero-text {
    padding-right: 28px; } }
