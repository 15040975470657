.joule-others h2, .joule-others span.h2-block {
  color: #ff674d; }

.joule-others .text-icon svg {
  height: 85px;
  width: auto;
  margin-bottom: 7px; }
  .joule-others .text-icon svg .a, .joule-others .text-icon svg .b {
    stroke-width: 2px;
    stroke: #ff674d; }

.component-media.padded + .component-olapic.theme-gray {
  background-color: #f7f7f7; }
