.owl-item.active .underline-hover {
  background-position: 100% 97% !important; }

.matrix-media {
  justify-content: center; }
  .matrix-media .simple-carousel-item {
    display: block; }
    .matrix-media .simple-carousel-item .item-image {
      flex: 0 0 196px;
      width: 196px;
      height: 196px;
      margin-bottom: 14px;
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      box-shadow: 0 0 15px rgba(0, 0, 20, 0.08); }
      .matrix-media .simple-carousel-item .item-image:before {
        content: '';
        display: block;
        padding-top: 66.66%; }
      .matrix-media .simple-carousel-item .item-image .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .matrix-media .simple-carousel-item .item-image cs-image {
        border-radius: 4px;
        -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
      .matrix-media .simple-carousel-item .item-image img {
        width: 100%; }
    .matrix-media .simple-carousel-item.empty-image .item-image {
      background: #b8b6b4; }
    .matrix-media .simple-carousel-item .item-text {
      padding: 0 14px; }
      .matrix-media .simple-carousel-item .item-text h4, .matrix-media .simple-carousel-item .item-text span.h4-block {
        margin-bottom: 7px; }
      .matrix-media .simple-carousel-item .item-text .item-card-label {
        display: none;
        font-size: 14px;
        text-transform: uppercase;
        opacity: 0.6; }
      .matrix-media .simple-carousel-item .item-text p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.85; }
      .matrix-media .simple-carousel-item .item-text .button {
        margin-top: 28px; }

@media (max-width: 960px) {
  .simple-carousel-item .simple-carousel-item .item-image {
    position: relative;
    overflow: hidden; }
    .simple-carousel-item .simple-carousel-item .item-image:before {
      content: '';
      display: block;
      padding-top: 50%; } }
