// Color Variables
// Note: If any of the following are modified, please modify their corresponding JS values in colors.coffee
$primary-bg: white;
$secondary-bg: #f5b6ab; // rose, light orange
$tertiary-bg: #f6654d; // coral, dark orange
$white-bg: white;
$primary-text: #333333; // light-black
$secondary-text: white;
$button-bg: $white-bg;
$button-text: $tertiary-bg;
$error: red;
$disabled: #fadbd5; // 0.5 opacity on $secondary-bg
$orange: #e25f25;
$light-black: rgb(51,51,51);
$dark-gray: rgb(146, 146, 146);
$offwhite: #f1f1f1;

// Spacing Variables

$space-micro: 5px;
$space-xsmall: 10px;
$space-small: 15px;
$space-medium: 20px;
$space-large: 30px;
$space-xlarge: 40px;


// Typography Variables

$font-xsmall: 10px;
$font-small: 12px;
$font-regular: 15px;
$font-medium: 18px;
$font-large: 25px;

$font-number-mini: 17px;
$font-number-small: 21px;
$font-number-medium: 27px;
$font-number-large: 55px;


/* Typography */

$baseline: 1.2rem; /* = 11px/14px */

$horz-baseline-small: 3px;
$horz-baseline-medium: 9px;
$horz-baseline-large: 13px;
