.header {
  box-sizing: border-box;
  text-align: center;
  padding-left: 28px;
  padding-right: 28px; }
  .header h2, .header span.h2-block {
    margin-bottom: 14px; }
  .header h5, .header span.h5-block {
    margin-bottom: 14px; }

@media (max-width: 640px) {
  .header {
    padding-left: 28px;
    padding-right: 28px; }
    .header h5 {
      margin-bottom: 14px; } }
