.suggested-recipes-container {
  margin-bottom: 14px; }
  .suggested-recipes-container h4, .suggested-recipes-container span.h4-block {
    font-size: 18px;
    margin-bottom: 7px;
    font-weight: bold; }

.suggested-recipes-item {
  margin-bottom: 14px;
  cursor: pointer; }
  .suggested-recipes-item h6, .suggested-recipes-item span.h6-block {
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 7px; }
  .suggested-recipes-item .cs-image-hover-info .info-counters {
    margin-bottom: 7px; }
