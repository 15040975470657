.component.gallery-nav {
  overflow: visible; }

.component.gallery-nav {
  padding: 70px 56px 56px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .component.gallery-nav .input-group {
    justify-content: center;
    flex-wrap: nowrap; }
    .component.gallery-nav .input-group .button {
      min-width: 120px; }
    .component.gallery-nav .input-group input {
      font-size: 26px;
      line-height: 42px;
      font-weight: 500; }

.search-input-form {
  flex: 3 0 auto;
  max-width: 450px;
  position: relative;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px;
  border-style: dashed;
  border-color: #b8b6b4;
  box-sizing: border-box;
  display: flex;
  margin: 0 42px 42px; }
  .search-input-form:before {
    content: "\f002";
    color: #b8b6b4;
    font-family: FontAwesome;
    font-size: 20px;
    line-height: 42px;
    position: absolute;
    top: 0;
    left: -42px; }
  .search-input-form .search-input-tags {
    flex: 1 0 auto;
    background-color: #edecea;
    color: #b8b6b4;
    font-weight: 500;
    padding: 7px 14px;
    margin-right: 14px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .search-input-form .search-input-tags i {
      margin-left: 7px;
      cursor: pointer; }
      .search-input-form .search-input-tags i .cs-icon-x-dims {
        width: 11px;
        height: 11px; }
  .search-input-form form {
    flex: 0 1 100%; }
  .search-input-form .search-input {
    border: 0px;
    padding: 0;
    width: 100%; }
    .search-input-form .search-input::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .search-input-form .search-input-clear {
    flex: 1 0 auto; }
    .search-input-form .search-input-clear:hover {
      cursor: pointer;
      color: #ff674d; }

.popular-search {
  position: absolute;
  left: 0;
  top: 56px;
  font-size: 16px;
  color: #b8b6b4; }
  .popular-search a {
    border-bottom: 1px solid #b8b6b4;
    margin: 0 2px;
    transition: color 300ms ease, border-color 300ms ease; }
  .popular-search a:hover {
    color: #43413f;
    border-color: #43413f; }

@media (max-width: 900px) {
  .component.gallery-nav .input-group input {
    font-size: 22px;
    line-height: 42px;
    font-weight: 500; }
  .component.gallery-nav .search-input-form:before {
    font-size: 18px;
    left: -28px; } }

@media (max-width: 750px) {
  .component.gallery-nav {
    padding: 28px 28px;
    flex-direction: column; }
    .component.gallery-nav .input-group {
      width: 100%; }
      .component.gallery-nav .input-group input {
        font-size: 18px;
        line-height: 28px;
        font-weight: 500; }
    .component.gallery-nav .search-input-form {
      margin: 0 0 70px; } }

.gallery-filters {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.96);
  display: flex;
  justify-content: center;
  align-items: center; }
  .gallery-filters .component {
    text-align: center;
    padding: 14px;
    position: initial; }
    .gallery-filters .component .gallery-filter-add-recipe {
      font-size: 18px;
      text-decoration: underline;
      color: #43413f;
      position: absolute;
      bottom: 28px;
      right: 28px; }

.gallery-filter-close {
  position: fixed;
  top: 28px;
  right: 28px;
  z-index: 100; }
  .gallery-filter-close .cs-icon-x-dims {
    width: 21px;
    height: 21px; }
  .gallery-filter-close:hover {
    color: #ff674d;
    cursor: pointer; }

.gallery-filter-group {
  margin: 0 auto 28px;
  width: 100%;
  max-width: 500px; }
  .gallery-filter-group .radio-option {
    font-size: 20px; }

@media (max-width: 550px) {
  .component.gallery-nav .search-input-form {
    margin: 0 0 91px; }
    .component.gallery-nav .search-input-form:before {
      content: ""; }
  .popular-search {
    top: 63px;
    bottom: auto; } }

@media (max-width: 700px), (max-height: 550px) {
  .gallery-filters .component .gallery-filter-add-recipe {
    font-size: 16px;
    position: relative;
    bottom: inherit;
    right: inherit; }
  .gallery-filters .component .gallery-filter-group h3, .gallery-filters .component .gallery-filter-group span.h3-block {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0; }
  .gallery-filters .component .gallery-filter-group .radio-option {
    font-size: 16px; }
  .gallery-filters .component .gallery-filter-group .filter-button {
    display: none; }
  .gallery-filters .gallery-filter-close .cs-icon-x-dims {
    width: 14px;
    height: 14px; } }

.filter-with-button {
  position: relative;
  display: inline-block; }
  .filter-with-button .filter-button {
    position: absolute;
    top: 0;
    right: -150px;
    line-height: 28px;
    padding: 7px 14px;
    background-color: #edecea;
    border-radius: 28px; }
    .filter-with-button .filter-button:hover {
      color: #ff674d;
      cursor: pointer; }

.gallery-results {
  background-color: #edecea;
  padding-top: 42px;
  min-height: 1200px;
  padding-left: calc((100% - 1400px)/2);
  padding-right: calc((100% - 1400px)/2); }
  .gallery-results.my-classes {
    padding-top: 28px; }
  .gallery-results .no-results {
    padding: 42px 14px 84px;
    text-align: center; }
    .gallery-results .no-results h3, .gallery-results .no-results h5, .gallery-results .no-results span.h3-block, .gallery-results .no-results span.h5-block {
      margin-bottom: 0; }

.gallery-animate-repeat.ng-move, .gallery-animate-repeat.ng-enter {
  transition: all ease-in-out 300ms; }

.gallery-animate-repeat.ng-leave {
  transition: all ease-in-out 300ms; }
  .gallery-animate-repeat.ng-leave.ng-leave-active {
    opacity: 0; }

.gallery-animate-repeat.ng-move, .gallery-animate-repeat.ng-enter {
  opacity: 0; }

.gallery-animate-repeat.ng-leave, .gallery-animate-repeat.ng-move.ng-move-active, .gallery-animate-repeat.ng-enter.ng-enter-active {
  opacity: 1; }

@media (max-width: 1484px) {
  .gallery-results {
    width: 100%;
    padding: 42px 0 0; }
    .gallery-results.my-classes {
      padding: 28px 0 0; }
    .gallery-results .matrix.matrix-card {
      padding: 0 42px; } }

@media (max-width: 640px) {
  .gallery-results {
    padding: 0; }
    .gallery-results.my-classes {
      padding-top: 28px; }
    .gallery-results .matrix.matrix-card {
      padding: 0; }
      .gallery-results .matrix.matrix-card .matrix-item {
        flex: 0 1 100%;
        margin-left: 0px;
        margin-right: 0px;
        margin: 0;
        border-radius: 0; }
        .gallery-results .matrix.matrix-card .matrix-item .item-text {
          padding: 28px;
          text-align: center; }
          .gallery-results .matrix.matrix-card .matrix-item .item-text h4 {
            font-size: 16px;
            line-height: 21px; }
          .gallery-results .matrix.matrix-card .matrix-item .item-text .item-card-label {
            justify-content: center; }
            .gallery-results .matrix.matrix-card .matrix-item .item-text .item-card-label .tag, .gallery-results .matrix.matrix-card .matrix-item .item-text .item-card-label .premium {
              font-size: 12px;
              line-height: 21px; }
        .gallery-results .matrix.matrix-card .matrix-item .item-image {
          border-radius: 0; }
    .gallery-results .no-results {
      padding: 42px 14px; } }

.gallery-sort {
  position: relative;
  margin-left: 14px;
  display: inline-block; }
  .gallery-sort .gallery-sort-expanded {
    position: relative;
    z-index: 2;
    background-color: #43413f;
    color: white; }
  .gallery-sort .gallery-sort-options {
    position: absolute;
    top: 56px;
    left: -14px;
    width: 150px;
    text-align: center;
    z-index: 1;
    border: 1px solid #edecea;
    border-radius: 4px;
    background-color: white;
    padding: 21px 14px;
    box-sizing: border-box; }
    .gallery-sort .gallery-sort-options:before, .gallery-sort .gallery-sort-options:after {
      content: "";
      border-style: solid;
      border-width: 10px;
      width: 0;
      height: 0;
      position: absolute;
      top: -20px;
      left: 65px; }
    .gallery-sort .gallery-sort-options:before {
      border-color: transparent transparent #edecea; }
    .gallery-sort .gallery-sort-options:after {
      margin-top: 2px;
      border-color: transparent transparent white; }
    .gallery-sort .gallery-sort-options .radio-options .radio-option {
      font-size: 18px;
      line-height: 35px;
      margin: 0 14px; }

@media (max-width: 640px) {
  .gallery-sort .gallery-sort-options .radio-options .radio-option {
    font-size: 16px; } }
