.matrix {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap; }

.matrix .matrix-item {
  margin: 0 14px 35px;
  box-sizing: border-box; }

@media (min-width: 1195px) {
  .component-full .matrix {
    padding: 0px 42px; }
  .matrix.matrix-1-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px; }
  .matrix.matrix-2-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2); }
    .matrix.matrix-2-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .matrix.matrix-2-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .matrix.matrix-3-col .matrix-item {
    flex: 0 1 calc((100% - 56px)/3);
    flex-basis: calc((100% - 56px)/3); }
    .matrix.matrix-3-col .matrix-item:nth-of-type(3n + 1) {
      margin-left: 0px; }
    .matrix.matrix-3-col .matrix-item:nth-of-type(3n + 3) {
      margin-right: 0px; }
  .matrix.matrix-4-col .matrix-item {
    flex: 0 1 calc((100% - 84px)/4);
    flex-basis: calc((100% - 84px)/4); }
    .matrix.matrix-4-col .matrix-item:nth-of-type(4n + 1) {
      margin-left: 0px; }
    .matrix.matrix-4-col .matrix-item:nth-of-type(4n + 4) {
      margin-right: 0px; }
  .matrix.matrix-5-col .matrix-item {
    flex: 0 1 calc((100% - 112px)/5);
    flex-basis: calc((100% - 112px)/5); }
    .matrix.matrix-5-col .matrix-item:nth-of-type(5n + 1) {
      margin-left: 0px; }
    .matrix.matrix-5-col .matrix-item:nth-of-type(5n + 5) {
      margin-right: 0px; } }

@media (min-width: 951px) and (max-width: 1194px) {
  .matrix {
    padding: 0px 42px; }
  .matrix.matrix-1-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px; }
  .matrix.matrix-2-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2); }
    .matrix.matrix-2-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .matrix.matrix-2-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .matrix.matrix-3-col .matrix-item {
    flex: 0 1 calc((100% - 56px)/3);
    flex-basis: calc((100% - 56px)/3); }
    .matrix.matrix-3-col .matrix-item:nth-of-type(3n + 1) {
      margin-left: 0px; }
    .matrix.matrix-3-col .matrix-item:nth-of-type(3n + 3) {
      margin-right: 0px; }
  .matrix.matrix-4-col .matrix-item {
    flex: 0 1 calc((100% - 56px)/3);
    flex-basis: calc((100% - 56px)/3); }
    .matrix.matrix-4-col .matrix-item:nth-of-type(3n + 1) {
      margin-left: 0px; }
    .matrix.matrix-4-col .matrix-item:nth-of-type(3n + 3) {
      margin-right: 0px; }
  .matrix.matrix-5-col .matrix-item {
    flex: 0 1 calc((100% - 56px)/3);
    flex-basis: calc((100% - 56px)/3); }
    .matrix.matrix-5-col .matrix-item:nth-of-type(3n + 1) {
      margin-left: 0px; }
    .matrix.matrix-5-col .matrix-item:nth-of-type(3n + 3) {
      margin-right: 0px; } }

@media (min-width: 641px) and (max-width: 950px) {
  .matrix {
    padding: 0px 42px; }
  .matrix.matrix-2-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2); }
    .matrix.matrix-2-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .matrix.matrix-2-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .matrix.matrix-3-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2); }
    .matrix.matrix-3-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .matrix.matrix-3-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .matrix.matrix-4-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2); }
    .matrix.matrix-4-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .matrix.matrix-4-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .matrix.matrix-5-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2); }
    .matrix.matrix-5-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .matrix.matrix-5-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; } }

@media (max-width: 640px) {
  .matrix {
    padding: 0px 14px; }
  .matrix.matrix-2-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px; }
  .matrix.matrix-3-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px; }
  .matrix.matrix-4-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px; }
  .matrix.matrix-5-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px; } }

.item-icons {
  position: absolute;
  height: 28px;
  padding: 14px;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  right: 0;
  left: 0;
  color: white;
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, rgba(0, 0, 0, 0.2)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#33000000',GradientType=0 );
  /* IE6-9 */ }

.item-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; }
  .item-info .item-info-counters {
    display: flex;
    font-size: 28px;
    line-height: 28px;
    font-weight: normal;
    margin-bottom: 14px; }
    .item-info .item-info-counters i {
      margin-right: 7px;
      margin-top: 2px; }
  .item-info .item-info-more {
    display: flex;
    font-style: italic; }

.is-mobile .item-info {
  top: initial;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 14px; }
  .is-mobile .item-info .item-info-counters {
    font-size: 20px;
    margin-bottom: 0; }
    .is-mobile .item-info .item-info-counters i {
      margin-top: 1px; }
  .is-mobile .item-info .item-info-more {
    display: none; }

.item-text:hover h4, .item-text:hover h6 {
  cursor: pointer;
  text-decoration: none; }

.item-text:hover h4 .underline-hover {
  background-position: 0% 97%;
  transition: background-position 0.4s ease; }

.zoom-hover {
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  cursor: pointer; }
  .zoom-hover cs-image {
    background-color: #43413f; }
    .zoom-hover cs-image img {
      filter: brightness(1);
      transform: scale3d(1, 1, 1);
      transition: all 500ms ease;
      backface-visibility: hidden; }
  .zoom-hover .zoom-hover-hide {
    transition: all 500ms ease;
    opacity: 1; }
  .zoom-hover .zoom-hover-show {
    transition: all 500ms ease;
    transition-delay: 100ms;
    opacity: 0; }

.zoom-hover-active cs-image img {
  cursor: pointer;
  transform: scale3d(1.06, 1.06, 1.06); }

.zoom-hover-active .zoom-hover-hide {
  opacity: 0; }

.zoom-hover-active .zoom-hover-show {
  opacity: 1; }

.zoom-hover-active.zoom-hover-active-dim cs-image img {
  filter: brightness(0.4); }
