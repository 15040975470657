.country-flag {
  display: flex; }

.prev-icon {
  font-size: 27px !important;
  margin-top: 14px;
  margin-right: 14px;
  margin-bottom: 14px; }

.input-country:checked + .country-flag {
  outline: 3px solid #43413f;
  outline-offset: 4px; }

.select-date {
  margin-top: 14px; }

.preview-link {
  margin-top: 28px; }
