.matrix-collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 28px;
  margin: 0 0 28px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  cursor: pointer; }
  .matrix-collapsible-header h3, .matrix-collapsible-header span.h3-block {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 0px; }
  .matrix-collapsible-header .button-group-inline {
    min-width: 70px; }
  .matrix-collapsible-header i, .matrix-collapsible-header span.fa {
    color: #b8b6b4;
    font-size: 28px; }
  .matrix-collapsible-header span.fa {
    margin-left: 0;
    margin-right: 0; }

.matrix-collapsible .matrix.matrix-list .matrix-item a {
  align-items: center; }

.disable-non-premium .matrix-collapsible-header {
  opacity: 0.6; }

.disable-non-premium .matrix-collapsible .matrix.matrix-list .matrix-item a {
  pointer-events: none;
  opacity: 0.6; }

@media (max-width: 1194px) {
  .matrix-collapsible-header {
    margin: 0 28px 28px; } }

@media (max-width: 550px) {
  .component-matrix-collapsible.component.padded {
    padding-top: 28px;
    padding-bottom: 14px; }
  .matrix-collapsible-header {
    padding: 14px;
    margin: 0 14px 14px; }
    .matrix-collapsible-header h3, .matrix-collapsible-header span.h3-block {
      font-size: 18px; }
  .matrix-collapsible {
    margin-bottom: 14px; }
    .matrix-collapsible .number-circle, .matrix-collapsible .item-number {
      width: 21px;
      height: 21px;
      font-size: 14px; } }
