.joule-voice::after {
  display: none; }

.joule-voice .hero-text {
  max-width: 500px;
  margin-left: 500px;
  padding: 42px 28px; }
  .joule-voice .hero-text h2 {
    max-width: 400px;
    margin: 0 auto 28px; }

.joule-voice .cs-background-image {
  background-position: bottom !important; }

.joule-voice .text-icon svg {
  height: 85px;
  width: auto;
  margin-bottom: 7px; }
  .joule-voice .text-icon svg .a, .joule-voice .text-icon svg .b {
    stroke: white;
    stroke-width: 2px; }

@media (max-width: 1100px) {
  .joule-voice .hero-text {
    margin-left: 350px; } }

@media (max-width: 800px) {
  .joule-voice {
    align-items: flex-start;
    min-height: 800px; }
    .joule-voice .hero-text {
      margin-left: 0px;
      padding-top: 56px; }
    .joule-voice .cs-background-image {
      background-position: bottom !important; } }
