.matrix-card .matrix-item {
  display: block;
  position: relative;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06); }
  .matrix-card .matrix-item .item-image {
    width: 100%;
    border-radius: 4px 4px 0 0;
    position: relative;
    overflow: hidden; }
    .matrix-card .matrix-item .item-image:before {
      content: '';
      display: block;
      padding-top: 66.66%; }
    .matrix-card .matrix-item .item-image .image, .matrix-card .matrix-item .item-image cs-image {
      background-color: #43413f;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .matrix-card .matrix-item .item-image img {
      width: 100%; }
  .matrix-card .matrix-item .item-text {
    color: #43413f;
    padding: 28px; }
    .matrix-card .matrix-item .item-text h4, .matrix-card .matrix-item .item-text span.h4-block {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 0; }
    .matrix-card .matrix-item .item-text p {
      margin-bottom: 0; }

.matrix-card .underline-hover {
  text-shadow: 0.03em 0.07em white, -0.03em 0.07em white, 0 0.03em white, 0 -0.03em white, 0.06em 0.07em white, -0.06em 0.07em white, 0.09em 0.07em white, -0.09em 0.07em white, 0.12em 0.07em white, -0.12em 0.07em white, 0.15em 0.07em white, -0.15em 0.07em white; }

.component-type-settings .matrix-card .matrix-item {
  background: transparent;
  border-radius: none;
  overflow: hidden;
  box-shadow: none; }

.item-card-label {
  display: flex; }
  .item-card-label .tag, .item-card-label .premium {
    font-size: 12px;
    line-height: 21px;
    font-weight: bold;
    margin-top: 7px;
    text-transform: uppercase;
    margin-right: 5px;
    opacity: 0.5; }
    .item-card-label .tag.purchased, .item-card-label .premium.purchased {
      color: #43413f !important; }
      .item-card-label .tag.purchased > .icon, .item-card-label .premium.purchased > .icon {
        position: relative;
        top: 1px;
        right: 4px; }
        .item-card-label .tag.purchased > .icon > svg, .item-card-label .premium.purchased > .icon > svg {
          width: 12px;
          height: 12px; }
  .item-card-label .premium {
    color: #ff674d;
    opacity: 1; }

.matrix-card .item-card-label .tag {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1; }

@media (max-width: 640px) {
  .matrix-card {
    justify-content: center; }
    .matrix-card .matrix-item .item-text {
      padding: 28px; }
      .matrix-card .matrix-item .item-text h4, .matrix-card .matrix-item .item-text span.h4-block {
        font-size: 16px;
        line-height: 21px; }
  .matrix-card.matrix-1-col .matrix-item {
    flex: 0 1 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0 0 28px; }
  .matrix-card.matrix-2-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2);
    margin: 0 7px 28px; }
    .matrix-card.matrix-2-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .matrix-card.matrix-2-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .matrix-card.matrix-3-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2);
    margin: 0 7px 28px; }
    .matrix-card.matrix-3-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .matrix-card.matrix-3-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; }
  .matrix-card.matrix-4-col .matrix-item {
    flex: 0 1 calc((100% - 28px)/2);
    flex-basis: calc((100% - 28px)/2);
    margin: 0 7px 28px; }
    .matrix-card.matrix-4-col .matrix-item:nth-of-type(2n + 1) {
      margin-left: 0px; }
    .matrix-card.matrix-4-col .matrix-item:nth-of-type(2n + 2) {
      margin-right: 0px; } }

@media (max-width: 450px) {
  .matrix-card .matrix-item .item-text {
    padding: 14px; }
    .matrix-card .matrix-item .item-text h4, .matrix-card .matrix-item .item-text span.h4-block {
      font-size: 13px;
      line-height: 16px; }
  .matrix-card .item-card-label .tag, .matrix-card .item-card-label .premium {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500; } }
