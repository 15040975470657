.component-joule-timeline {
  padding-bottom: 0 !important; }

.joule-timeline-items {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 1000px;
  padding-bottom: 56px;
  margin: 84px auto 0;
  box-sizing: border-box;
  align-items: center; }
  .joule-timeline-items::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 50%;
    bottom: 0px;
    border-left: 2px solid #edecea; }

.joule-timeline-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 112px; }

.joule-timeline-marker {
  flex: 0 0 120px;
  padding: 14px;
  box-sizing: border-box;
  position: relative;
  background-color: white;
  text-align: center; }
  .joule-timeline-marker .joule-timeline-time {
    font-family: Akkurat;
    font-size: 22px; }
  .joule-timeline-marker svg {
    width: 100%;
    max-height: 70px; }

.joule-timeline-image, .joule-timeline-content {
  flex: 0 1 100%;
  max-width: calc(50% - 50px); }

.joule-timeline-image {
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .joule-timeline-image .cs-image {
    border-radius: 50%;
    max-width: 126px;
    overflow: hidden; }

.joule-timeline-content h4, .joule-timeline-content span.h4-block {
  margin-bottom: 14px; }

.joule-timeline-content p {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 24px; }

.joule-timeline-block:first-of-type {
  margin-top: 0px; }

.joule-timeline-block:nth-last-of-type(even) .joule-timeline-image {
  justify-content: flex-end;
  margin-right: 56px; }

.joule-timeline-block:nth-last-of-type(even) .joule-timeline-content {
  margin-left: 56px;
  text-align: left; }

.joule-timeline-block:nth-last-of-type(odd) {
  flex-direction: row-reverse; }
  .joule-timeline-block:nth-last-of-type(odd) .joule-timeline-image {
    justify-content: flex-start;
    margin-left: 56px; }
  .joule-timeline-block:nth-last-of-type(odd) .joule-timeline-content {
    margin-right: 56px;
    text-align: right; }

.joule-timeline-end {
  width: 100%;
  height: 50vh;
  min-height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .joule-timeline-end::after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 80%;
    border-left: 2px solid rgba(255, 255, 255, 0.6); }
  .joule-timeline-end .joule-timeline-end-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .joule-timeline-end .cs-background-image-container::before {
    content: '';
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .joule-timeline-end i[cs-icon='sides'] {
    margin-bottom: 28px; }
    .joule-timeline-end i[cs-icon='sides'] svg {
      height: 40px;
      width: 40px; }

.joule-timeline-end-title {
  z-index: 1;
  width: 100px;
  color: white;
  padding: 42px; }

@media (max-width: 1084px) {
  .joule-timeline-items {
    width: 100%;
    padding: 0 42px 56px; } }

@media (max-width: 800px) {
  .joule-timeline-items::before {
    left: 102px;
    top: 0px; }
  .joule-timeline-block {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 0;
    text-align: left;
    padding-bottom: 56px; }
  .joule-timeline-image {
    display: none; }
  .joule-timeline-content {
    max-width: none; }
  .joule-timeline-block:nth-last-of-type(even) .joule-timeline-content {
    margin-left: 28px;
    text-align: left; }
  .joule-timeline-block:nth-last-of-type(odd) {
    flex-direction: row; }
    .joule-timeline-block:nth-last-of-type(odd) .joule-timeline-content {
      margin-right: 0;
      margin-left: 28px;
      text-align: left; }
  .joule-timeline-end {
    justify-content: flex-start; }
    .joule-timeline-end::after {
      left: 102px;
      bottom: 70%; }
  .joule-timeline-end-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 70px; }
    .joule-timeline-end-title i[cs-icon='sides'] {
      margin-bottom: 0; }
    .joule-timeline-end-title h2 {
      margin-bottom: 0;
      margin-left: 28px; } }

@media (max-width: 500px) {
  .joule-timeline-items {
    margin: 28px 0 0;
    padding: 0 28px 28px 14px; }
    .joule-timeline-items::before {
      left: 64px; }
  .joule-timeline-block {
    padding-bottom: 14px; }
  .joule-timeline-marker {
    flex: 0 0 100px; }
    .joule-timeline-marker img {
      margin-bottom: 0; }
    .joule-timeline-marker .joule-timeline-time {
      font-size: 16px; }
  .joule-timeline-content {
    margin-left: 7px !important; }
    .joule-timeline-content h4, .joule-timeline-content span.h4-block {
      margin-top: 14px; }
    .joule-timeline-content p {
      font-size: 14px;
      line-height: 21px; }
  .joule-timeline-end::after {
    left: 64px; }
  .joule-timeline-end-title {
    padding: 0 42px; } }
