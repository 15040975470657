.joule-features {
  position: relative; }

.joule-vertical, .joule-horizontal {
  position: absolute;
  z-index: 1; }
  .joule-vertical img, .joule-horizontal img {
    width: 100%; }

.joule-vertical {
  top: 0;
  right: 0;
  width: 500px; }

.joule-horizontal {
  bottom: 50px;
  left: 0;
  width: 300px; }

.joule-features-inner {
  width: 90%;
  max-width: 1400px;
  padding: 84px 42px;
  margin: auto;
  background-color: #f7dfdd;
  color: #ff674d;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .joule-features-inner h2, .joule-features-inner span.h2-block {
    font-size: 52px;
    line-height: 1;
    text-align: center;
    max-width: 500px;
    margin-bottom: 42px;
    z-index: 2; }
  .joule-features-inner .button {
    min-width: 220px;
    z-index: 2; }

.joule-feature-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 28px;
  z-index: 2; }

.joule-feature {
  flex: 0 0 50%;
  width: 50%;
  display: flex;
  padding: 14px 28px;
  box-sizing: border-box; }

.feature-icon {
  margin-right: 14px; }
  .feature-icon svg {
    width: 70px;
    height: auto; }
    .feature-icon svg .a {
      stroke: #ff674d;
      stroke-width: 2px; }

.feature-text h4, .feature-text span.h4-block {
  margin-bottom: 7px; }

.feature-text p {
  font-size: 16px;
  line-height: 24px;
  color: #43413f;
  opacity: 0.5; }

@media (min-width: 1750px) {
  .joule-vertical {
    right: calc(50vw - 800px); }
  .joule-horizontal {
    left: calc(50vw - 700px); } }

@media (max-width: 1300px) {
  .joule-vertical {
    width: 400px;
    top: -80px;
    right: -10px; }
  .joule-horizontal {
    width: 250px;
    bottom: 10px; } }

@media (max-width: 1200px) {
  .joule-vertical {
    width: 380px;
    top: -140px;
    right: -20px; }
  .joule-horizontal {
    width: 200px;
    bottom: 10px; } }

@media (max-width: 900px) {
  .joule-vertical {
    width: 320px;
    right: -10px;
    top: -140px; }
  .joule-horizontal {
    bottom: auto;
    top: 0px;
    width: 170px; }
  .joule-features-inner {
    padding: 70px 28px 56px; }
    .joule-features-inner h2, .joule-features-inner span.h2-block {
      font-size: 40px;
      max-width: 450px;
      margin-bottom: 56px; }
  .joule-feature {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 14px 14px 0;
    flex: 0 0 33%;
    width: 33%; }
  .feature-icon {
    margin: 0; } }

@media (max-width: 730px) {
  .joule-vertical {
    width: 250px;
    right: -10px;
    top: -40px; }
  .joule-horizontal {
    bottom: auto;
    top: 40px;
    width: 130px; }
  .joule-features-inner h2, .joule-features-inner span.h2-block {
    max-width: 300px;
    margin-bottom: 42px; }
  .joule-feature {
    flex: 0 0 50%;
    width: 50%;
    max-width: 250px; } }

@media (max-width: 500px) {
  .joule-vertical {
    width: 340px;
    right: 30px;
    top: -100px; }
  .joule-horizontal {
    bottom: auto;
    top: 20px;
    width: 200px; }
  .joule-features-inner {
    padding: 280px 28px 56px; } }

@media (max-width: 400px) {
  .joule-vertical {
    width: 310px;
    right: -20px;
    top: -80px; }
  .joule-horizontal {
    bottom: auto;
    top: 20px;
    width: 170px; }
  .joule-features-inner {
    width: 100%;
    padding: 280px 0 56px; }
  .joule-feature {
    flex: 0 0 100%;
    width: 100%;
    max-width: 320px;
    flex-direction: row;
    align-items: flex-start;
    text-align: left; }
  .feature-icon {
    margin-right: 14px; } }
