.number-circle {
  display: inline-flex;
  border: 2px solid #43413f;
  color: #43413f;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-family: 'akkurat';
  font-weight: bold;
  font-size: 16px; }
  .number-circle.solid.orange {
    border-color: #ff674d;
    background-color: #ff674d;
    color: white; }
    .number-circle.solid.orange:hover, .number-circle.solid.orange:active {
      opacity: 0.8; }
